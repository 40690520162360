import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { history } from "../../App";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Input,
  Row,
  Spacer,
  Switch,
} from "@nextui-org/react";
import {
  getUser,
  initializeColruytStores,
  updateColruytQuery,
  updateColruytStore,
  updateEmail,
  updateFirstName,
  updateName,
  updateUser,
  updateReceivePromotionMail,
  updateReceiveTweedehandsMail,
  updateReceivePriceUpdateMail,
} from "../../redux/reducers/UserSlice";
import { useTranslation } from "react-i18next";

export default function UserProfile() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);
  const stores = useSelector((state) => state.user.filteredColruytStores);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
    dispatch(updateColruytQuery(""));
    dispatch(initializeColruytStores());
  }, [dispatch]);

  return (
    user && (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            marginTop: "15px",
          }}
        >
          <h4>{t("profile_personaldata")}</h4>
          <Input
            style={{ minWidth: "300px" }}
            label={t("firstname")}
            value={user.firstName}
            onChange={(e) => dispatch(updateFirstName(e.target.value))}
          ></Input>
          <Input
            style={{ minWidth: "300px", margin: "10px" }}
            label={t("name")}
            value={user.lastName}
            type="text"
            onChange={(e) => dispatch(updateName(e.target.value))}
          ></Input>
          <Input
            style={{ minWidth: "300px", margin: "10px" }}
            label={t("email")}
            value={user.email}
            type="text"
            onChange={(e) => dispatch(updateEmail(e.target.value))}
          ></Input>
          <FormControl
            style={{ marginTop: "2px", margin: "10px" }}
            variant="standard"
            className="inputLabelSmall"
          >
            <InputLabel>{t("colruyt_location")}</InputLabel>
            <Select
              value={user.configuration.colruytLocation}
              onChange={(e) => dispatch(updateColruytStore(e.target.value))}
            >
              <MenuItem key={-1} value={""}>
                {t("colruyt_location_select")}
              </MenuItem>
              {stores.map((store) => (
                <MenuItem key={store.id} value={store.id}>
                  {store.address}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <h4>{t("options")}</h4>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "10px",
            }}
          >
            <p style={{ minWidth: "250px" }}>{t("receive_promomail")}</p>
            <Switch
              checked={user.configuration.receivePromotionMail}
              onChange={() => dispatch(updateReceivePromotionMail())}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "10px",
            }}
          >
            <p style={{ minWidth: "250px" }}> {t("receive_secondhandmail")}</p>
            <Switch
              checked={user.configuration.receiveTweedeHandsMail}
              onChange={() => dispatch(updateReceiveTweedehandsMail())}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "10px",
            }}
          >
            <p style={{ minWidth: "250px" }}> {t("receive_priceupdatemail")}</p>
            <Switch
              checked={user.configuration.receivePriceUpdateMail}
              onChange={() => dispatch(updateReceivePriceUpdateMail())}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{ minWidth: "250px" }}
              size="sm"
              onClick={() => dispatch(updateUser())}
            >
              <p style={{ textTransform: "uppercase" }}> {t("save")}</p>
            </Button>
          </div>
        </div>
      </React.Fragment>
    )
  );
}
