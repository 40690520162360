import React, { useState } from "react";
import { Row } from "reactstrap";
import { useSelector } from "react-redux";
import ItemOverviewCard from "./ItemOverviewCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { getItemsPagedEternal } from "../../../redux/reducers/itemSlice";

function ItemsListMobile() {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.item.filter);

  const itemsEternal = useSelector((state) => state.item.itemsEternal);
  const [page, setPage] = useState(1);
  const fetchData = () => {
    const nexPage = page + 1;
    setPage(page + 1);
    dispatch(getItemsPagedEternal({ query: filter, page: nexPage, size: 12 }));
  };
  return (
    <Row>
      {itemsEternal && (
        <InfiniteScroll
          dataLength={itemsEternal.length} //This is important field to render the next data
          next={fetchData}
          hasMore={true}
        >
          {itemsEternal.map((item, i) => (
            <ItemOverviewCard key={item.id} item={item} />
          ))}
        </InfiniteScroll>
      )}
    </Row>
  );
}

export default ItemsListMobile;
