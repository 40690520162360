import React from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  switchTweedehands,
  updateTweedehandsQuery,
} from "../../../redux/reducers/itemSlice";
import { Checkbox, Container, Input } from "@nextui-org/react";

export default function ItemDetailTweedehands() {
  const dispatch = useDispatch();
  const tweedehands = useSelector((state) => state.item).itemTweedehands;
  const query = useSelector((state) => state.item).itemTweedehandsQuery;
  return (
    <React.Fragment>
      {tweedehands && (
        <Input
          clearable
          value={query}
          label="Zoekterm"
          disabled={!tweedehands}
          placeholder="tweedehands zoekterm"
          width="100%"
          // style={{minWidth:"100%"}}
          onChange={(e) => {
            dispatch(updateTweedehandsQuery(e.target.value));
          }}
        />
      )}
    </React.Fragment>
  );
}
