import React, { useState } from "react";
import { Grid, Card, Row, Text, Spacer, Button } from "@nextui-org/react";
import { CenteredImage } from "../../Components/Shared/CenteredImage";

export default function StoreScrapeHit(props) {
  return (
    <Card.Body onClick={() => window.open(`${props.item.Url}`, "_blank")}>
      <Row wrap="no-wrap" justify="space-between" style={{ minHeight: "75px" }}>
        <Text> {props.item.description}</Text>
        <Text style={{ minWidth: "90px", textAlign: "right" }} h3>
          {" "}
          {props.item.priceString}
        </Text>
      </Row>
      <Card.Divider />
      <Spacer y={1} />
      <Row wrap="no-wrap" justify="space-between">
        <div
          className="itemoverviewcard-image-container"
          style={{
            maxWidth: "125px",
            minWidth: "115px",
            marginRight: "25px",
          }}
        >
          <CenteredImage
            size="95px"
            src={props.item.image}
            alt={props.item.store}
          />
        </div>
        <div>
          {props.item.promotion && (
            <div>
              <div style={{ textAlign: "center" }}>
                <Text h4> {props.item.promotion}</Text>

                {props.item.promotionStart && (
                  <Text p>
                    {`van ${props.item.promotionStart} tot ${props.item.promotionEnd}`}
                  </Text>
                )}
              </div>
            </div>
          )}
        </div>
      </Row>
    </Card.Body>
  );
}
