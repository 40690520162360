import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  getShoppingList,
  getShoppingListsForUser,
  switchShoppingListitem,
  createShoppingListForItemGroup,
} from "../../redux/reducers/shoppingListSlice";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import ItemStoreImage from "../../Components/Shared/ItemStoreImage";
import { PercentRounded } from "@mui/icons-material";
import { Avatar, Button, Chip } from "@mui/material";
import { setPageActions } from "../../redux/reducers/authenticationSlice";
const pageActions = [];

export default function ShoppingList() {
  const params = useParams();
  const dispatch = useDispatch();
  const shoppingLists = useSelector(
    (store) => store.shoppingList.shoppingLists
  );
  const storenames = useSelector((state) => state.search.searchStores);
  const shoppingList = useSelector((store) => store.shoppingList.shoppingList);
  useEffect(() => {
    dispatch(getShoppingList(params.id));
    dispatch(setPageActions(pageActions));
  }, [params.id]);

  const handleRefresh = () => {
    dispatch(
      createShoppingListForItemGroup({
        groupId: shoppingList.itemGroupId,
        storeId: 0,
      })
    );
  };

  return (
    <Container>
      {shoppingList && (
        <React.Fragment>
          <Row
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "15px",
              marginBottom: "25px",
            }}
          >
            {shoppingList.availableStores &&
              shoppingList.availableStores.map((store) => {
                var imageStore = storenames.find((x) => x.id === store.store);
                return (
                  <Col
                    xs={6}
                    sm={4}
                    md={2}
                    xl={2}
                    style={{ marginBottom: "10px", cursor: "pointer" }}
                    onClick={() => dispatch(getShoppingList(store.linkId))}
                  >
                    {imageStore ? (
                      <Chip
                        avatar={
                          <Avatar
                            alt={imageStore.image}
                            src={imageStore.image}
                          />
                        }
                        label={store.priceString}
                        variant="outlined"
                      />
                    ) : (
                      <Chip
                        avatar={<Avatar>€</Avatar>}
                        label={store.priceString}
                      />
                    )}
                  </Col>
                );
              })}
          </Row>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>{shoppingList.description}</h4>
            <Button onClick={handleRefresh}>Refresh</Button>
          </div>
          <div>
            {shoppingList.items.map((storeItem) => (
              <div style={{ marginBottom: "25px" }}>
                <Row>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    {" "}
                    <ItemStoreImage storeId={storeItem.store}></ItemStoreImage>
                    <div>{storeItem.storeName}</div>
                  </div>
                </Row>

                {storeItem.items
                  .filter((x) => x.active)
                  .map((item) => (
                    <Row>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            style={{ marginRight: "35px" }}
                            type="checkbox"
                            checked={item.active}
                            onChange={() =>
                              dispatch(
                                switchShoppingListitem({
                                  id: shoppingList.id,
                                  itemId: item.id,
                                })
                              )
                            }
                          ></input>
                          <div>{`${item.amount} x ${item.description}`}</div>
                        </div>

                        <div>{item.priceString}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <b style={{ marginLeft: "50px" }}>{item.promotion}</b>
                      </div>
                    </Row>
                  ))}

                {storeItem.items
                  .filter((x) => !x.active)
                  .map((item) => (
                    <Row>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            style={{ marginRight: "35px" }}
                            type="checkbox"
                            checked={item.active}
                            onChange={() =>
                              dispatch(
                                switchShoppingListitem({
                                  id: shoppingList.id,
                                  itemId: item.id,
                                })
                              )
                            }
                          ></input>
                          <div
                            style={{ textDecoration: "line-through" }}
                          >{`${item.amount} x ${item.description}`}</div>
                        </div>

                        <div>{item.priceString}</div>
                      </div>
                    </Row>
                  ))}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    borderTopStyle: "solid",
                    marginTop: "5px",
                  }}
                >
                  <p>{storeItem.priceString}</p>
                </div>
              </div>
            ))}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p>{`Totaal: ${shoppingList.priceString}`}</p>
          </div>
        </React.Fragment>
      )}
    </Container>
  );
}
