import instance from "./Api";
import TokenService from "./TokenService";

class AuthService {
  login(username, password) {
    return instance
      .post("/User/login", {
        username,
        password
      })
      .then(response => {
        if (response.accessToken) {
          TokenService.setUser(response);
        }
        return response.data;
      });
  }
  logout() {
    TokenService.removeUser();
  }
  register(data) {
    return instance.post("/User/register", data);
  }
  activate(token) {
    return instance.post(`/User/activate/${token}`);
  }
  getCurrentUser() {
    return TokenService.getUser();
  }
}
export default new AuthService();
