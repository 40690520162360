import React from "react";

import { useDispatch } from "react-redux";
import {
  updateConfiguration,
  updateSearchConfiguration,
  updateGetItemConfiguration,
} from "../../redux/reducers/searchSlice";
import { TextField } from "@mui/material";

export default function StoreConfigTextInput(props) {
  const dispatch = useDispatch();
  let inputValue = {};
  switch (props.type) {
    case 0:
      inputValue = { ...props.storeConfig };
      break;
    case 1:
      inputValue = { ...props.storeConfig.searchConfiguration };
      break;
    case 2:
      inputValue = { ...props.storeConfig.getItemConfiguration };
      break;

    default:
      break;
  }

  const HandleChange = (value) => {
    switch (props.type) {
      case 0:
        dispatch(
          updateConfiguration({
            key: props.objectKey,
            value,
          })
        );
        break;
      case 1:
        dispatch(
          updateSearchConfiguration({
            key: props.objectKey,
            value,
          })
        );
        break;
      case 2:
        dispatch(
          updateGetItemConfiguration({
            key: props.objectKey,
            value,
          })
        );
        break;

      default:
        break;
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: "95%",
        marginBottom: "5px",
      }}
    >
      <p style={{ minWidth: "150px" }}>{props.title}:</p>
      <TextField
        multiline
        variant="standard"
        maxRows={3}
        placeholder={props.title}
        style={{ width: "100%" }}
        value={inputValue[props.objectKey] || ""}
        onChange={(e) => HandleChange(e.target.value)}
      />
    </div>
  );
}
