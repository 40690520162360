import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { getItemHistory } from "../../../redux/reducers/itemSlice";
import ItemStoreImage from "../../../Components/Shared/ItemStoreImage";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function ItemPriceHistory() {
  const params = useParams();
  const { t } = useTranslation();
  const itemHistory = useSelector((s) => s.item.itemHistory);
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(itemDetail());
    dispatch(getItemHistory(params.id));
    // dispatch(updateQueryInternal(""));
  }, [params.id, dispatch]);

  const options = {
    responsive: true,
    elements: {
      line: {
        stepped: false,
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: itemHistory && itemHistory.highestPrice,
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  return (
    <>
      {itemHistory && (
        <>
          {" "}
          <div style={{ display: "flex", marginTop: "15px" }}>
            <ItemStoreImage storeId={itemHistory.store}></ItemStoreImage>
            <div>{itemHistory.name}</div>
          </div>
          <div style={{ width: "75%", margin: "auto" }}>
            {itemHistory && itemHistory.dates.length > 0 && (
              <Line
                options={options}
                data={{
                  labels: itemHistory.dates,
                  datasets: [
                    {
                      label: t("price"),
                      data: itemHistory.prices,
                      borderColor: "rgb(255, 99, 132)",
                      backgroundColor: "rgba(255, 99, 132, 0.5)",
                    },
                    {
                      label: t("promotion"),
                      data: itemHistory.promo,
                      borderColor: "rgb(0, 0, 0)",
                      backgroundColor: "lightgreen",
                      fill: {
                        target: "origin",
                        //above: "rgb(255, 0, 0)", // Area will be red above the origin
                        below: "green", // And blue below the origin
                      },
                    },
                  ],
                }}
              />
            )}
          </div>
          <div style={{ marginTop: "15px" }}>
            {itemHistory.promotions.length > 0 && (
              <div>
                {itemHistory.promotions.map((promo) => (
                  <h5>
                    {promo.key} {promo.value}
                  </h5>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
