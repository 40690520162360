import React from "react";
import { Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getItemsPaged,
  setCurrentPage,
} from "../../../redux/reducers/itemSlice";
import { useTranslation } from "react-i18next";

export default function ItemOverviewPagination() {
  const { t } = useTranslation();
  const pages = useSelector((state) => state.item.pages);
  const hits = useSelector((state) => state.item.hits);
  const filter = useSelector((state) => state.item.filter);
  const dispatch = useDispatch();

  const changePage = (event, value) => {
    dispatch(setCurrentPage(value));
    dispatch(getItemsPaged({ query: filter, page: value, size: 12 }));

    // window.scrollTo(0, 0);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "25px",
      }}
    >
      {" "}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination count={pages} onChange={changePage} />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p>
          {hits} {hits !== 1 ? t("items") : t("item")} {t("found")}{" "}
        </p>
      </div>
    </div>
  );
}
