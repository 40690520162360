import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TokenService from "../../Services/TokenService";
import AuthService from "../../Services/AuthService";
import { history } from "../../App";

const initialState = {
  username: "",
  password: "",
  redirect: "",
  user: null,
  pageActions: [],
};

export const login = createAsyncThunk(
  "authentication/login",
  async (_, { dispatch, getState }) => {
    const state = getState().authentication;
    await AuthService.login(state.username, state.password);
    const user = TokenService.getUser();

    if (
      !user &&
      !window.location.href.indexOf("/login") > 0 &&
      !window.location.href.endsWith("/register") &&
      window.location.href.indexOf("/activate/") < 0
    ) {
      history.push("/login");
    } else {
      if (
        window.location.href === "https://www.webkom.be/" ||
        window.location.href === "http://localhost:3000/" ||
        window.location.href.indexOf("/login") >= 0 ||
        window.location.href.endsWith("/register") ||
        window.location.href.indexOf("/activate/") > 0
      ) {
        if (state.redirect) {
          window.location.href = state.redirect;
        } else {
          window.location.href = "/items";
        }
      } else {
        window.location.href = window.location.href;
      }
    }
  }
);

const { actions, reducer } = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout: (state, action) => {
      TokenService.removeUser();
      state.user = null;
      // location.href to refresh the state
      window.location.href = "/login";
    },
    setName: (state, action) => {
      state.username = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setRedirect: (state, action) => {
      state.redirect = action.payload;
    },
    setPageActions: (state, action) => {
      state.pageActions = action.payload;
    },

    setUser: (state, action) => {
      state.user = TokenService.getUser();

      if (state.user) {
        history.push("/login");
      } else if (
        !state.user &&
        !window.location.href.endsWith("/login") &&
        !window.location.href.endsWith("/register") &&
        window.location.href.indexOf("/activate/") < 0
      ) {
        history.push("/login");
      }
    },
  },
});

export const {
  logout,
  setName,
  setPassword,
  setUser,
  setPageActions,
  setRedirect,
} = actions;
export default reducer;
