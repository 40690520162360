import React from "react";
import ItemPromotionCard from "./ItemPromotionCard";
import { useSelector } from "react-redux";
import { Row } from "reactstrap";

export default function ItemPromotionList() {
  const items = useSelector((state) => state.item.itemsWithPromotions);
  return (
    items && (
      <Row>
        {items.map((item, i) => (
          <ItemPromotionCard key={item.id} item={item} />
        ))}
      </Row>
    )
  );
}
