import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../Services/AuthService";
import UserService from "../../Services/UserService";
import TokenService from "../../Services/TokenService";
import { history } from "../../App";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  filteredColruytStores: [],
  colruytStores: [
    {
      id: "902",
      address: "AALST (BRUSSELSE STEENWEG 41)",
    },
    {
      id: "946",
      address: "AALTER (LOSTRAAT 66)",
    },
    {
      id: "950",
      address: "AARSCHOT (LEUVENSESTEENWEG 241)",
    },
    {
      id: "886",
      address: "ALSEMBERG (BRUSSELSESTEENWEG 19)",
    },
    {
      id: "783",
      address: "AMAY (CHAUSSEE DE TONGRES 247)",
    },
    {
      id: "650",
      address: "ANDENNE (RUE DE LA PAPETERIE 4)",
    },
    {
      id: "669",
      address: "ANDERLECHT (MAURICE HERBETTELAAN 57)",
    },
    {
      id: "744",
      address: "ANDERLECHT (BERGENSESTEENWEG 824)",
    },
    {
      id: "448",
      address: "ANDERLUES (RUE DE LA STATION 4)",
    },
    {
      id: "681",
      address: "ANS (RUE DES FRANCAIS 88)",
    },
    {
      id: "880",
      address: "ANTOING (AVENUE DE L'EUROPE 14)",
    },
    {
      id: "636",
      address: "ANTWERPEN 1 (LANGE ELZENSTRAAT 35A)",
    },
    {
      id: "2781",
      address: "ANTWERPEN 1 (GENTPLAATS 15)",
    },
    {
      id: "2643",
      address: "ANTWERPEN 5 (BLANCEFLOERLAAN 357)",
    },
    {
      id: "538",
      address: "ARLON (RUE DE L'HYDRION 15)",
    },
    {
      id: "454",
      address: "ASSE (NERVIERSSTRAAT 49A)",
    },
    {
      id: "833",
      address: "ASSEBROEK (NIJVERHEIDSSTRAAT 124)",
    },
    {
      id: "621",
      address: "ATH (CHAUSSEE DE BRUXELLES 116)",
    },
    {
      id: "640",
      address: "AVELGEM (DOORNIKSESTEENWEG 272)",
    },
    {
      id: "974",
      address: "AVERBODE (MELKERIJSTRAAT 8)",
    },
    {
      id: "982",
      address: "BAISY-THY (CHAUSSEE DE BRUXELLES 13)",
    },
    {
      id: "3174",
      address: "BALEN (SCHOOR 1)",
    },
    {
      id: "741",
      address: "BASTOGNE (CHAUSSEE DE HOUFFALIZE 22)",
    },
    {
      id: "866",
      address: "BATTICE (OUTRE-COUR 37)",
    },
    {
      id: "2673",
      address: "BEAUFAYS (VOIE DE L'AIR PUR 175)",
    },
    {
      id: "2583",
      address: "BEERNEM (HUBERT D'YDEWALLESTRAAT 72)",
    },
    {
      id: "608",
      address: "BEN-AHIN (CHAUSSEE DE DINANT 5)",
    },
    {
      id: "3310",
      address: "BERCHEM (ANTW.) (OOGSTSTRAAT 239)",
    },
    {
      id: "484",
      address: "BERINGEN (KOOLMIJNLAAN 321)",
    },
    {
      id: "685",
      address: "BEVEREN-WAAS (DONKVIJVERSTRAAT 17)",
    },
    {
      id: "874",
      address: "BIERGHES (ROUTE DE QUENAST 7)",
    },
    {
      id: "3330",
      address: "BILZEN (MAASTRICHTERSTRAAT 18)",
    },
    {
      id: "661",
      address: "BINCHE (RUE ZEPHIRIN FONTAINE 162)",
    },
    {
      id: "717",
      address: "BLANKENBERGE (RUITERSTRAAT 2)",
    },
    {
      id: "687",
      address: "BONCELLES (ROUTE DU CONDROZ 19)",
    },
    {
      id: "766",
      address: "BOOM (PAPENSTEENSTRAAT 1)",
    },
    {
      id: "579",
      address: "BORNEM (SINT-AMANDSESTEENWEG 85)",
    },
    {
      id: "614",
      address: "BOUILLON (RUE DU COLLEGE 32)",
    },
    {
      id: "1713",
      address: "BRAINE-L'ALLEUD (RUE DU JARDINIER 50)",
    },
    {
      id: "617",
      address: "BRAINE-LE-CHATEAU (RUE DE TUBIZE 6)",
    },
    {
      id: "615",
      address: "BRAKEL (GERAARDSBERGSESTRAAT 4)",
    },
    {
      id: "716",
      address: "BRASSCHAAT (LEOPOLDSLEI 24)",
    },
    {
      id: "875",
      address: "BREDENE (VLOEDSTRAAT 22)",
    },
    {
      id: "811",
      address: "BREE (REGENBOOGSTRAAT 14)",
    },
    {
      id: "668",
      address: "BRUGGE (VEEMARKTSTRAAT 1)",
    },
    {
      id: "1826",
      address: "CHAPELLE-LEZ-HERLAIM (CLOS DU CHENE AU BOIS 13)",
    },
    {
      id: "510",
      address: "CHATELET (PLACE DU MARCHE 11)",
    },
    {
      id: "877",
      address: "CHENEE (RUE DE BEAUFRAIPONT 16)",
    },
    {
      id: "757",
      address: "CINEY (RUE SAINT GILLES 17)",
    },
    {
      id: "1003",
      address: "CORBAIS (GRAND'ROUTE 89)",
    },
    {
      id: "2717",
      address: "COURCELLES (RUE DE TRAZEGNIES 154)",
    },
    {
      id: "864",
      address: "CUESMES (PLACE DE CUESMES 57)",
    },
    {
      id: "646",
      address: "DAMPREMY (CHAUSSEE DE BRUXELLES 233)",
    },
    {
      id: "654",
      address: "DEINZE (GAMPELAEREDREEF 1)",
    },
    {
      id: "3490",
      address: "DEINZE (ASTENE) (GAMPELAEREDREEF 1)",
    },
    {
      id: "700",
      address: "DENDERMONDE (OUDE VEST 98)",
    },
    {
      id: "897",
      address: "DEURNE (ANTWERPEN) (HERENTALSEBAAN 57-A)",
    },
    {
      id: "489",
      address: "DEURNE (ANTWERPEN) (EYENDIJKSTRAAT 22)",
    },
    {
      id: "841",
      address: "DIEST (LEUVENSESTEENWEG 72)",
    },
    {
      id: "878",
      address: "DIKSMUIDE (RIJKSWACHTSTRAAT 1)",
    },
    {
      id: "455",
      address: "DILBEEK (NINOOFSESTEENWEG 170)",
    },
    {
      id: "1671",
      address: "DINANT (RUE SAINT JACQUES 355)",
    },
    {
      id: "871",
      address: "DOTTENIJS (ALBERT DEGANDTPLEIN 18)",
    },
    {
      id: "1004",
      address: "DOUR (RUE D'ELOUGES 104)",
    },
    {
      id: "676",
      address: "EDINGEN (HOVESSTRAAT 124)",
    },
    {
      id: "652",
      address: "EEKLO (VROMBAUTSTRAAT 24)",
    },
    {
      id: "610",
      address: "EKEREN (KLOOSTERSTRAAT 122)",
    },
    {
      id: "879",
      address: "ELLEZELLES (RUE JEAN VINOIS 27)",
    },
    {
      id: "2221",
      address: "EPINOIS (ROUTE DE CHARLEROI 159)",
    },
    {
      id: "2750",
      address: "EPPEGEM (KREUPELSTRAAT 32)",
    },
    {
      id: "1675",
      address: "ERQUELINNES (ROUTE DE MONS 300)",
    },
    {
      id: "471",
      address: "ETTERBEEK (GRAYSTRAAT 102)",
    },
    {
      id: "2713",
      address: "EUPEN (HERBESTHALER STRASSE 56)",
    },
    {
      id: "625",
      address: "EVERE (OUD-STRIJDERSLAAN 42)",
    },
    {
      id: "635",
      address: "EVERE (HAACHTSESTEENWEG 1031)",
    },
    {
      id: "458",
      address: "FLERON (AVENUE DES MARTYRS 45)",
    },
    {
      id: "2220",
      address: "FLORENVILLE (RUE DE LA STATION 77)",
    },
    {
      id: "813",
      address: "FRASNES-LEZ-BUISSENA (ROUTE DE LESSINES 4A)",
    },
    {
      id: "848",
      address: "GEEL (ANTWERPSEWEG 87)",
    },
    {
      id: "490",
      address: "GEMBLOUX (RUE DE LA SUCRERIE 6)",
    },
    {
      id: "486",
      address: "GENK (VENNESTRAAT 225)",
    },
    {
      id: "3294",
      address: "GENK (HASSELTWEG 214)",
    },
    {
      id: "710",
      address: "GENT (DRONGENSESTEENWEG 197)",
    },
    {
      id: "967",
      address: "GERPINNES (CHEE DE PHILIPPEVILLE 271)",
    },
    {
      id: "695",
      address: "GILLY (CHAUSSEE IMPERIALE 61)",
    },
    {
      id: "709",
      address: "GOSSELIES (RUE TAHON 37)",
    },
    {
      id: "1709",
      address: "GREZ-DOICEAU (CHAUSSEE DE WAVRE 328)",
    },
    {
      id: "449",
      address: "GRIMBERGEN (WOLVERTEMSESTEENWEG 248)",
    },
    {
      id: "3061",
      address: "GROBBENDONK (LIERSE STEENWEG 40)",
    },
    {
      id: "868",
      address: "HAACHT (STATIONSSTRAAT 113)",
    },
    {
      id: "904",
      address: "HAALTERT (HOOGSTRAAT 131)",
    },
    {
      id: "1010",
      address: "HACCOURT (PLACE COMMUNALE 1)",
    },
    {
      id: "604",
      address: "HALLE (EDINGENSESTEENWEG 300)",
    },
    {
      id: "697",
      address: "HAMME (O.-VL.) (DAMSTRAAT 91)",
    },
    {
      id: "773",
      address: "HANNUT (RUE DE LANDEN 59)",
    },
    {
      id: "1670",
      address: "HARELBEKE (STASEGEMSESTEENWEG 180)",
    },
    {
      id: "739",
      address: "HASSELT (HENDRIK V. VELDEKESINGEL 36)",
    },
    {
      id: "2654",
      address: "HECHTEL-EKSEL (PEERDERBAAN 17)",
    },
    {
      id: "464",
      address: "HEIST-OP-DEN-BERG (MECHELSESTEENWEG 40)",
    },
    {
      id: "682",
      address: "HELCHTEREN (GROTE BAAN 596)",
    },
    {
      id: "763",
      address: "HERENTALS (BELGIELAAN 42)",
    },
    {
      id: "493",
      address: "HERSTAL (BOULEVARD ZENOBE GRAMME 17)",
    },
    {
      id: "881",
      address: "HEUSY (AVENUE REINE ASTRID 97)",
    },
    {
      id: "605",
      address: "HEVERLEE (GROENVELDSTRAAT 71)",
    },
    {
      id: "693",
      address: "HOBOKEN (ANTWERPEN) (SINT-BERNARDSESTEENWEG 777)",
    },
    {
      id: "921",
      address: "HOOGLEDE (IEPERSTRAAT 88)",
    },
    {
      id: "898",
      address: "HOOGSTRATEN (SINT LENAARTSEWEG 20A)",
    },
    {
      id: "491",
      address: "IEPER (RIJSELSEPOORT 10)",
    },
    {
      id: "779",
      address: "IZEGEM (NEDERWEG 119)",
    },
    {
      id: "1710",
      address: "JAMBES (CHAUSSEE DE LIEGE 477)",
    },
    {
      id: "885",
      address: "JEMAPPES (AVENUE WILSON 510)",
    },
    {
      id: "645",
      address: "JEMEPPE-SUR-MEUSE (RUE DU BIEF 2)",
    },
    {
      id: "457",
      address: "JEMEPPE-SUR-SAMBRE (CHAUSSEE DE CHARLEROI 39)",
    },
    {
      id: "612",
      address: "JETTE (LEOPOLD I STRAAT 511B)",
    },
    {
      id: "928",
      address: "JODOIGNE (RUE DE PIETRAIN 36)",
    },
    {
      id: "511",
      address: "KALMTHOUT (KAPELLENSTEENWEG 186)",
    },
    {
      id: "922",
      address: "KEERBERGEN (PUTSEBAAN 75)",
    },
    {
      id: "440",
      address: "KESSEL-LO (TIENSESTEENWEG 237)",
    },
    {
      id: "768",
      address: "KNOKKE (NATIENLAAN 191)",
    },
    {
      id: "873",
      address: "KOKSIJDE (ZEELAAN 39)",
    },
    {
      id: "460",
      address: "KOMEN (WERVIKSESTEENWEG 164)",
    },
    {
      id: "487",
      address: "KONTICH (OOSTSTATIESTRAAT 171)",
    },
    {
      id: "596",
      address: "KORTRIJK (SINT-SEBASTIAANSLAAN 2)",
    },
    {
      id: "597",
      address: "KRAAINEM (FERDINAND KINNENSTRAAT 9F)",
    },
    {
      id: "966",
      address: "KUURNE (KLEINE RINGLAAN 9)",
    },
    {
      id: "2623",
      address: "LA LOUVIERE (RUE DE BAUME-MARPENT 30)",
    },
    {
      id: "701",
      address: "LA LOUVIERE (RUE DU GAZOMETRE 21)",
    },
    {
      id: "557",
      address: "LAKEN (MAURICE DE MOORPLEIN 7)",
    },
    {
      id: "900",
      address: "LANDEN (FABRIEKSTRAAT 21)",
    },
    {
      id: "623",
      address: "LEBBEKE (BRUSSELSESTEENWEG 110)",
    },
    {
      id: "780",
      address: "LEDE (HOOGSTRAAT 44)",
    },
    {
      id: "657",
      address: "LEDEBERG (EDMOND VAN HOOREBEKESTR 69)",
    },
    {
      id: "684",
      address: "LEUVEN (LOMBAARDENSTRAAT 2)",
    },
    {
      id: "740",
      address: "LEUZE-EN-HAINAUT (RUE DE CONDE 56)",
    },
    {
      id: "485",
      address: "LIEDEKERKE (KLEEMPUTTENSTRAAT 65)",
    },
    {
      id: "699",
      address: "LIEGE 2 (RUE GRETRY 235)",
    },
    {
      id: "560",
      address: "LIEGE 2 (BLD DE LA CONSTITUTION 32)",
    },
    {
      id: "456",
      address: "LIER (ANTWERPSESTEENWEG 344)",
    },
    {
      id: "508",
      address: "LOCHRISTI (ANTWERPSE STEENWEG 44-46)",
    },
    {
      id: "924",
      address: "LOKEREN (GENTSE STEENWEG 22)",
    },
    {
      id: "793",
      address: "LOMMEL (KONING LEOPOLDLAAN 37)",
    },
    {
      id: "462",
      address: "LONDERZEEL (MOLENSTRAAT 39)",
    },
    {
      id: "892",
      address: "MAASEIK (KLOOSTERBEMPDEN 9)",
    },
    {
      id: "798",
      address: "MALDEGEM (KONING LEOPOLDLAAN 2)",
    },
    {
      id: "805",
      address: "MALMEDY (AVENUE DES ALLIES 2)",
    },
    {
      id: "771",
      address: "MARBAIS (BR.W.) (CHAUSSEE DE NAMUR 264)",
    },
    {
      id: "494",
      address: "MARCHE-EN-FAMENNE (VIEILLE ROUTE DE LIEGE 10)",
    },
    {
      id: "512",
      address: "MARCHIENNE-AU-PONT (RUE LIEUTEN.GEN.GILLAIN 43)",
    },
    {
      id: "708",
      address: "MARCINELLE (RUE DU GRAND PONT 16)",
    },
    {
      id: "683",
      address: "MECHELEN (LIERSESTEENWEG 351)",
    },
    {
      id: "616",
      address: "MECHELEN-AAN-DE-MAAS (RIJKSWEG 583)",
    },
    {
      id: "698",
      address: "MENEN (IEPERSTRAAT 435)",
    },
    {
      id: "3323",
      address: "MERCHTEM (BLEUKENWEG 5)",
    },
    {
      id: "715",
      address: "MERELBEKE (HUNDELGEMSESTEENWEG 696)",
    },
    {
      id: "583",
      address: "MERKSEM (MOLENLEI 1)",
    },
    {
      id: "670",
      address: "MERKSEM (GROENENDAALLAAN 111)",
    },
    {
      id: "753",
      address: "METTET (RUE ALBERT-IER 121)",
    },
    {
      id: "688",
      address: "MIDDELKERKE (OOSTENDELAAN 298)",
    },
    {
      id: "2819",
      address: "MOESKROEN (MENENSTRAAT 136)",
    },
    {
      id: "620",
      address: "MOL (ROZENBERG 64)",
    },
    {
      id: "686",
      address: "MONS (CHAUSSEE DE BINCHE 123)",
    },
    {
      id: "672",
      address: "MORLANWELZ-MARIEMONT (CHAUSSEE DE MARIEMONT 36)",
    },
    {
      id: "988",
      address: "MORTSEL (DRABSTRAAT 172)",
    },
    {
      id: "749",
      address: "NAMUR (CHAUSSEE DE CHARLEROI 16)",
    },
    {
      id: "884",
      address: "NEU-MORESNET (LUTTICHER STRASSE 145)",
    },
    {
      id: "958",
      address: "NIEUWPOORT (JOZEF CARDIJNLAAN 33-A)",
    },
    {
      id: "859",
      address: "NIMY (RUE DES VIADUCS 287)",
    },
    {
      id: "901",
      address: "NINOVE (POLDERBAAN 26)",
    },
    {
      id: "595",
      address: "NIVELLES (CHAUSSEE DE BRUXELLES 170)",
    },
    {
      id: "618",
      address: "NOSSEGEM (VOSKAPELLELAAN 11)",
    },
    {
      id: "980",
      address: "OOSTENDE (TORHOUTSESTEENWEG 529)",
    },
    {
      id: "794",
      address: "OOSTROZEBEKE (INGELMUNSTERSTEENWEG 16)",
    },
    {
      id: "2733",
      address: "OPWIJK (STEENWEG OP MERCHTEM 137)",
    },
    {
      id: "641",
      address: "OTTIGNIES (AVENUE DU DOUAIRE 2038)",
    },
    {
      id: "644",
      address: "OUD-TURNHOUT (BLEKERIJSTRAAT 2)",
    },
    {
      id: "447",
      address: "OUDENAARDE (TACAMBAROPLEIN 3)",
    },
    {
      id: "459",
      address: "OUDERGEM (TRIOMFLAAN 20)",
    },
    {
      id: "712",
      address: "OVERBOELARE (GROTEWEG 373)",
    },
    {
      id: "606",
      address: "OVERIJSE (BRUSSELSESTEENWEG 482A)",
    },
    {
      id: "498",
      address: "PELT (HEERSTRAAT 68)",
    },
    {
      id: "2812",
      address: "PERUWELZ (RUE NEUVE CHAUSSEE 157)",
    },
    {
      id: "784",
      address: "POPERINGE (DUINKERKESTRAAT 56)",
    },
    {
      id: "1680",
      address: "QUEVAUCAMPS (RUE DE TOURNAI 167)",
    },
    {
      id: "713",
      address: "RAMSEL (STATIONSSTRAAT 9)",
    },
    {
      id: "759",
      address: "RECOGNE (LE BLANCHEAU 8)",
    },
    {
      id: "776",
      address: "RIXENSART (RUE AVIATEUR HUENS 16)",
    },
    {
      id: "2712",
      address: "ROCHEFORT (RUE DE DINANT 4)",
    },
    {
      id: "899",
      address: "ROESELARE (HEROPBOUWSTRAAT 163)",
    },
    {
      id: "754",
      address: "ROESELARE (DIKSMUIDSESTEENWEG 114)",
    },
    {
      id: "1659",
      address: "RONSE (OSWALD PONETTESTRAAT 52)",
    },
    {
      id: "609",
      address: "SAINT-NICOLAS(LIEGE) (RUE ADOLPHE RENSON 17)",
    },
    {
      id: "1008",
      address: "SART-LEZ-SPA (BALMORAL 1)",
    },
    {
      id: "558",
      address: "SCHAARBEEK (JERUSALEMSTRAAT 8-16)",
    },
    {
      id: "469",
      address: "SCHAARBEEK (ROODEBEEKLAAN 17)",
    },
    {
      id: "2826",
      address: "SCHAARBEEK (LEUVENSESTEENWEG 510)",
    },
    {
      id: "551",
      address: "SCHERPENHEUVEL (AUGUST NIHOULSTRAAT 139)",
    },
    {
      id: "451",
      address: "SCHOTEN (PAALSTRAAT 275)",
    },
    {
      id: "553",
      address: "SERAING (RUE PUITS-MARIE 61)",
    },
    {
      id: "762",
      address: "SINT-AMANDSBERG (DENDERMONDSESTEENWEG 409)",
    },
    {
      id: "631",
      address: "SINT-ANDRIES (GISTELSE STEENWEG 345)",
    },
    {
      id: "1825",
      address: "SINT-DENIJS-WESTREM (KORTRIJKSESTEENWEG 1238)",
    },
    {
      id: "923",
      address: "SINT-GILLIS-WAAS (BLOKSTRAAT 8)",
    },
    {
      id: "639",
      address: "SINT-KATELIJNE-WAVER (PUTSESTEENWEG 87)",
    },
    {
      id: "1059",
      address: "SINT-NIKLAAS (SMISSTRAAT 109)",
    },
    {
      id: "453",
      address: "SINT-NIKLAAS (NIJVERHEIDSSTRAAT 54)",
    },
    {
      id: "726",
      address: "SINT-PIETERS-LEEUW (BERGENSESTEENWEG 201)",
    },
    {
      id: "577",
      address: "SINT-TRUIDEN (TIENSESTEENWEG 266)",
    },
    {
      id: "844",
      address: "SOIGNIES (CHEMIN SAINT-LANDRY 10A)",
    },
    {
      id: "727",
      address: "ST-JANS-MOLENBEEK (GENTSESTEENWEG 362)",
    },
    {
      id: "474",
      address: "ST-PIETERS-WOLUWE (PIERRE DE COCKSTRAAT 5)",
    },
    {
      id: "619",
      address: "STABROEK (ABTSDREEF 10/E)",
    },
    {
      id: "725",
      address: "TEMSE (VRIJHEIDSTRAAT 19)",
    },
    {
      id: "865",
      address: "TERTRE (RUE DEFUISSEAUX 49)",
    },
    {
      id: "858",
      address: "THUIN (DREVE DES ALLIES 108)",
    },
    {
      id: "1708",
      address: "TIELT (DEKEN DARRASLAAN 66)",
    },
    {
      id: "893",
      address: "TIENEN (ZIJDELINGSESTRAAT 54)",
    },
    {
      id: "513",
      address: "TIENEN (ZIJDELINGSESTRAAT 64)",
    },
    {
      id: "883",
      address: "TONGEREN (ASTRIDLAAN 33)",
    },
    {
      id: "452",
      address: "TORHOUT (KORTEMARKSTRAAT 25)",
    },
    {
      id: "750",
      address: "TOURNAI (QUAI DES SALINES 2)",
    },
    {
      id: "785",
      address: "TOURNAI (CHAUSSEE DE RENAIX 113)",
    },
    {
      id: "696",
      address: "UKKEL (STALLESTRAAT 161)",
    },
    {
      id: "1057",
      address: "VELDWEZELT (KIEZELWEG 16)",
    },
    {
      id: "473",
      address: "VERVIERS (BOULEVARD D GERARDCHAMPS 60)",
    },
    {
      id: "495",
      address: "VEURNE (VAARTSTRAAT 18)",
    },
    {
      id: "554",
      address: "VILVOORDE (MUTSAERTPLAATS 23)",
    },
    {
      id: "626",
      address: "VILVOORDE (SCHAARBEEKLEI 57)",
    },
    {
      id: "925",
      address: "VIRTON (RUE DE MONTMEDY 14)",
    },
    {
      id: "566",
      address: "VORST (VICTOR ROUSSEAULAAN 208)",
    },
    {
      id: "834",
      address: "VOSSELAAR (ANTWERPSESTEENWEG 21)",
    },
    {
      id: "2624",
      address: "WALCOURT (RUE ST.DONAT 23)",
    },
    {
      id: "755",
      address: "WAREGEM (NOORDERLAAN 50)",
    },
    {
      id: "647",
      address: "WAREMME (RUE DU MOULIN 17)",
    },
    {
      id: "465",
      address: "WASMUEL (SENTIER DU PIED VERT 10)",
    },
    {
      id: "497",
      address: "WATERLOO (CHAUSSEE DE BRUXELLES 684)",
    },
    {
      id: "624",
      address: "WAVRE (AVENUE DES PRINCES 45)",
    },
    {
      id: "861",
      address: "WELLIN (RUE DE LA STATION 26)",
    },
    {
      id: "1792",
      address: "WEMMEL (ROMEINSE STEENWEG 906)",
    },
    {
      id: "977",
      address: "WEPION (CHAUSSEE DE DINANT 1320)",
    },
    {
      id: "559",
      address: "WESTMALLE (ANTWERPSESTEENWEG 335)",
    },
    {
      id: "613",
      address: "WETTEREN (GENTSESTEENWEG 95)",
    },
    {
      id: "611",
      address: "WILRIJK (LAARSTRAAT 34)",
    },
    {
      id: "926",
      address: "WOMMELGEM (HERENTALSEBAAN 587)",
    },
    {
      id: "912",
      address: "WONDELGEM (BOTESTRAAT 23)",
    },
    {
      id: "665",
      address: "ZEDELGEM (SINT-LAURENTIUSSTRAAT 32)",
    },
    {
      id: "556",
      address: "ZELE (LANGE AKKER 2-6)",
    },
    {
      id: "483",
      address: "ZELZATE (WESTKADE 6)",
    },
    {
      id: "891",
      address: "ZOERSEL (ZOERSELSTEENWEG 8A)",
    },
    {
      id: "2830",
      address: "ZONHOVEN (HEUVENEINDEWEG 62)",
    },
    {
      id: "653",
      address: "ZOTTEGEM (BIJLOKESTRAAT 2)",
    },
  ],
  storeQuery: "",
  registerUser: {
    username: "",
    password: "",
    firstname: "",
    lastname: "",
    email: "",
    location: "",
  },
};

export const getUser = createAsyncThunk(
  "user/get",
  async (_, { dispatch, getState }) => {
    var existing = getState().user.user;

    if (existing) {
      return existing;
    }
    const user = await UserService.getUser();

    return user;
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async (_, { dispatch, getState }) => {
    var user = getState().user.registerUser;

    await AuthService.register(user);
    history.push("/login");
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (data, { dispatch, getState }) => {
    var user = getState().user.user;
    const updatedUser = await UserService.updateUser(user);

    var tokenUser = { ...TokenService.getUser() };
    tokenUser.configuration.colruytLocation =
      updatedUser.configuration.colruytLocation;
    await TokenService.setUser(tokenUser);
    if (updatedUser) {
      toast.success("Profile saved");
    } else {
      toast.error("Error saving profile");
    }
    return updatedUser;
  }
);

const { actions, reducer } = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateName: (state, action) => {
      state.user.lastName = action.payload;
    },
    updateFirstName: (state, action) => {
      state.user.firstName = action.payload;
    },
    updateEmail: (state, action) => {
      state.user.email = action.payload;
    },
    updateColruytStore: (state, action) => {
      state.user.configuration.colruytLocation = action.payload;
    },
    registerColruytStore: (state, action) => {
      state.registerUser.configuration.colruytLocation = action.payload;
    },
    registerName: (state, action) => {
      state.registerUser.lastname = action.payload;
    },
    registerFirstName: (state, action) => {
      state.registerUser.firstname = action.payload;
    },
    registereEmail: (state, action) => {
      state.registerUser.email = action.payload;
    },
    registerPassword: (state, action) => {
      state.registerUser.password = action.payload;
    },
    registerUserName: (state, action) => {
      state.registerUser.username = action.payload;
    },
    updateColruytQuery: (state, action) => {
      state.storeQuery = action.payload;

      let query = action.payload.toUpperCase();
      let stores = state.colruytStores.filter(
        (x) => x.address.indexOf(query) >= 0
      );

      state.filteredColruytStores = stores;
      if (state.filteredColruytStores.length === 1) {
        state.user.configuration.colruytLocation =
          state.filteredColruytStores[0].id;
      }
    },
    registerColruytQuery: (state, action) => {
      state.storeQuery = action.payload;

      let query = action.payload.toUpperCase();
      let stores = state.colruytStores.filter(
        (x) => x.address.indexOf(query) >= 0
      );

      state.filteredColruytStores = stores;
      if (state.filteredColruytStores.length === 1) {
        state.registerUser.configuration.colruytLocation =
          state.filteredColruytStores[0].id;
      }
    },
    getColruytStores: (state, action) => {
      //   console.log("inpay", action.payload);
      //   let query = action.payload.toUpper();
      //   let stores = state.colruytStores.filter(
      //     x => x.address.indexOf(query) <= 0
      //   );
      //   state.filteredColruytStores = stores;
    },
    initializeColruytStores: (state, action) => {
      state.filteredColruytStores = state.colruytStores;
    },
    updateReceivePromotionMail: (state, action) => {
      state.user.configuration.receivePromotionMail =
        !state.user.configuration.receivePromotionMail;
    },
    updateReceiveTweedehandsMail: (state, action) => {
      state.user.configuration.receiveTweedeHandsMail =
        !state.user.configuration.receiveTweedeHandsMail;
    },
    updateReceivePriceUpdateMail: (state, action) => {
      state.user.configuration.receivePriceUpdateMail =
        !state.user.configuration.receivePriceUpdateMail;
    },
  },
  extraReducers: {
    [getUser.fulfilled]: (state, { meta, payload }) => {
      state.user = payload;
    },
    [updateUser.fulfilled]: (state, { meta, payload }) => {
      state.user = payload;
    },
  },
});

export const {
  updateName,
  updateFirstName,
  updateEmail,
  updateColruytStore,
  updateColruytQuery,
  getColruytStores,
  initializeColruytStores,
  registerColruytStore,
  registerFirstName,
  registerName,
  registerPassword,
  registerUserName,
  registereEmail,
  registerColruytQuery,
  updateReceivePriceUpdateMail,
  updateReceiveTweedehandsMail,
  updateReceivePromotionMail,
} = actions;
export default reducer;
