import instance from "./Api";
import TokenService from "./TokenService";

class ShoppingListService {
  getShoppingList = (id) => instance.get(`/ShoppingList/${id}`);
  getShoppingListsForGroup = (id) => instance.get(`/ShoppingList/group/${id}`);
  deleteShoppingList = (id) => instance.delete(`/ShoppingList/${id}`);
  getShoppingListsForUser = (userId) =>
    instance.get(`/ShoppingList/user/${userId}`);
  createShoppingListFromItemGroup = (userId, groupId) =>
    instance.put(`/ShoppingList/user/${userId}/group/${groupId}`);
  switchShoppingListitem = (id, itemId) =>
    instance.put(`/ShoppingList/${id}/item/${itemId}/switch`);
}
export default new ShoppingListService();
