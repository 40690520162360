import React from "react";
import { useState, useEffect } from "react";
import { FormControl, Input, MenuItem, Select, Button } from "@mui/material";
import { Card, Grid, Row, Text } from "@nextui-org/react";
import { useDispatch } from "react-redux";
import {
  deleteConfigTasks,
  updateConfigTasks,
  addConfigTask,
} from "../../redux/reducers/searchSlice";
import { useSelector } from "react-redux";

export default function StoreScrapeTask(props) {
  const [task, setTask] = useState({});
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.search.steps);
  const taskTypes = useSelector((state) => state.search.taskTypes);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "95%",
          marginBottom: "5px",
        }}
      >
        <Text style={{ minWidth: "150px" }}>{"Step"}:</Text>
        <Select
          variant="standard"
          value={props.task.step}
          onChange={(e) =>
            dispatch(
              updateConfigTasks({
                type: props.type,
                id: props.task.id,
                key: "step",
                value: e.target.value,
              })
            )
          }
        >
          {steps.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "95%",
          marginBottom: "5px",
        }}
      >
        <Text style={{ minWidth: "150px" }}>{"Action"}:</Text>
        <Select
          variant="standard"
          value={props.task.type}
          onChange={(e) =>
            dispatch(
              updateConfigTasks({
                type: props.type,
                id: props.task.id,
                key: "type",
                value: e.target.value,
              })
            )
          }
        >
          {taskTypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </Select>
      </div>

      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "95%",
            marginBottom: "5px",
          }}
        >
          <Text style={{ minWidth: "150px" }}>{"Parameter 1"}:</Text>
          <Input
            value={props.task.parameter1}
            onChange={(e) =>
              dispatch(
                updateConfigTasks({
                  type: props.type,
                  id: props.task.id,
                  key: "parameter1",
                  value: e.target.value,
                })
              )
            }
            placeholder="parameter1"
            type="text"
          />
        </div>
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "95%",
            marginBottom: "5px",
          }}
        >
          <Text style={{ minWidth: "150px" }}>{"Parameter 2"}:</Text>

          <Input
            value={props.task.parameter2}
            onChange={(e) =>
              dispatch(
                updateConfigTasks({
                  type: props.type,
                  id: props.task.id,
                  key: "parameter2",
                  value: e.target.value,
                })
              )
            }
            placeholder="parameter2"
            type="text"
          />
        </div>
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button
            onClick={() =>
              dispatch(
                deleteConfigTasks({ type: props.type, id: props.task.id })
              )
            }
          >
            DELETE
          </Button>
          <Button
            onClick={() =>
              dispatch(
                addConfigTask({ type: props.type, position: props.task.order })
              )
            }
          >
            Insert step
          </Button>
        </div>
      </Row>
    </React.Fragment>
  );
}
