import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getItemsForCategory,
  updateFilter,
} from "../../../redux/reducers/itemSlice";

import "./ItemOverviewFilter.css";
import { Col, Row } from "reactstrap";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function ItemOverviewFilterMobile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.item.filter);
  const category = useSelector((state) => state.item.category);
  const tags = useSelector((state) => state.item.userTags);
  return (
    <Row>
      <Col xs={12} style={{ marginBottom: "5px" }}>
        {" "}
        {tags && (
          <FormControl
            variant="standard"
            className=""
            style={{
              marginTop: "10px",
              width: "100%",
            }}
          >
            <Select
              value={category}
              endAdornment={
                <InputAdornment position="start">
                  <IconButton style={{ marginRight: "15px" }}>
                    <Close
                      onClick={() => {
                        dispatch(getItemsForCategory(""));
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              }
              disabled={false}
              onChange={(e) => {
                dispatch(getItemsForCategory(e.target.value));
              }}
            >
              <MenuItem key={t("show_all")} value={""}>
                {t("show_all")}
              </MenuItem>
              {tags.map((tag) => (
                <MenuItem key={tag.value} value={tag.label}>
                  {tag.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Col>
      <Col xs={12} style={{ marginBottom: "15px" }}>
        <FormControl style={{ width: "100%", marginTop: "10px" }}>
          <Input
            onChange={(e) => {
              dispatch(updateFilter(e.target.value));
            }}
            value={filter}
            placeholder={t("query")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton style={{ marginLeft: "15px" }}>
                  <Close
                    onClick={() => {
                      dispatch(updateFilter(""));
                    }}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Col>
    </Row>
  );
}
