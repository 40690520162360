import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getItemGroup,
  getItemGroups,
  refreshItem,
} from "../../redux/reducers/itemSlice";
import TokenService from "../../Services/TokenService";
const connectionUrl = process.env.REACT_APP_SOCKET_URL;
let socket = null;
function SocketHandler() {
  const user = TokenService.getUser();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      connectSocket(user.userId);
    }
  });
  const connectSocket = (userId) => {
    if (socket) return;
    socket = new WebSocket(`${connectionUrl}/${userId}`);
    socket.onopen = () => {
      console.log("connected to socket");
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log("Disconnected.");
      } else {
        console.log("Connection lost."); // for example if server processes is killed
      }
      console.log("Code: " + event.code + ". Reason: " + event.reason);
      socket = null;
      setTimeout(() => {
        console.log("reconnecting");
        connectSocket(userId);
      }, 2000);
    };

    socket.onmessage = (event) => {
      var messageObject = JSON.parse(event.data);
      const socketMessageType = parseInt(messageObject.Type, 10);
      switch (socketMessageType) {
        case 0:
          if (window.location.pathname.indexOf(messageObject.Message) >= 0)
            dispatch(refreshItem(messageObject.Message));
          break;
        case 1:
          if (window.location.pathname.indexOf(messageObject.Message) >= 0) {
            dispatch(getItemGroup(messageObject.Message));
          } else if (
            window.location.pathname.indexOf("itemgroups") >= 0 &&
            window.location.pathname.indexOf("-") < 0
          ) {
            dispatch(getItemGroups());
          }
          break;
        default:
          break;
      }
    };

    socket.onerror = function (error) {
      console.log("Error: " + error.message);
    };
  };
  return <React.Fragment />;
}

export default SocketHandler;
