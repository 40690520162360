import React from "react";

import { CenteredImage } from "../../Shared/CenteredImage";
import ItemStoreImage from "../../Shared/ItemStoreImage";

export function ItemSearchResultCard(props) {
  const description = props.item.Description || props.item.description;
  const url = props.item.Url || props.item.url;
  const priceString = props.item.PriceString || props.item.priceString;
  const image = props.item.Image || props.item.image;
  const store = props.item.Store || props.item.store;
  const promotion = props.item.Promotion || props.item.promotion;
  const promotionEnd = props.item.PromotionEnd || props.item.promotionEnd;
  const promotionStart = props.item.PromotionStart || props.item.promotionStart;

  const shortDescription =
    description && description.length > 40
      ? description.slice(0, 39)
      : description;

  const handleNavigateStore = () => {
    window.open(url, "_blank");
  };
  return (
    <React.Fragment>
      <div style={{ margin: "5px" }}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <p style={{ textTransform: "uppercase" }}>
              <b>{shortDescription}</b>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
              marginLeft: "10px",
            }}
          >
            <p>
              <b>{priceString}</b>
            </p>
          </div>

          <hr
            style={{
              borderStyle: "solid",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          ></hr>

          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <div
              style={{ display: "flex", cursor: "pointer" }}
              onClick={() => handleNavigateStore()}
            >
              <div
                className="itemoverviewcard-image-container"
                style={{
                  maxWidth: "125px",
                  minWidth: "115px",
                  // marginRight: "25px",
                  marginLeft: "15px",
                }}
              >
                <CenteredImage size="95px" src={image} alt={store} />
                <div className="itemoverviewcard-image-itemstoreimage-container">
                  <ItemStoreImage storeId={store} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "-15px",
                flexGrow: "1",
                // alignItems: "center",
              }}
            >
              {" "}
              {promotion && (
                <div>
                  <div style={{ textAlign: "center" }}>
                    <p style={{ fontSize: "18px" }}> {promotion}</p>

                    {promotionStart && (
                      <p style={{ fontSize: "12px" }}>
                        {`van ${promotionStart} tot ${promotionEnd}`}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
