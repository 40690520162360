import React from "react";
import { useSelector } from "react-redux";
import { Container } from "@nextui-org/react";
import ItemSearchResultCardSourceWrapper from "./ItemSearchResultCardSourceWrapper";
import { Row } from "reactstrap";

export default function ItemSuggestions() {
  const items = useSelector((state) => state.item.suggestions);
  return (
    <Container>
      {items && items.length > 0 && (
        <React.Fragment>
          <Row justify="center">SUGGESTIONS</Row>
          <div
            style={{
              marginBottom: "10px",
              height: "600px",
              width: "100%",
              backgroundColor: "white",
              paddingRight: "15px",
              boxSizing: "content-box",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {items.length > 0 && (
              <Row>
                {items.map((item, i) => {
                  return (
                    <ItemSearchResultCardSourceWrapper
                      item={item}
                      key={item.image}
                    />
                  );
                })}
              </Row>
            )}
          </div>
        </React.Fragment>
      )}
    </Container>
  );
}
