import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Text, Row } from "@nextui-org/react";
import ItemGroupService from "../../Services/ItemGroupService";
import ItemGroupSharedItemCard from "../../Components/ItemGroups/ItemGroupSharedItemCard";

export default function SharedList() {
  const params = useParams();

  const [group, setGroup] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const data = await ItemGroupService.getSharedItemGroup(params.id);
      setGroup(data);
    };

    getData();
  }, [params.id]);

  return (
    group && (
      <div fluid>
        <Row wrap="no-wrap" justify="space-between">
          <Text h3>{group.description}</Text>
          <Text h3>{group.priceString}</Text>
        </Row>
        <div>
          <Row>{group.items.length > 0 && <Text h4>ITEMS</Text>}</Row>
          <Grid.Container gap={2} justify="start" direction="row">
            {group.items.map((item) => (
              <ItemGroupSharedItemCard
                key={item.id}
                item={item}
                groupId={params.id}
              />
            ))}
          </Grid.Container>
        </div>
        {/* </Container> */}
      </div>
    )
  );
}
