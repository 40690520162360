import React from "react";
import ItemStoreImage from "../../Shared/ItemStoreImage";

export default function ItemPromotionCardLine(props) {
  console.log(props);
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
          // borderStyle: "solid",
          // borderColor: "#f6f6ff",
          // borderWidth: "1px",
          // borderRadius: "10px",
          padding: "10px",
          marginLeft: "10px",
          marginRight: "10px",
          marginBottom: "10px",
        }}
        // className="shadow-light"
      >
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "15px",
            flexGrow: "1",
          }}
        > */}
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
        <div>
          <a href={props.source.url} target={"_blank"} rel="noreferrer">
            <ItemStoreImage storeId={props.source.store}></ItemStoreImage>
          </a>
        </div>
        <div
          style={{
            display: "flex",

            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <div>
            <div style={{ textAlign: "center" }}>
              <p style={{ fontSize: "18px" }}> {props.source.promotionText}</p>

              {props.source.promotionStart && props.source.promotionEnd && (
                <p style={{ fontSize: "12px" }}>
                  {`van ${props.source.promotionStart} tot ${props.source.promotionEnd}`}
                </p>
              )}

              {!props.source.promotionStart && props.source.promotionEnd && (
                <p style={{ fontSize: "12px" }}>
                  {`tot ${props.source.promotionEnd}`}
                </p>
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <p
            style={{
              marginRight: "10px",
              flexWrap: "nowrap",
              minWidth: "55px",
              textDecoration: "Line-through",
            }}
          >
            {props.source.unitPriceString}
          </p>
          <p
            style={{
              marginRight: "10px",
              flexWrap: "nowrap",
              minWidth: "55px",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            {props.source.promoUnitPriceString}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
