import React, { useEffect, useState } from "react";
import "./ItemGroups.css";
import { CenteredImage } from "../Shared/CenteredImage";
import ItemStoreImage from "../Shared/ItemStoreImage";
import { Text, Grid, Card, Spacer, Button, Row } from "@nextui-org/react";
import { useDispatch } from "react-redux";
import {
  deleteItemGroupItem,
  updateItemGroupItem,
} from "../../redux/reducers/itemSlice";
import { useTranslation } from "react-i18next";

export default function ItemGroupSharedItemCard(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleDelete = () => {
    if (window.confirm(t("confirm_delete_item"))) {
      dispatch(
        deleteItemGroupItem({ id: props.item.id, groupId: props.groupId })
      );
    }
  };

  const handleUpdate = (amount) => {
    dispatch(updateItemGroupItem({ itemId: props.item.item.id, amount }));
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(props.item.amount);
  }, [props.item]);

  const descrease = () => {
    if (count > 1) {
      handleUpdate(count - 1);
      setCount(count - 1);
    }
  };

  const increase = () => {
    handleUpdate(count + 1);
    setCount(count + 1);
  };

  return (
    // <Col sm={12} md={6} lg={6} className="colSpacing">
    <Grid xs={12} sm={6} md={6} lg={4}>
      <Card>
        <Card.Body>
          <Row
            wrap="no-wrap"
            justify="space-between"
            style={{ minHeight: "75px" }}
          >
            <Text> {props.item.item.description}</Text>
            <Text style={{ minWidth: "90px", textAlign: "right" }} h3>
              {props.item.total}
            </Text>
          </Row>
          <Card.Divider />
          <Spacer y={1} />
          <Row wrap="no-wrap" justify="space-between">
            <div
              className="itemoverviewcard-image-container"
              style={{
                maxWidth: "125px",
                minWidth: "115px",
                marginRight: "25px",
              }}
            >
              <CenteredImage
                size="95px"
                src={props.item.item.image}
                alt={props.item.item.store}
              />
              <div className="itemoverviewcard-image-itemstoreimage-container">
                <ItemStoreImage storeId={props.item.item.store} />
              </div>
              <div className="itemoverviewcard-sourcecount">
                {props.item.item.sourceCount}
              </div>
            </div>
          </Row>
          <Card.Divider />
          <Spacer y={1} />
          <Row wrap="no-wrap" justify="space-between">
            <div>
              <div style={{ display: "flex" }}>
                <Button auto onClick={() => descrease()} disabled={true}>
                  -
                </Button>
                <div
                  style={{
                    width: "35px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                  }}
                >
                  <Text size={20}>{count}</Text>
                </div>

                <Button auto onClick={() => increase()} disabled={true}>
                  +
                </Button>
              </div>
            </div>

            <div>
              <Button
                onClick={() => handleDelete()}
                disabled={true}
                light
                color="error"
                size="auto"
              >
                {t("delete")}
              </Button>
            </div>
          </Row>
        </Card.Body>
      </Card>
    </Grid>
  );
}
