import React from "react";
import { useDispatch } from "react-redux";
import {
  addExistingItemToItemGroup,
  addItemGroupToParentItemGroup,
  deleteItemGroup,
} from "../../redux/reducers/itemSlice";

import { Card, Grid, Row, Text } from "@nextui-org/react";
import { history } from "../../App";
import { Button } from "@mui/material";
import { createShoppingListForItemGroup } from "../../redux/reducers/shoppingListSlice";
import { Col } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function ItemGroupCard(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleOnDrag = (e, id) => {
    e.dataTransfer.setData(
      "data",
      JSON.stringify({
        parentId: props.itemGroup.id,
        childId: id,
        type: "group",
      })
    );
  };

  const handleOnDrop = (e) => {
    const dataObject = e.dataTransfer.getData("data");

    const data = JSON.parse(dataObject);

    //return;
    if (data) {
      switch (data.type) {
        case "group":
          dispatch(
            addItemGroupToParentItemGroup({
              parentId: props.itemGroup.id,
              childId: data.childId,
            })
          );
          break;
        case "item":
          dispatch(
            addExistingItemToItemGroup({
              groupId: props.itemGroup.id,
              itemId: data.childId,
            })
          );
          break;
        default:
          break;
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <Col
      onDragStart={(e) => handleOnDrag(e, props.itemGroup.id)}
      onDrop={handleOnDrop}
      onDragOver={handleDragOver}
      xs={12}
      sm={12}
      md={6}
      lg={6}
      xl={4}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          margin: "15px",
          borderStyle: "solid",
          borderColor: "#f6f6f4",
          borderWidth: "1px",
          borderRadius: "10px",
          padding: "15px",
        }}
        className="shadow"
        // onClick={() => history.push(`/itemgroups/${props.itemGroup.id}`)}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>{props.itemGroup.description}</h3>
          <h4>{props.itemGroup.priceString}</h4>
        </div>

        <hr
          style={{
            borderStyle: "solid",
            marginTop: "5px",
            marginBottom: "10px",
          }}
        ></hr>
        <div>
          <div>
            {props.itemGroup && (
              <Text h5>{`${props.itemGroup.itemCount} ${
                props.itemGroup.itemCount === 1 ? t("item") : t("items")
              }`}</Text>
            )}
            {props.itemGroup && (
              <Text h5>{`${props.itemGroup.groupCount} ${
                props.itemGroup.groupCount === 1 ? t("group") : t("groups")
              }`}</Text>
            )}
          </div>
        </div>
        <hr
          style={{
            borderStyle: "solid",
            marginTop: "5px",
            marginBottom: "10px",
          }}
        ></hr>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            size="auto"
            onClick={() => {
              dispatch(
                createShoppingListForItemGroup({
                  groupId: props.itemGroup.id,
                  storeId: 0,
                })
              );
            }}
          >
            {t("shopping_list")}
          </Button>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="auto"
              onClick={() => history.push(`/itemgroups/${props.itemGroup.id}`)}
            >
              {t("edit")}
            </Button>
            <Button
              size="auto"
              color="error"
              onClick={() => {
                if (
                  props.itemGroup.itemCount + props.itemGroup.groupCount ===
                  0
                ) {
                  dispatch(deleteItemGroup(props.itemGroup.id));
                } else {
                  if (window.confirm(t("confirm_delete_group"))) {
                    dispatch(deleteItemGroup(props.itemGroup.id));
                  }
                }
              }}
            >
              {t("delete")}
            </Button>
          </div>
        </div>
      </div>
      {/* </Link> */}
    </Col>
  );
}
