import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateImage } from "../../../redux/reducers/itemSlice";
import { CenteredImage } from "../../Shared/CenteredImage";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";

function ItemDetailImage() {
  const item = useSelector((state) => state.item.item);
  const dispatch = useDispatch();
  const currentImage = item.image;
  let carouselImages = [];
  if (item.itemSources) {
    const otherImages = item.itemSources
      .map((source) => source.image)
      .filter((s) => s !== currentImage);
    if (currentImage) {
      carouselImages = [currentImage, ...otherImages];
    } else {
      carouselImages = [...otherImages];
    }
  }

  return (
    item &&
    item.itemSources &&
    carouselImages.length > 0 && (
      <div
        className="sixty"
        style={{
          marginBottom: "125px",
          borderTopStyle: "solid",
          borderWidth: "1px",
          borderColor: "lightgrey",
          marginTop: "15px",
        }}
      >
        {carouselImages.length > 0 && carouselImages[0] && (
          <AwesomeSlider
            backgroundColor="white"
            style={{ backgroundColor: "white" }}
            onTransitionEnd={(e) =>
              dispatch(updateImage(e.currentMedia.children.props.src))
            }
          >
            {carouselImages.map((s) => (
              <div key={s} style={{ width: "100%", backgroundColor: "white" }}>
                <CenteredImage src={s} alt={s} size="175px"></CenteredImage>
              </div>
            ))}
          </AwesomeSlider>
        )}
      </div>
    )
  );
}

export default ItemDetailImage;
