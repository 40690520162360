import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    resources: {
      en: {
        translation: {
          groups: "lijsten",
          group: "lijst",
          group_duplicate: "lijst kopieren",
          items: "producten",
          searchitem: "zoek product",
          item_search: "zoek product",
          promotions: "Promoties",
          stores: "Winkels",
          edit: "bewerk",
          delete: "verwijder",
          showall: "toon alles",
          show_all: "toon alles",
          found: "gevonden",
          item: "product",
          description: "omschrijving",
          create: "maak aan",
          add: "voeg toe",
          add_item: "product toevoegen",
          item_add: "product toevoegen",
          item_save: "product opslaan",
          item_edit: "product bewerken",
          item_delete: "product verwijderen",
          add_group: "lijst toevoegen",
          creategroup: "lijst aanmaken",
          group_create: "lijst aanmaken",
          confirm_delete_selected:
            "Bent u zeker dat u de geselecteerde items wilt verwijderen?",
          confirm_merge_selected:
            "Bent u zeker dat u de geselecteerde items wilt samenvoegen?",
          confirm_delete_group:
            "Bent u zeker dat u deze lijst wilt verwijderen?",
          confirm_delete_item: "Bent u zeker dat u dit item wilt verwijderen?",
          merge: "voeg samen",
          clear_selection: "alles deselecteren",
          selected: "geselecteerd",
          shopping_list: "winkellijst",
          shopping_list_update: "winkellijst actualiseren",
          refresh: "ververs",
          copy: "kopieer",
          query: "zoekterm",
          history: "geschiedenis",
          itemsources_refresh: "bronnen verversen",
          itemsource_add: "bron toevoegen",
          itemsource: "bron",
          itemsource_confirm_delete:
            "Bent u zeker dat u deze bron wilt verwijderen?",
          itemsources: "bronnen",
          tag_add: "label toevoegen",
          tag_none: "geen labels",
          secondhand_search: "zoek tweedehands",
          tags: "labels",
          save: "opslaan",
          visit: "website",
          price: "prijs",
          promotion: "promotie",
          searching: "aan het zoeken",
          no_results: "geen resultaten",
          suggestions: "suggesties",
          navigate: "navigeer",
          settings_show: "instellingen weergeven",
          logoff: "afmelden",
          profile: "profiel",
          username: "Gebruikersnaam",
          password: "Wachtwoord",
          login: "aanmelden",
          profile_personaldata: "Gegevens",
          firstname: "Voornaam",
          name: "Naam",
          email: "E-mail",
          colruyt_location: "Colruyt vestiging",
          colruyt_location_select: "kies Colruyt vestiging",
          options: "Voorkeuren",
          receive_promomail: "Dagelijks promotieoverzicht",
          receive_secondhandmail: "Mail bij nieuwe tweedehandsitems",
          receive_priceupdatemail: "Mail bij prijswijzigingen",
          store_new: "nieuwe winkel",
          config_test: "Test Configuratie",
        },
      },
      nl: {
        translation: {
          groups: "Lijsten",
        },
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
