import { configureStore } from "@reduxjs/toolkit";
import itemReducer from "./reducers/itemSlice";
import authenticationReducer from "./reducers/authenticationSlice";
import searchReducer from "./reducers/searchSlice";
import userReducer from "./reducers/UserSlice";
import shoppingListReducer from "./reducers/shoppingListSlice";
import { combineReducers } from "redux";

const reducer = combineReducers({
  item: itemReducer,
  authentication: authenticationReducer,
  search: searchReducer,
  user: userReducer,
  shoppingList: shoppingListReducer,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: true,
});

export default store;
