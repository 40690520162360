import React from "react";
import { useSelector } from "react-redux";
import { Grid, Loading, Container } from "@nextui-org/react";
import { ItemSearchResultCard } from "./ItemSearchResultCard";
import ItemSearchResultCardSourceWrapper from "./ItemSearchResultCardSourceWrapper";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function ItemSearchResults() {
  const items = useSelector((state) => state.search.items);
  const suggestions = useSelector((state) => state.search.suggestions);
  const searchVisible = useSelector((state) => state.search.searchVisible);
  const noResults = useSelector((state) => state.search.noResults);
  const { t } = useTranslation();
  return (
    <Container>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {searchVisible && (
          <Loading>
            <h4>{t("searching")}</h4>
          </Loading>
        )}
        {noResults && <h4>{t("no_results")}</h4>}
      </div>
      <Row>
        {items && items.length > 0 && (
          <>
            <h5 style={{ marginTop: "15px" }}>{`${items.length} ${
              items.length === 1 ? t("item") : t("items")
            } ${t("found")}`}</h5>
            <div
              style={{
                marginBottom: "10px",
                height: "600px",
                width: "100%",
                backgroundColor: "white",
                // paddingRight: "15px",
                // boxSizing: "content-box",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {items.length > 0 && (
                <Row>
                  {items.map((item, i) => {
                    return (
                      <ItemSearchResultCardSourceWrapper
                        item={item}
                        key={item.image}
                      />
                    );
                  })}
                </Row>
              )}
            </div>
          </>
        )}

        {suggestions && suggestions.length > 0 && (
          <>
            {t("suggestions")}
            <div
              style={{
                marginBottom: "10px",
                height: "600px",
                width: "100%",
                backgroundColor: "white",
                // paddingRight: "15px",
                // boxSizing: "content-box",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {suggestions.length > 0 && (
                <Row>
                  {suggestions.map((item, i) => {
                    return (
                      <ItemSearchResultCardSourceWrapper
                        item={item}
                        key={item.image}
                      />
                    );
                  })}
                </Row>
              )}
            </div>
          </>
        )}
      </Row>
    </Container>
  );
}
