import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import CategoryService from "../../Services/CategoryService";
import ItemService from "../../Services/ItemService";
import TokenService from "../../Services/TokenService";
import { history } from "../../App";
import ItemGroupService from "../../Services/ItemGroupService";

const initialState = {
  item: {},
  items: [],
  itemsEternal: [],
  filteredItems: [],
  itemsInCategory: [],
  suggestions: [],
  loading: true,
  category: "Toon alles",
  newItemName: "",
  filter: "",
  itemCreate: false,
  itemGroupSelectOpen: false,
  itemId: 0,
  itemTweedehands: false,
  itemTweedehandsQuery: "",
  itemGroups: [],
  unavailable: [],
  selectedItems: [],
  selectedItemSources: [],
  userTags: [],
  pages: 0,
  selectedTags: [],
  size: 12,
  hits: 0,
  currentPage: 1,
  showItemOverviewGroupCreate: false,
  showItemOverviewAddToGroup: false,
};

let typeTimeOut;

function filterItem(keywords, description) {
  let contains = false;
  let desc = description.toLowerCase();
  keywords.forEach((keyword) => {
    if (desc.indexOf(keyword.toLowerCase()) > -1) {
      contains = true;
    } else {
      contains = false;
    }
  });
  return contains;
}

export const getItem = createAsyncThunk(
  "items/getItem",
  async (itemId, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState().item;
      dispatch(getItemSuggestions(itemId));
      dispatch(setItemLoading(true));
      const item = await ItemService.getItem(itemId);

      return { item };
      //}
    } catch (err) {
      return rejectWithValue(null, err);
    }
  }
);

export const getItemHistory = createAsyncThunk(
  "items/getItemHistory",
  async (itemId, { rejectWithValue, dispatch, getState }) => {
    try {
      const itemHistory = await ItemService.getItemHistory(itemId);
      return itemHistory;
    } catch (err) {
      return rejectWithValue(null, err);
    }
  }
);

export const getItemsForCategory = createAsyncThunk(
  "items/getStoresForCategory",
  async (tag, { rejectWithValue, dispatch, getState }) => {
    const state = getState().item;
    dispatch(updateCategory(tag));

    let tags = [];
    if (tag) {
      tags = [tag];
    }

    dispatch(
      getItemsPaged({
        query: state.filter,
        page: 1,
        tags: tags,
        size: state.size,
      })
    );

    dispatch(
      getItemsPagedEternal({
        query: state.filter,
        page: 1,
        tags: tags,
        size: state.size,
      })
    );
  }
);

// //used
export const refreshItem = createAsyncThunk(
  "items/refreshItem",
  async (itemId, { rejectWithValue, dispatch, getState }) => {
    try {
      const item = await ItemService.getItem(itemId);
      return item;
    } catch (err) {
      return rejectWithValue(null, err);
    }
  }
);

export const getItems = createAsyncThunk(
  "items/getItems",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState().item;

      if (state.items.length === 0) {
        let user = TokenService.getUser();
        const items = await ItemService.getUserItems(user.userId, 0);
        return items;
      }
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const getItemsPaged = createAsyncThunk(
  "items/getItemsPaged",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState().item;
      if (!data.tags) {
        data.tags = state.selectedTags;
      }

      let user = TokenService.getUser();
      const items = await ItemService.getUserItemsPaged(user.userId, {
        query: data.query,
        page: data.page,
        size: data.size,
        tags: data.tags,
      });
      return items;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const getItemsPagedEternal = createAsyncThunk(
  "items/getItemsPagedEternal",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(setCurrentPage(data.page));
      const state = getState().item;
      if (!data.tags) {
        data.tags = state.selectedTags;
      }

      let user = TokenService.getUser();
      const items = await ItemService.getUserItemsPaged(user.userId, {
        query: data.query,
        page: data.page,
        size: data.size,
        tags: data.tags,
      });
      return items;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const updateFilter = createAsyncThunk(
  "items/updateFilter",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState().item;
      dispatch(updateFilterLocal(data));
      clearTimeout(typeTimeOut);
      typeTimeOut = setTimeout(() => {
        dispatch(
          getItemsPaged({
            query: data,
            page: 1,
            tags: state.selectedTags,
            size: state.size,
          })
        );
        dispatch(setCurrentPage(1));
        dispatch(
          getItemsPagedEternal({
            query: data,
            page: 1,
            tags: state.selectedTags,
            size: state.size,
          })
        );
      }, 250);

      // let user = TokenService.getUser();
      // const items = await ItemService.getUserItemsPaged(user.userId, {
      //   query: data,
      //   page: 1,
      //   size: 12,
      //   tags: state.selectedTags,
      // });
      // return items;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const getItemGroups = createAsyncThunk(
  "items/getItemGroups",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      // if (state.itemGroups.length === 0) {
      let user = TokenService.getUser();
      const itemGroups = await ItemGroupService.getItemGroupsForUser(
        user.userId
      );

      return itemGroups;
      //}
      // return state.itemGroups;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const getItemGroup = createAsyncThunk(
  "items/getItemGroup",
  async (groupId, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(clearItemGroup());
      const itemGroup = await ItemGroupService.getItemGroup(groupId);
      return itemGroup;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const addItemTotemGroup = createAsyncThunk(
  "items/addItemToItemGroup",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const itemGroup = await ItemGroupService.addItemToItemGroup(
        data.groupId,
        data.itemId
      );

      return { itemGroup, id: data.groupId };
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const addSelectedItemsTotemGroup = createAsyncThunk(
  "items/addItemsToItemGroup",
  async (groupId, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState().item;
      console.log("", state);
      for (let index = 0; index < state.selectedItems.length; index++) {
        const itemGroup = await ItemGroupService.addItemToItemGroup(
          groupId,
          state.selectedItems[index].id
        );
      }

      history.push(`/itemgroups/${groupId}`);
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const addExistingItemToItemGroup = createAsyncThunk(
  "items/addExistingItemToItemGroup",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const itemGroup = await ItemGroupService.addExistingItemToItemGroup(
        data.groupId,
        data.itemId
      );

      return itemGroup;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const moveItemGroupItemToParent = createAsyncThunk(
  "items/moveItemGroupItemToParent",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const itemGroup = await ItemGroupService.moveItemGroupItemToParent(
        data.groupId,
        data.itemId
      );

      return itemGroup;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const updateItemGroupItem = createAsyncThunk(
  "items/updateItemGroupItem",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const group = getState().item.itemGroup;
      const itemGroup = await ItemGroupService.updateItemGroupItem(
        group.id,
        data
      );
      return itemGroup;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const createItemGroup = createAsyncThunk(
  "items/createItemGroup",
  async (description, { rejectWithValue, dispatch }) => {
    try {
      let user = TokenService.getUser();

      const data = {
        userId: user.userId,
        description,
        items: [],
      };
      const itemGroup = await ItemGroupService.addItemGroup(data);
      dispatch(getItemGroups());
      return itemGroup;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const createItemGroupFromItems = createAsyncThunk(
  "items/createItemGroupFromItems",
  async (description, { rejectWithValue, dispatch, getState }) => {
    try {
      let user = TokenService.getUser();
      const items = getState().item.selectedItems.map((x) => x.id);
      const data = {
        userId: user.userId,
        description,
        items: [...items],
      };
      const itemGroup = await ItemGroupService.addItemGroup(data);
      if (itemGroup) {
        history.push(`/itemgroups/${itemGroup.id}`);
      } else {
        toast.error("Error creating itemgroup");
      }
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const mergeSelectedItems = createAsyncThunk(
  "items/mergeSelectedItems",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      let user = TokenService.getUser();
      const items = getState().item.selectedItems.map((x) => x.id);
      const data = {
        userId: user.userId,
        itemIdList: [...items],
      };
      const itemList = await ItemService.mergeSelectedItems(data);
      if (itemList) {
        return itemList;
      } else {
        toast.error("Error merging items");
      }
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const addSubGoupToItemGroup = createAsyncThunk(
  "items/addsubgroup",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      let user = TokenService.getUser();
      const payload = {
        userId: user.userId,
        description: data.description,
        items: [],
      };
      const itemGroup = await ItemGroupService.addItemGroup(payload);

      const newGroup = await ItemGroupService.addItemGroupToItemGroup(
        data.groupId,
        itemGroup.id
      );
      dispatch(getItemGroup(data.groupId));
      return newGroup;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const deleteItemGroupItem = createAsyncThunk(
  "items/deleteItemGroupItem",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const itemGroup = await ItemGroupService.deleteItemFromItemGroup(
        data.groupId,
        data.id
      );

      return itemGroup;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const addItemGroupToParentItemGroup = createAsyncThunk(
  "items/addItemGroupToParentItemGroup",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const itemGroup = await ItemGroupService.addItemGroupToParentItemGroup(
        data.parentId,
        data.childId
      );
      dispatch(getItemGroups());
      return itemGroup;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const moveItemGroupToParentItemGroup = createAsyncThunk(
  "items/moveItemGroupToParentItemGroup",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const itemGroup = await ItemGroupService.moveItemGroupToParentItemGroup(
        data.parentId,
        data.childId
      );

      return itemGroup;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const deleteItemGroup = createAsyncThunk(
  "items/deleteItemGroup",
  async (groupId, { rejectWithValue, dispatch, getState }) => {
    try {
      var itemgroup = getState().item.itemGroup;
      await ItemGroupService.deleteItemGroup(groupId);
      dispatch(getItemGroup(itemgroup.id));
      // dispatch(getItemGroups());

      return groupId;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const duplicateItemGroup = createAsyncThunk(
  "items/duplicateItemGroup",
  async (data, { rejectWithValue }) => {
    try {
      var newGroup = await ItemGroupService.duplicateItemGroup(data);
      return newGroup;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const deleteItem = createAsyncThunk(
  "items/deleteItem",
  async (_, { rejectWithValue, getState, dispatch }) => {
    if (window.confirm("Do you really want to delete this items?")) {
      try {
        const state = getState().item;

        await ItemService.removeItem(state.item.id);
        dispatch(removeItem(state.item.id));
        toast.success("Item deleted");
        if (window.location.href.indexOf("item") >= 0) history.push("/items");
      } catch (err) {
        toast.error("Error while deleting item");
        return rejectWithValue(null, err);
      }
    }
  }
);

export const deleteItems = createAsyncThunk(
  "items/deleteItems",
  async (_, { rejectWithValue, getState, dispatch }) => {
    if (window.confirm("Do you really want to delete this items?")) {
      try {
        const state = getState().item;

        await ItemService.removeItems(state.selectedItems.map((x) => x.id));
        for (let index = 0; index < state.selectedItems.length; index++) {
          dispatch(removeItem(state.selectedItems[index].id));
        }

        toast.success("Items deleted");
        if (window.location.href.indexOf("item") >= 0) history.push("/items");
      } catch (err) {
        toast.error("Error while deleting items");
        return rejectWithValue(null, err);
      }
    }
  }
);

export const deleteItemInOverview = createAsyncThunk(
  "items/deleteItemInOverview",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState().item;
      await ItemService.removeItem(id);
      dispatch(removeItem(id));
      dispatch(
        getItemsPaged({
          query: state.filter,
          page: state.currentPage,
          size: 12,
        })
      );
      toast.success("Item deleted");
      if (window.location.href.indexOf("item") >= 0) history.push("/items");
    } catch (err) {
      toast.error("Error while deleting item");
      return rejectWithValue(null, err);
    }
  }
);

export const saveItem = createAsyncThunk(
  "items/saveItem",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState().item;
      const data = {
        id: state.item.id,
        description: state.item.description,
        image: state.item.image,
        categoryId: state.item.categoryId,
        tweedehandsQueryString: state.itemTweedehandsQuery,
      };
      const item = await ItemService.updateItem(data);
      dispatch(updateItemInItems(item));
      return { item };
    } catch (err) {
      return rejectWithValue(null, err);
    }
  }
);

export const getCategoriesForUser = createAsyncThunk(
  "items/getCategoriesForUser",
  async (_, { rejectWithValue }) => {
    try {
      let user = TokenService.getUser();
      const response = await CategoryService.getCategoriesForUser(user.userId);
      return response;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const removeSource = createAsyncThunk(
  "items/removeSource",
  async (sourceId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState().item;
      const item = await ItemService.removeSourceFromItem(
        state.item.id,
        sourceId
      );
      dispatch(updateItemInItems(item));
      return item;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const removeSources = createAsyncThunk(
  "items/removeSources",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState().item;
      const item = await ItemService.removeSourcesFromItem(
        state.item.id,
        state.selectedItemSources.map((x) => x.id)
      );
      dispatch(updateItemInItems(item));
      dispatch(clearSelectedItemSources());
      return item;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const getUnavailableSources = createAsyncThunk(
  "items/getUnavailableSources",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const items = await ItemService.getUnavailableSources();
      return items;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const updateSource = createAsyncThunk(
  "items/updatesource",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState().item;
      const item = await ItemService.updateItemSource({
        id: data.id,
        amount: data.amount,
      });
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const removeSourceFromItem = createAsyncThunk(
  "items/removeSource",
  async (sourceData, { rejectWithValue, getState, dispatch }) => {
    try {
      const item = await ItemService.removeSourceFromItem(
        sourceData.itemId,
        sourceData.sourceId
      );
      dispatch(updateItemInItems(item));
      return item;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const addItem = createAsyncThunk(
  "itemCreate/addItem",
  async (_, { dispatch, getState }) => {
    try {
      const user = TokenService.getUser();
      const state = getState().item;
      let newItem = await ItemService.addItem(user.userId, state.item);

      return newItem;
    } catch (err) {
      return null;
    }
  }
);

export const addItemWithSource = createAsyncThunk(
  "itemCreate/addItemWithSource",
  async (source, { getState }) => {
    try {
      const user = TokenService.getUser();
      const item = { ...getState().item.item };
      item.itemSources = [source];
      let newItem = await ItemService.addItem(user.userId, item);

      return newItem;
    } catch (err) {
      return null;
    }
  }
);
export const getUserPromotionItems = createAsyncThunk(
  "itemCreate/getUserPromotionItems",
  async (source, {}) => {
    try {
      const user = TokenService.getUser();
      return await ItemService.getUserPromotions(user.userId);
    } catch (err) {
      return null;
    }
  }
);

export const addSourceToItem = createAsyncThunk(
  "items/addApiSource",
  async (source, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState().item;
      const item = await ItemService.addSourceToItem(state.item.id, source);
      dispatch(updateItemInItems(item));
      return item;
    } catch (err) {
      return rejectWithValue(null, err);
    }
  }
);

export const updateItemSourcesForItem = createAsyncThunk(
  "items/updatesources",
  async (itemId, { rejectWithValue }) => {
    try {
      await ItemService.updateItemSourcesOfItem(itemId);
      toast.success("Update was requested");
    } catch (err) {
      return rejectWithValue(null, err);
    }
  }
);

export const getItemSuggestions = createAsyncThunk(
  "items/getItemSuggestions",
  async (itemId, { rejectWithValue }) => {
    try {
      return await ItemService.getItemSuggestions(itemId);
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const getUserTags = createAsyncThunk(
  "items/getUserTags",
  async (_, { rejectWithValue, getState }) => {
    try {
      const user = TokenService.getUser();
      let tags = await ItemService.getTags(user.userId);
      return tags;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const removeTag = createAsyncThunk(
  "items/removeTag",
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const item = getState().item.item;
      await ItemService.removeTag(id, item.id);
      dispatch(removeTagFromItem(id));
      dispatch(getUserTags());
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const addTag = createAsyncThunk(
  "items/addTag",
  async (tag, { rejectWithValue, getState, dispatch }) => {
    try {
      const user = TokenService.getUser();
      const state = getState().item;
      const newTag = await ItemService.addTag({
        userId: user.userId,
        itemId: state.item.id,
        value: tag,
      });
      dispatch(addTagToItem(newTag));
      dispatch(getUserTags());
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const { actions, reducer } = createSlice({
  name: "item",
  initialState,
  reducers: {
    addTagToItem: (state, action) => {
      let tags = state.item.tags.slice();
      tags = [...tags, action.payload];
      let userTags = state.userTags.slice();
      userTags = [...userTags, action.payload];
      state.userTags = userTags;
      state.item.tags = tags;

      let items = [...state.items];
      const index1 = items.map((e) => e.id).indexOf(state.item.id);
      if (index1 >= 0) {
        items[index1] = state.item;
      }
      state.items = items;
    },
    removeTagFromItem: (state, action) => {
      let tags = state.item.tags
        .slice()
        .filter((x) => x.value !== action.payload);
      let item = { ...state.item };
      item.tags = tags;
      state.item = item;

      let items = [...state.items];
      const index1 = items.map((e) => e.id).indexOf(item.id);
      if (index1 >= 0) {
        items[index1] = item;
      }
      state.items = items;
    },
    updateDescription: (state, action) => {
      state.item.description = action.payload;
    },

    clearItemGroup: (state, action) => {
      state.item.itemGroup = null;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    addSelectedItem: (state, action) => {
      state.selectedItems = [...state.selectedItems, action.payload];
    },
    removeSelectedItem: (state, action) => {
      const selectedItems = state.selectedItems
        .slice()
        .filter((x) => x.id !== action.payload);

      state.selectedItems = selectedItems;
    },
    clearSelectedItems: (state, action) => {
      state.selectedItems = [];
    },
    addSelectedItemSource: (state, action) => {
      let items = state.item.itemSources.slice();
      let itemIndex = items.map((i) => i.id).indexOf(action.payload);

      if (itemIndex >= 0) {
        state.selectedItemSources = [
          ...state.selectedItemSources,
          items[itemIndex],
        ];
      }
    },
    removeSelectedItemSource: (state, action) => {
      const selectedItemSources = state.selectedItemSources
        .slice()
        .filter((x) => x.id !== action.payload);

      state.selectedItemSources = selectedItemSources;
    },
    clearSelectedItemSources: (state, action) => {
      state.selectedItemSources = [];
    },

    switchTweedehands: (state, action) => {
      state.itemTweedehands = !state.itemTweedehands;
      if (state.itemTweedehands) {
        state.itemTweedehandsQuery = state.item.description;
      } else {
        state.itemTweedehandsQuery = "";
      }
    },
    updateTweedehandsQuery: (state, action) => {
      state.itemTweedehandsQuery = action.payload;
    },

    newItem: (state, action) => {
      state.itemCreate = true;
      state.item = { image: "", itemSources: [], description: "", userId: "" };
      state.itemTweedehands = false;
      state.itemTweedehandsQuery = "";
    },
    itemDetail: (state, action) => {
      state.item = { image: "", itemSources: [], description: "", userId: "" };
      state.itemCreate = false;
      state.itemTweedehands = false;
      state.itemTweedehandsQuery = "";
    },
    updateItemInItems: (state, action) => {
      let items = [...state.items];
      let filteredItems = [...state.filteredItems];
      const index1 = items.map((e) => e.id).indexOf(action.payload.id);
      const index2 = filteredItems.map((e) => e.id).indexOf(action.payload.id);
      if (index1 >= 0) {
        items[index1] = action.payload;
      }
      if (index2 >= 0) {
        filteredItems[index2] = action.payload;
      }
      state.filteredItems = filteredItems;
      state.items = items;
    },
    updateNewItemName: (state, action) => {
      state.newItemName = action.payload;
    },
    removeTempSource: (state, action) => {
      const itemToRemove = action.payload;
      let itemSources = [...state.item.itemSources].filter((i) => {
        if (i.amount !== itemToRemove.amount) return true;
        if (i.store !== itemToRemove.store) return true;
        if (i.itemId !== itemToRemove.itemId) return true;
        return false;
      });
      if (itemSources.length === 0) {
        state.item.description = "";
        state.itemImageUrl = "";
      }
      state.item.itemSources = itemSources;
    },
    updateImage: (state, action) => {
      state.item.image = action.payload;
    },
    removeItem: (state, action) => {
      const items = state.items.filter((e) => e.id !== action.payload);
      const filteredItems = state.filteredItems.filter(
        (e) => e.id !== action.payload
      );
      state.items = items;
      state.filteredItems = filteredItems;
    },
    updateCategory: (state, action) => {
      state.category = action.payload;
      if (action.payload) {
        state.selectedTags = [action.payload];
      } else {
        state.selectedTags = [];
      }
    },
    setItemLoading: (state, action) => {
      state.loading = action.payload;
    },
    setShowItemOverviewGroupCreate: (state, action) => {
      state.showItemOverviewGroupCreate = action.payload;
    },
    setShowItemOverviewAddToGroup: (state, action) => {
      state.showItemOverviewAddToGroup = action.payload;
    },

    switchModal: (state, action) => {
      state.itemGroupSelectOpen = !state.itemGroupSelectOpen;
    },
    setItemId: (state, action) => {
      state.itemId = action.payload;
      state.itemGroupSelectOpen = !state.itemGroupSelectOpen;
    },
    updateFilterLocal: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [getItem.fulfilled]: (state, { meta, payload }) => {
      state.item = payload.item;
      state.itemSourcePrizes = state.item.itemSources.filter(
        (e) => !e.multiprice
      );
      state.itemSourceMultiPrizes = state.item.itemSources.filter(
        (e) => e.multiprice === true
      );
      state.loading = false;
      state.itemTweedehands = payload.item.searchTweedehands;
      state.itemTweedehandsQuery = state.item.tweedehandsQueryString;
    },
    [addItemGroupToParentItemGroup.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.itemGroup = payload;
      }
    },
    [getUserTags.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.userTags = payload;
      }
    },
    [getUserPromotionItems.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.itemsWithPromotions = payload;
      }
    },
    [getItemSuggestions.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.suggestions = payload;
      }
    },
    [moveItemGroupToParentItemGroup.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.itemGroup = payload;
      }
    },
    [moveItemGroupItemToParent.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.itemGroup = payload;
      }
    },
    [addExistingItemToItemGroup.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.itemGroup = payload;
      }
    },
    [getItemHistory.fulfilled]: (state, { meta, payload }) => {
      state.itemHistory = payload;
    },
    // [updateFilter.fulfilled]: (state, { meta, payload }) => {
    //   state.items = payload.items;
    //   state.filteredItems = payload.items;
    //   state.pages = payload.pages;
    //   // updateFilter: (state, action) => {
    //   //   state.filter = action.payload;
    //   //   var keywords = state.filter.trim().split(/\s+/);
    //   //   if (keywords.length > 0 && keywords[0]) {
    //   //     const items = state.itemsInCategory.filter((i) => {
    //   //       return filterItem(keywords, i.description);
    //   //     });
    //   //     state.filteredItems = items;
    //   //   } else {
    //   //     state.filteredItems = state.itemsInCategory;
    //   //   }
    //   // },
    // },

    [addItem.fulfilled]: (state, { meta, payload }) => {
      const items = [...state.items, payload];
      const filteredItems = [...state.filteredItems, payload];
      state.item = payload;
      state.newItemName = "";
      state.items = items;
      state.filteredItems = filteredItems;
      state.itemCreate = false;
      toast.success("Item was created");
      history.push(`/item/${payload.id}`);
    },
    [addItemWithSource.fulfilled]: (state, { meta, payload }) => {
      const items = [...state.items, payload];
      const filteredItems = [...state.filteredItems, payload];
      state.item = payload;
      state.newItemName = "";
      state.items = items;
      state.filteredItems = filteredItems;
      state.itemCreate = false;
      toast.success("Item was created");
      history.push(`/item/${payload.id}`);
    },
    [addSourceToItem.fulfilled]: (state, { meta, payload }) => {
      state.item = payload;
      toast.success("Itemsource added");
    },
    [removeSource.fulfilled]: (state, { meta, payload }) => {
      state.item = payload;
      toast.success("Itemsource deleted");
    },
    [removeSources.fulfilled]: (state, { meta, payload }) => {
      state.item = payload;
      toast.success("Itemsources deleted");
    },
    [getCategoriesForUser.fulfilled]: (state, { meta, payload }) => {
      state.categories = payload;
      state.loading = false;
    },
    [saveItem.fulfilled]: (state, { meta, payload }) => {
      state.item = payload.item;
      state.loading = false;
      toast.success("Item was saved");
    },
    [getItems.fulfilled]: (state, { meta, payload }) => {
      let viewMode = 0;
      let defaultView = localStorage.getItem("item-view");
      if (defaultView) {
        viewMode = parseInt(defaultView, 10);
      }
      state.viewMode = viewMode;
      if (payload) {
        state.filteredItems = payload;
        state.itemsInCategory = payload;
        state.items = payload;
      }
      state.loading = false;
    },
    [getItemsPaged.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.filteredItems = payload.items;
        state.itemsInCategory = payload.items;
        state.items = payload.items;
        state.pages = payload.pages;
        state.userTags = payload.tags;
        state.hits = payload.hits;
      }
      state.loading = false;
    },
    [getItemsPagedEternal.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        if (state.currentPage === 1) {
          state.itemsEternal = [...payload.items];
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
          });
        } else {
          state.itemsEternal = [...state.itemsEternal, ...payload.items];
        }

        // state.itemsInCategory = payload.items;
        //state.items = payload.items;
        state.pages = payload.pages;
        state.userTags = payload.tags;
        state.hits = payload.hits;
      }
      state.loading = false;
    },
    [mergeSelectedItems.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.filteredItems = payload;
        state.itemsInCategory = payload;
        state.items = payload;
      }
    },
    [getItemGroups.fulfilled]: (state, { meta, payload }) => {
      state.itemGroups = payload;
    },
    [getItemGroup.fulfilled]: (state, { meta, payload }) => {
      state.itemGroup = null;
      state.itemGroup = payload;
    },
    [getUnavailableSources.fulfilled]: (state, { meta, payload }) => {
      state.unavailable = payload;
    },
    [addItemTotemGroup.fulfilled]: (state, { meta, payload }) => {
      state.itemGroup = payload.itemGroup;
      state.itemGroupSelectOpen = false;
      toast.success("item added to group");
      history.push(`/itemgroups/${payload.id}`);
    },
    [updateItemGroupItem.fulfilled]: (state, { meta, payload }) => {
      state.itemGroup = payload;
      let itemGroups = [...state.itemGroups];
      let item = itemGroups.find((o) => o.id === payload.id);
      item = payload;
    },
    [deleteItemGroup.fulfilled]: (state, { meta, payload }) => {
      console.log("", payload);
      state.itemGroups = state.itemGroups.filter((g) => g.id !== payload);

      if (state.itemGroup.groups) {
        state.itemGroup.groups = state.itemGroup.groups.filter(
          (g) => g.id !== payload
        );
      }
    },
    [deleteItemGroupItem.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.itemGroup.items = state.itemGroup.items.filter(
          (g) => g.id !== payload.id
        );
        state.itemGroup = payload;
      }
    },
    [createItemGroup.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.itemGroups = [...state.itemGroups, payload];
        //history.push(`/itemgroups/${payload.id}`);
      }
    },
    [addSubGoupToItemGroup.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.itemGroup = payload;
      }
    },
    [duplicateItemGroup.fulfilled]: (state, { meta, payload }) => {
      if (payload) {
        state.itemGroup = payload;
      }
    },
    [getItemsForCategory.fulfilled]: (state, { meta, payload }) => {},
    [refreshItem.fulfilled]: (state, { meta, payload }) => {
      let items = [...state.items];
      let filteredItems = [...state.filteredItems];
      const index1 = items.map((e) => e.id).indexOf(payload.id);
      const index2 = filteredItems.map((e) => e.id).indexOf(payload.id);
      if (index1 >= 0) {
        items[index1] = payload;
      }
      if (index2 >= 0) {
        filteredItems[index2] = payload;
      }
      state.filteredItems = filteredItems;
      state.items = items;
      if (state.item.id === payload.id) {
        state.item = payload;
      }
      toast.success(`item ${payload.description} is bijgewerkt`);

      state.loading = false;
    },
  },
});

export const {
  updateDescription,
  updateImage,
  updateCategory,
  setItemLoading,
  removeItem,
  updateItemInItems,
  updateFilterLocal,
  newItem,
  itemDetail,
  removeTempSource,
  switchModal,
  setItemId,
  switchTweedehands,
  updateTweedehandsQuery,
  clearItemGroup,
  addSelectedItem,
  removeSelectedItem,
  clearSelectedItems,
  addSelectedItemSource,
  removeSelectedItemSource,
  clearSelectedItemSources,
  addTagToItem,
  removeTagFromItem,
  setCurrentPage,
  setShowItemOverviewGroupCreate,
  setShowItemOverviewAddToGroup,
} = actions;
export default reducer;
