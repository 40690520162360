import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Close, Search, QrCodeScanner } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import {
  search,
  setSelectedStore,
  updateQueryInternal,
  initializeScanner,
  initializeScannerPro,
  scanCodeMulti,
} from "../../../redux/reducers/searchSlice";
import { CenteredImage } from "../../Shared/CenteredImage";
import { useTranslation } from "react-i18next";
export default function ItemSearchControl() {
  const query = useSelector((state) => state.search.query);
  const { t } = useTranslation();
  const groupedStores = useSelector(
    (state) => state.search.selectedGroupedStores
  );

  let flattened = [];

  groupedStores.forEach((category) => {
    flattened.push({ id: category.category, name: category.category });
    category.stores.forEach((store) => flattened.push(store));
  });

  const store = useSelector((state) => state.search.selectedStore);
  const searching = useSelector((state) => state.search.searching);
  const dispatch = useDispatch();
  const handleSearch = () => {
    dispatch(search());
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      dispatch(search());
    }
  };

  return (
    <div className="sixty">
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Select
          value={`${store}`}
          sx={{ display: { xs: "none", md: "flex" } }}
          variant="standard"
          onChange={(e) => {
            dispatch(setSelectedStore(e.target.value));
            dispatch(search());
          }}
        >
          {flattened.map((store) => (
            <MenuItem value={store.id} key={store.id}>
              <div style={{ display: "flex" }}>
                {store.image && (
                  <div>
                    <CenteredImage
                      className={`storeImage`}
                      src={store.image}
                      alt={store.name}
                      size="35px"
                    ></CenteredImage>
                  </div>
                )}

                {store.image ? (
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      fontSize: "0.75em",
                    }}
                  >
                    {store.name}
                  </div>
                ) : (
                  <b>{store.name}</b>
                )}
              </div>
            </MenuItem>
          ))}
        </Select>{" "}
        <FormControl
          sx={{ display: { xs: "none", md: "flex" } }}
          style={{ width: "100%", marginTop: "16px", marginLeft: "5px" }}
        >
          <Input
            id="outlined-adornment-password"
            type={"text"}
            placeholder={t("query")}
            value={query || ""}
            onChange={(e) => {
              dispatch(updateQueryInternal(e.target.value));
            }}
            onKeyUp={handleKeypress}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    handleSearch();
                  }}
                >
                  <Search />
                </IconButton>
                <IconButton
                  onClick={() => {
                    !searching && dispatch(initializeScanner());
                  }}
                >
                  <QrCodeScanner />
                </IconButton>

                <IconButton
                  onClick={() => {
                    dispatch(updateQueryInternal(""));
                  }}
                  style={{ marginLeft: "15px" }}
                >
                  <Close />
                </IconButton>
              </InputAdornment>
            }
            label="Search item"
          />
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {" "}
          <Select
            sx={{ display: { xs: "flex", md: "none" } }}
            value={`${store}`}
            style={{ flexGrow: "1" }}
            variant="standard"
            onChange={(e) => {
              dispatch(setSelectedStore(e.target.value));
              dispatch(search());
            }}
          >
            {flattened.map((store) => (
              <MenuItem value={store.id} key={store.id}>
                <div style={{ display: "flex" }}>
                  {store.image && (
                    <div>
                      <CenteredImage
                        className={`storeImage`}
                        src={store.image}
                        alt={store.name}
                        size="35px"
                      ></CenteredImage>
                    </div>
                  )}

                  {store.image ? (
                    <div
                      style={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        fontSize: "0.75em",
                      }}
                    >
                      {store.name}
                    </div>
                  ) : (
                    <b>{store.name}</b>
                  )}
                </div>
              </MenuItem>
            ))}
          </Select>{" "}
          <div style={{ marginLeft: "15px", marginTop: "5px" }}>
            <IconButton
              onClick={() => {
                !searching && dispatch(initializeScanner());
              }}
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <QrCodeScanner />
            </IconButton>
          </div>
        </div>

        <FormControl
          sx={{ display: { xs: "flex", md: "none" } }}
          style={{ width: "100%", marginTop: "16px", marginLeft: "5px" }}
        >
          <Input
            id="outlined-adornment-password"
            type={"text"}
            placeholder={t("query")}
            value={query || ""}
            onChange={(e) => {
              dispatch(updateQueryInternal(e.target.value));
            }}
            onKeyUp={handleKeypress}
            endAdornment={
              <InputAdornment position="end">
                <IconButton>
                  <Search
                    onClick={() => {
                      handleSearch();
                    }}
                  />
                </IconButton>

                <IconButton style={{ marginLeft: "15px" }}>
                  <Close
                    onClick={() => {
                      dispatch(updateQueryInternal(""));
                    }}
                  />
                </IconButton>
              </InputAdornment>
            }
            label={t("item_search")}
          />
        </FormControl>
      </div>
      {/* <div id="eanscan" style={{ width: "380px" }}></div> */}
      {/* <div
        style={{
          width: "370px",
          heigth: "200px",
          overFlowX: "hidden",
          overFlowX: "hidden",
        }}
      > */}
      <div id="eanscan" style={{ width: "450px" }}></div>
    </div>
    // </div>
  );
}
