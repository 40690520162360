import React from "react";
import {
  login,
  setName,
  setPassword,
  setRedirect,
} from "../../redux/reducers/authenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { Button, Container, Input, Row, Spacer } from "@nextui-org/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Login(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const windowUrl = window.location.href;

  let redirect = "";
  if (windowUrl.indexOf("redirect") > 0) {
    redirect = windowUrl.substring(windowUrl.indexOf("=") + 1);
  }
  dispatch(setRedirect(redirect));

  const username = useSelector((state) => state.authentication.username);
  const password = useSelector((state) => state.authentication.password);

  return (
    <Container md style={{ marginTop: "25px" }}>
      <Row justify="center">
        <Input
          style={{ minWidth: "300px" }}
          label={t("username")}
          value={username}
          onChange={(e) => dispatch(setName(e.target.value))}
        ></Input>
      </Row>
      <Spacer y={1} />
      <Row justify="center">
        <Input
          style={{ minWidth: "300px" }}
          label={t("password")}
          value={password}
          type="password"
          onChange={(e) => dispatch(setPassword(e.target.value))}
        ></Input>
      </Row>
      <Spacer y={1} />
      <Row justify="center">
        <Button
          style={{ minWidth: "250px" }}
          size="sm"
          onClick={() => dispatch(login())}
        >
          <p style={{ textTransform: "uppercase" }}> {t("login")}</p>
        </Button>
      </Row>
    </Container>
  );
}

export default Login;
