import { Button, Input, Radio } from "@nextui-org/react";
import React, { useState } from "react";
import { ItemSearchResultCard } from "./ItemSearchResultCard";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemWithSource,
  addSourceToItem,
} from "../../../redux/reducers/itemSlice";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function ItemSearchResultCardSourceWrapper(props) {
  const { t } = useTranslation();
  const [factor, setFactor] = useState(1);
  const [modifier, setModifier] = useState("X");
  const itemCreate = useSelector((state) => state.item.itemCreate);
  const dispatch = useDispatch();
  const handleAddItem = () => {
    let item = { ...props.item };
    if (modifier === "X") {
      item.amount = 0 + parseFloat(factor, 10);
    } else {
      item.amount = 0 - parseFloat(factor, 10);
    }
    if (itemCreate) {
      dispatch(addItemWithSource(item));
    } else {
      dispatch(addSourceToItem(item));
    }
  };
  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          margin: "15px",
          borderStyle: "solid",
          borderColor: "#f6f6f4",
          borderWidth: "1px",
          borderRadius: "10px",
        }}
        className="shadow"
      >
        <ItemSearchResultCard {...props} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <div style={{ marginRight: "25px", marginTop: "5px" }}>
              <Radio.Group
                orientation="horizontal"
                aria-label="factor"
                value={modifier}
                onChange={(e) => setModifier(e)}
              >
                <Radio size="sm" value="X">
                  X
                </Radio>
                <Radio size="sm" value="/">
                  /
                </Radio>
              </Radio.Group>
            </div>

            <Input
              type="number"
              step={0.5}
              min={1}
              value={factor}
              style={{ maxWidth: "55px" }}
              onChange={(e) => setFactor(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => handleAddItem()} size="auto" light>
              <p style={{ textTransform: "uppercase" }}>{t("add")}</p>
            </Button>
          </div>
        </div>
      </div>
    </Col>
  );
}
