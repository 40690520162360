import React from "react";
import { Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ItemSourceCard from "../ItemDetail/ItemSourceCard";
import { Link } from "react-router-dom";
import { RefreshRounded } from "@mui/icons-material";
import { updateItemSourcesForItem } from "../../../redux/reducers/itemSlice";

export default function ItemSourceUnavailableList() {
  const unavailableSources = useSelector(
    (state) => state.item.unavailableSources
  );
  const dispatch = useDispatch();
  return (
    unavailableSources && (
      <React.Fragment>
        <Row>
          {unavailableSources.map((source, i) => (
            <React.Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Link to={`/item/${source.itemId}`}>{source.description}</Link>
                <RefreshRounded
                  style={{ cursor: "pointer", color: "black", fontSize: "2em" }}
                  onClick={() =>
                    dispatch(updateItemSourcesForItem(source.itemId))
                  }
                />
              </div>

              <ItemSourceCard
                key={source.id}
                source={source.source}
                itemId={source.itemId}
              />
            </React.Fragment>
          ))}
        </Row>
      </React.Fragment>
    )
  );
}
