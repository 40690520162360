import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addSubGoupToItemGroup,
  duplicateItemGroup,
  getItemGroup,
  getItemGroups,
  getItems,
  getItemsForCategory,
  moveItemGroupItemToParent,
  moveItemGroupToParentItemGroup,
  updateFilter,
} from "../../../redux/reducers/itemSlice";
import {
  getShoppingList,
  getShoppingListsForGroup,
} from "../../../redux/reducers/shoppingListSlice";
import { createShoppingListForItemGroup } from "../../../redux/reducers/shoppingListSlice";
import ItemGroupCard from "../../../Components/ItemGroups/ItemGroupCard";
import {
  Add,
  AddBox,
  AddCircle,
  ArrowBack,
  Close,
  ContentCopy,
  LibraryAdd,
  ReceiptLong,
  ShoppingCartCheckout,
} from "@mui/icons-material";
import { history } from "../../../App";
import ItemGroupItemPicker from "../../../Components/ItemGroups/ItemGroupItemPicker";
import ItemGroupItemCard from "../../../Components/ItemGroups/ItemGroupItemCard";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
} from "@mui/material";
import { updateQueryInternal } from "../../../redux/reducers/searchSlice";
import ItemStoreImage from "../../../Components/Shared/ItemStoreImage";
import { setPageActions } from "../../../redux/reducers/authenticationSlice";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function ItemGroupDetail() {
  const { t } = useTranslation();
  const group = useSelector((state) => state.item.itemGroup);
  const category = useSelector((state) => state.item.category);
  const categories = useSelector((state) => state.search.categories);
  const filter = useSelector((state) => state.item.filter);
  const params = useParams();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [modalGroup, setModalGroup] = useState(false);
  const toggleGroup = () => setModalGroup(!modalGroup);
  const [modalItems, setModalItems] = useState(false);
  const toggleItems = () => setModalItems(!modalItems);
  const [modalDuplicate, setModalDuplicate] = useState(false);
  const toggleDuplicate = () => {
    setModalDuplicate(!modalDuplicate);
  };
  const [duplicateName, setDuplicateName] = useState(
    `${group ? group.description + "_copy" : ""}`
  );

  const handleDuplicateGroup = () => {
    dispatch(
      duplicateItemGroup({ groupId: group.id, description: duplicateName })
    );
    toggleDuplicate();
  };

  const handleCreateShoppingList = () => {
    dispatch(
      createShoppingListForItemGroup({
        groupId: params.id,
        storeId: 0,
      })
    );
  };

  const pageActions = [
    {
      title: t("group_duplicate"),
      action: toggleDuplicate,
      icon: <ContentCopy />,
    },
    { title: t("add_item"), action: toggleItems, icon: <Add /> },
    { title: t("add_group"), action: toggleGroup, icon: <AddBox /> },
    {
      title: t("shopping_list_update"),
      action: handleCreateShoppingList,
      icon: <ShoppingCartCheckout />,
    },
  ];

  // const actions = [
  //   { icon: <Save />, name: "Save", action: saveConfig },
  //   { icon: <Delete />, name: "Delete", action: handleDeleteConfig },
  //   { icon: <Visibility />, name: "Test config", action: handleTestConfig },
  // ];
  const storenames = useSelector((state) => state.search.searchStores);
  const shoppingLists = useSelector(
    (state) => state.shoppingList.shoppingListsForGroup
  );
  useEffect(() => {
    dispatch(getItemGroup(params.id));
    dispatch(getItems());
    dispatch(updateQueryInternal(""));
    dispatch(getShoppingListsForGroup(params.id));
    dispatch(setPageActions(pageActions));
  }, [params.id, modalGroup, modalItems, dispatch]);

  const handleBack = () => {
    if (!group.parentGroupId) {
      dispatch(getItemGroups());
    } else {
      dispatch(getItemGroup(group.parentGroupId));
    }
    history.push(`/itemgroups/${group.parentGroupId || ""}`);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleGroupCreate = (e) => {
    setName("");
    toggleGroup();
    dispatch(
      addSubGoupToItemGroup({
        description: name,
        groupId: params.id,
      })
    );
  };
  const handleOnDrop = (e) => {
    const dataObject = e.dataTransfer.getData("data");
    const data = JSON.parse(dataObject);

    //return;
    if (data) {
      switch (data.type) {
        case "group":
          const groupData = {
            parentId: group.id,
            childId: data.childId,
          };

          dispatch(moveItemGroupToParentItemGroup(groupData));
          break;
        case "item":
          const itemData = {
            groupId: group.id,
            itemId: data.childId,
          };

          dispatch(moveItemGroupItemToParent(itemData));
          break;
        default:
          break;
      }
    }
  };
  const pathString = () => {
    let retVal = "";
    if (group && group.path) {
      let pathParts = group.path.split("&");

      if (pathParts.length <= 0) return "";
      for (let index = 1; index < pathParts.length; index++) {
        const splitparts = pathParts[index].split(";");
        retVal = `${retVal}/${splitparts[1]}`;
      }
    }

    return retVal;
  };

  const [pathParts, setPathParts] = useState([]);

  const getPathParts = () => {
    let partsArray = [];
    if (group && group.path) {
      const parts = group.path.split("&");
      partsArray.push({ name: "root", link: `/itemgroups` });
      for (let index = 0; index < parts.length; index++) {
        const element = parts[index];
        const elementParts = element.split(";");
        if (elementParts.length > 1 && elementParts[1] !== "root") {
          partsArray.push({
            name: elementParts[1],
            link: `/itemgroups/${elementParts[0]}`,
          });
        }
      }
    }
    return partsArray;
  };

  const handleDuplicate = () => {
    dispatch(
      duplicateItemGroup({ groupId: group.id, description: duplicateName })
    );
    toggleDuplicate();
  };

  return (
    group && (
      <div>
        <div
          style={{
            top: "65px",
            position: "sticky",
            backgroundColor: "white",
            zIndex: 999,
            marginTop: "25px",
            marginLeft: "0px",
            color: "black",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Button onClick={() => handleBack()}>
              <ArrowBack />
            </Button>
            <Breadcrumbs style={{ marginTop: "10px" }} aria-label="breadcrumb">
              {getPathParts().length > 0 &&
                getPathParts().map((part) => (
                  <div
                    key={part.link}
                    underline="hover"
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(part.link)}
                  >
                    {part.name}
                  </div>
                ))}

              <Typography color="text.primary">
                <b>{group.description}</b>
              </Typography>
            </Breadcrumbs>
          </div>

          <h5 style={{ marginTop: "15px" }}>Totaal: {group.priceString}</h5>
        </div>

        {/* <Text h3>{`${path}/${group.description}`}</Text> */}

        <div>
          <Row>
            {group.itemsSorted && group.itemsSorted.length > 0 && (
              <h4 style={{ textTransform: "uppercase" }}>{t("items")}</h4>
            )}
          </Row>
          <Row>
            {group.itemsSorted &&
              group.itemsSorted.map((itemStore) => {
                const store = storenames.find((x) => x.id === itemStore.store);

                const storeName = store && store.name;
                return (
                  <React.Fragment key={itemStore.id}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "25px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div style={{ marginRight: "15px" }}>
                          <ItemStoreImage
                            storeId={itemStore.store}
                            size="95px"
                          />
                        </div>

                        <h4 style={{ marginTop: "5px" }}>{storeName}</h4>
                        <h5 style={{ marginTop: "8px", marginLeft: "10px" }}>
                          ( {itemStore.priceString} )
                        </h5>
                      </div>
                    </div>
                    <Row>
                      {itemStore.items.map((item) => (
                        <ItemGroupItemCard
                          key={item.id}
                          item={item}
                          groupId={params.id}
                        />
                      ))}
                    </Row>
                  </React.Fragment>
                );
              })}
          </Row>

          <Row>
            {group.groups.length > 0 && (
              <h4 style={{ textTransform: "uppercase" }}>{t("groups")}</h4>
            )}
          </Row>
          <Row>
            {group.groups
              .filter((g) => g)
              .map((sub) => (
                <ItemGroupCard key={sub.id} itemGroup={sub} />
              ))}
          </Row>
        </div>

        <Dialog
          open={modalGroup}
          onClose={toggleGroup}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>{t("group_create")}</DialogTitle>
          <DialogContent dividers={true}>
            <Input
              aria-label="group"
              color="primary"
              size="lg"
              placeholder={t("description")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              aria-label="addgroup"
              auto
              light
              onClick={() => handleGroupCreate()}
            >
              {t("create")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={modalDuplicate}
          onClose={toggleDuplicate}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>{t("group_duplicate")}</DialogTitle>
          <DialogContent dividers={true}>
            <Input
              aria-label="group"
              color="primary"
              size="lg"
              placeholder={t("description")}
              value={duplicateName}
              onChange={(e) => setDuplicateName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            {" "}
            <Button
              aria-label="addgroup"
              auto
              light
              onClick={() => handleDuplicate()}
            >
              {t("copy")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={modalItems}
          onClose={toggleItems}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>
            <p>{t("item_add")}</p>
            <Select
              value={category}
              variant="standard"
              onChange={(e) => {
                dispatch(getItemsForCategory(e.target.value));
              }}
            >
              <MenuItem key={"Toon alles"} value={"Toon alles"}>
                {t("show_all")}
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
            <FormControl style={{ width: "100%", marginBottom: "15px" }}>
              <Input
                id="outlined-adornment-password"
                type={"text"}
                placeholder={t("query")}
                value={filter || ""}
                onChange={(e) => {
                  dispatch(updateFilter(e.target.value));
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      style={{ marginLeft: "15px" }}
                      onClick={() => {
                        dispatch(updateFilter(""));
                      }}
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search item"
              />
            </FormControl>
          </DialogTitle>
          <DialogContent dividers={true}>
            {/* {(filter || category !== "Toon alles") && ( */}
            <ItemGroupItemPicker groupId={params.id} />
            {/* )} */}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
        {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {" "}
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "fixed", bottom: 16, left: 16 }}
            icon={<SpeedDialIcon />}
          >
            {" "}
            {pageActions.map((action) => (
              <SpeedDialAction
                key={action.title}
                icon={action.icon}
                tooltipTitle={action.title}
                onClick={() => action.action()}
              />
            ))}
          </SpeedDial>
        </Box> */}
      </div>
    )
  );
}
