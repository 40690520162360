import axios from "axios";
import TokenService from "./TokenService";
import { history } from "../App";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/Authenticate/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        TokenService.removeUser();

        if (
          window.location.href === "https://www.webkom.be/" ||
          window.location.href === "http://localhost:3000/" ||
          window.location.href.indexOf("/login") >= 0 ||
          window.location.href.endsWith("/register") ||
          window.location.href.indexOf("/activate/") > 0 ||
          window.location.href.indexOf("redirect=") > 0
        ) {
        } else {
          history.push(`/login?redirect=${window.location.href}`);
        }
      }
    }
    return Promise.reject(err);
  }
);
export default instance;
