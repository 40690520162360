import instance from "./Api";

class ItemService {
  addItem(userId, data) {
    let payload = { ...data };
    payload.userId = userId;
    return instance.post(`/Item`, payload);
  }
  addSourceToItem(itemId, data) {
    return instance.post(`/Item/${itemId}/source`, data);
  }

  removeSourceFromItem(itemId, sourceId) {
    return instance.delete(`/Item/${itemId}/source/${sourceId}`);
  }

  removeSourcesFromItem(itemId, sourceIds) {
    return instance.post(`/Item/${itemId}/source/removeSources`, sourceIds);
  }
  updateItem(data) {
    return instance.put(`/Item/${data.id}`, data);
  }

  updateItemSource(data) {
    return instance.put(`/Item/source`, data);
  }
  removeItem(itemId) {
    return instance.delete(`/Item/${itemId}`);
  }

  removeItems(itemIds) {
    return instance.post(`/Item/removeItems`, itemIds);
  }

  getItem(id) {
    return instance.get(`/Item/${id}`);
  }
  getItemSuggestions(id) {
    return instance.get(`/Item/${id}/suggestions`);
  }

  getUserItems(id) {
    return instance.get(`/item/user/${id}`);
  }

  getUserItemsPaged(id, data) {
    return instance.post(`/item/user/${id}`, data);
  }

  getUserPromotions(id) {
    return instance.get(`/item/user/${id}/promotions`);
  }

  updateItemSourcesOfItem(id) {
    return instance.get(`/item/update/${id}`);
  }

  getItemHistory(id) {
    return instance.get(`/History/${id}`);
  }
  mergeSelectedItems(data) {
    return instance.post(`/Item/merge`, data);
  }

  addTag(data) {
    return instance.post(`/Tag`, data);
  }

  removeTag(id, itemId) {
    return instance.delete(`/Tag/${id}/${itemId}`);
  }
  getTags(userId) {
    return instance.get(`/Tag/${userId}`);
  }
}
export default new ItemService();
