import React, { useState } from "react";
import { Row } from "reactstrap";
import { useSelector } from "react-redux";
import ItemOverviewCard from "./ItemOverviewCard";

function ItemsList() {
  const items = useSelector((state) => state.item.filteredItems);

  return (
    <>
      <Row style={{ width: "-webkit-fill-available" }}>
        {items.map((item, i) => (
          <ItemOverviewCard key={item.id} item={item} />
        ))}{" "}
      </Row>
    </>
  );
}

export default ItemsList;
