import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getItemsPagedEternal,
  setCurrentPage,
} from "../../../redux/reducers/itemSlice.js";
import ItemOverviewFilter from "../../../Components/Item/ItemOverview/ItemOverviewFilter.tsx";
import "./ItemOverview.css";
import { setPageActions } from "../../../redux/reducers/authenticationSlice.js";
import ItemOverviewSelectActions from "./ItemOverviewSelectActions.tsx";
import ItemsListMobile from "../../../Components/Item/ItemOverview/ItemsListMobile.js";
import ItemOverviewDialogs from "./ItemOverviewDialogs.tsx";
import ItemOverviewFilterMobile from "../../../Components/Item/ItemOverview/ItemOverviewFilterMobile.tsx";

function ItemOverviewMobile() {
  const dispatch = useDispatch();
  const selectedItems = useSelector((state) => state.item.selectedItems);
  const filter = useSelector((state) => state.item.filter);

  useEffect(() => {
    dispatch(setCurrentPage(1));
    dispatch(
      getItemsPagedEternal({ query: filter, page: 1, size: 12, tags: [] })
    );
    dispatch(setPageActions([]));
  }, [dispatch]);

  const height = selectedItems.length > 0 ? "175px" : "105px";

  return (
    <div>
      <div
        style={{
          top: "55px",
          position: "sticky",
          backgroundColor: "white",
          zIndex: 999,
          margin: "0px",
          height: height,
          width: "100%",
        }}
      >
        <ItemOverviewFilterMobile />
        <ItemOverviewSelectActions />
      </div>
      <ItemsListMobile />
      <ItemOverviewDialogs />
    </div>
  );
}

export default ItemOverviewMobile;
