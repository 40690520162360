import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  removeSource,
  removeSourceFromItem,
  removeTempSource,
  updateSource,
} from "../../../redux/reducers/itemSlice";
import { CenteredImage } from "../../Shared/CenteredImage";
import ItemStoreImage from "../../Shared/ItemStoreImage";
import "./ItemSourceCard.css";
import { Button, Radio, Input } from "@nextui-org/react";
import { history } from "../../../App";
import { Delete, History, Home, Edit, Save } from "@mui/icons-material";
import { Col } from "reactstrap";
import { useTranslation } from "react-i18next";

function ItemSourceCard(props) {
  const { t } = useTranslation();
  const itemCreate = useSelector((state) => state.item.itemCreate);

  const [edit, setEdit] = useState(false);
  const [factor, setFactor] = useState(1);
  const [modifier, setModifier] = useState("X");

  const source = props.source;
  const dispatch = useDispatch();

  const handleSourceSave = () => {
    let amount = 1;
    if (modifier === "X") {
      amount = 0 + parseFloat(factor, 10);
    } else {
      amount = 0 - parseFloat(factor, 10);
    }
    dispatch(updateSource({ id: source.id, amount: amount }));
    setEdit(false);
  };

  useEffect(() => {
    if (props.source.amount < 0) setModifier("/");
    setFactor(Math.abs(props.source.amount));
    setEdit(false);
  }, [props.source.amount]);

  const shortDescription =
    source.description.length > 40
      ? source.description.slice(0, 39)
      : source.description;
  const handleDelete = (id) => {
    if (window.confirm(t("itemsource_confirm_delete")))
      if (itemCreate) {
        dispatch(removeTempSource(source));
      } else {
        if (props.itemId) {
          dispatch(
            removeSourceFromItem({ sourceId: source.id, itemId: props.itemId })
          );
        } else {
          dispatch(removeSource(source.id));
        }
      }
  };

  const handleNavigateHistory = (id) => {
    history.push(`/item/pricehistory/${id}`);
  };

  const handleNavigateStore = () => {
    window.open(source.url, "_blank");
  };

  return (
    <Col xs={12} s={6} md={6} lg={6} xl={4}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          margin: "15px",
          borderStyle: "solid",
          borderColor: "#f6f6f4",
          borderWidth: "1px",
          borderRadius: "10px",
        }}
        className="shadow"
      >
        <div style={{ margin: "5px" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                minHeight: "55px",
                padding: "5px",
              }}
            >
              <h6 style={{ textTransform: "uppercase" }}>{shortDescription}</h6>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              <p style={{ fontSize: "13px", padding: "2px" }}>
                {source.factorString}
              </p>
              <p>
                <b>{source.priceString}</b>
              </p>
            </div>
            {source.promotion && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <p>
                  <b
                    style={{ fontSize: "15px" }}
                  >{`${source.promotion.promoUnitPriceString} bij ${source.promotion.promoMinimumAmount} stuks`}</b>
                </p>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              <p style={{ fontSize: "13px", padding: "2px" }}>
                {source.lastPriceDate}
              </p>
            </div>
            <hr
              style={{
                borderStyle: "solid",
                marginTop: "5px",
                marginBottom: "10px",
              }}
            ></hr>
            {edit && (
              <>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <div style={{ marginRight: "25px", marginTop: "5px" }}>
                    <Radio.Group
                      orientation="horizontal"
                      aria-label="factor"
                      value={modifier}
                      onChange={(e) => setModifier(e)}
                    >
                      <Radio size="sm" value="X">
                        X
                      </Radio>
                      <Radio size="sm" value="/">
                        /
                      </Radio>
                    </Radio.Group>
                  </div>

                  <Input
                    type="number"
                    step={0.5}
                    min={1}
                    value={factor}
                    style={{ maxWidth: "55px" }}
                    onChange={(e) => setFactor(e.target.value)}
                  />
                  <Button
                    onClick={() => {
                      handleSourceSave();
                      setEdit(false);
                    }}
                    light
                    size="auto"
                    style={{ marginLeft: "25px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Save />
                      <p
                        style={{
                          fontSize: "0.5rem",
                          padding: "2px",
                          textTransform: "uppercase",
                        }}
                      >
                        {t("save")}
                      </p>
                    </div>
                  </Button>
                </div>
              </>
            )}

            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ display: "flex" }}>
                <div
                  className="itemoverviewcard-image-container"
                  style={{
                    maxWidth: "125px",
                    minWidth: "115px",
                    // marginRight: "25px",
                    marginLeft: "15px",
                  }}
                >
                  <CenteredImage
                    size="95px"
                    src={source.image}
                    alt={source.store}
                  />
                  <div className="itemoverviewcard-image-itemstoreimage-container">
                    <ItemStoreImage storeId={source.store} />
                  </div>
                </div>
                <div className=""></div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: "15px",
                  // alignItems: "center",
                }}
              >
                {" "}
                {source.promotion && (
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "18px" }}>
                        {" "}
                        {source.promotion.promotionText}
                      </p>

                      {source.promotion.promotionStart &&
                        source.promotion.promotionEnd && (
                          <p style={{ fontSize: "12px" }}>
                            {`van ${source.promotion.promotionStart} tot ${source.promotion.promotionEnd}`}
                          </p>
                        )}

                      {!source.promotion.promotionStart &&
                        source.promotion.promotionEnd && (
                          <p style={{ fontSize: "12px" }}>
                            {`tot ${source.promotion.promotionEnd}`}
                          </p>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "5px",
              right: "5px",
              zIndex: "0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => handleNavigateStore(source.id)}
                light
                size="auto"
                style={{ marginLeft: "15px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Home />
                  <p
                    style={{
                      fontSize: "0.5rem",
                      padding: "2px",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("visit")}
                  </p>
                </div>
              </Button>
              <Button
                onClick={() => handleNavigateHistory(source.id)}
                light
                size="auto"
                style={{ marginLeft: "15px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <History />
                  <p
                    style={{
                      fontSize: "0.5rem",
                      padding: "2px",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("history")}
                  </p>
                </div>
              </Button>
              <Button
                onClick={() => setEdit(!edit)}
                light
                size="auto"
                style={{ marginLeft: "15px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Edit />
                  <p
                    style={{
                      fontSize: "0.5rem",
                      padding: "2px",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("edit")}
                  </p>
                </div>
              </Button>
              <Button
                onClick={() => handleDelete(source.id)}
                light
                size="auto"
                color="error"
                style={{ marginLeft: "15px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Delete />
                  <p
                    style={{
                      fontSize: "0.5rem",
                      padding: "2px",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("delete")}
                  </p>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
export default ItemSourceCard;
