import React from "react";

export function CenteredImage(props) {
  const size = props.size || "50px";

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  containerStyle.height = size;
  containerStyle.minWidth = size;

  const imageStyle = {};
  imageStyle.maxHeight = size;
  imageStyle.maxWidth = size;
  return (
    <div style={containerStyle} className={props.className}>
      <div>
        <img
          style={imageStyle}
          src={props.src}
          alt={props.src}
          loading="lazy"
        ></img>
      </div>
    </div>
  );
}
