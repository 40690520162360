import React from "react";
import "./ItemOverviewCard.css";
import { CenteredImage } from "../../Shared/CenteredImage";
import ItemStoreImage from "../../Shared/ItemStoreImage";
import { history } from "../../../App";
import {
  deleteItemInOverview,
  addSelectedItem,
  removeSelectedItem,
} from "../../../redux/reducers/itemSlice";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { Col, Row } from "reactstrap";
import { Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
export default function ItemOverviewCard(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedItems = useSelector((state) => state.item.selectedItems);
  const selectedIds = selectedItems.map((e) => e.id);
  const selected = selectedIds.indexOf(props.item.id) >= 0;
  const currentPage = useSelector((state) => state.item.currentPage);

  const shortDescription =
    props.item.description.length > 40
      ? props.item.description.slice(0, 39)
      : props.item.description;

  const borderColor = selected ? "rgb(25, 118, 210)" : "#f6f6f4";
  const borderWidth = selected ? "2px" : "1px";

  const handleItemCardClick = () => {
    if (selected) {
      dispatch(removeSelectedItem(props.item.id));
    } else {
      dispatch(addSelectedItem(props.item));
    }
  };

  let cardStyle = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    margin: "15px",
    borderStyle: "solid",
    borderColor: borderColor,
    borderWidth: borderWidth,
    borderRadius: "10px",
  };

  return (
    <Col xs={12} s={12} md={12} lg={6} xl={4}>
      <div style={cardStyle} className="shadow">
        <div style={{ margin: "5px" }} onClick={() => handleItemCardClick()}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                minHeight: "60px",
              }}
            >
              <p style={{ textTransform: "uppercase" }}>
                <b>{shortDescription}</b>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
              }}
            >
              <p>
                <b>{props.item.priceString}</b>
              </p>
            </div>
            <hr
              style={{
                borderStyle: "solid",
                marginTop: "5px",
                marginBottom: "10px",
              }}
            ></hr>
            <div>
              <Row wrap="no-wrap" justify="space-between">
                <div style={{ display: "flex" }}>
                  <div
                    className="itemoverviewcard-image-container"
                    style={{
                      maxWidth: "125px",
                      minWidth: "115px",
                      // marginRight: "25px",
                      marginLeft: "15px",
                    }}
                  >
                    <CenteredImage
                      size="95px"
                      src={props.item.image}
                      alt={props.item.store}
                    />
                    <div className="itemoverviewcard-image-itemstoreimage-container">
                      <ItemStoreImage storeId={props.item.store} />
                    </div>
                    <div className="itemoverviewcard-sourcecount">
                      {props.item.itemSourceCount}
                    </div>
                    {props.item.tweedehandsItemSourceCount > 0 && (
                      <div className="itemoverviewcard-tweedehandssourcecount">
                        {props.item.tweedehandsItemSourceCount}
                      </div>
                    )}
                  </div>
                  <div className="">
                    {props.item.storesWithPromotions &&
                      props.item.storesWithPromotions.length > 0 && (
                        <div style={{ display: "flex" }}>
                          <div className="itemoverviewcard-promotion-text">
                            {t("promo")}
                          </div>
                          {props.item.storesWithPromotions.map((store) => {
                            return (
                              <div
                                key={`key_store_${store}`}
                                className="itemoverviewcard-content-itemstoreimage-container"
                              >
                                <ItemStoreImage size={"25px"} storeId={store} />
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                </div>
              </Row>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "5px",
              right: "5px",
              zIndex: "0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                size="auto"
                style={{ marginRight: "15px" }}
                light
                onClick={() => history.push(`/item/${props.item.id}`)}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Edit />
                  <p
                    style={{
                      fontSize: "0.5rem",
                      padding: "2px",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("edit")}
                  </p>
                </div>
              </Button>
              <Button
                onClick={() => {
                  if (
                    window.confirm(
                      `${props.item.description} : ${t("confirm_delete_item")}`
                    )
                  )
                    dispatch(deleteItemInOverview(props.item.id));
                }}
                light
                size="auto"
                color="error"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Delete />
                  <p
                    style={{
                      fontSize: "0.5rem",
                      padding: "2px",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("delete")}
                  </p>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
