import React from "react";
import { useSelector } from "react-redux";
import "./ItemStoreImage.css";

function ItemStoreImage(props) {
  const storenames = useSelector((state) => state.search.searchStores);
  const size = props.size || "35px";
  const imageStyle = {};
  imageStyle.maxHeight = size;
  imageStyle.maxWidth = size;
  var store = storenames.find((x) => x.id === props.storeId);
  return (
    <img
      className={`storeImage ${props.className}`}
      src={store ? store.image : "0"}
      alt={props.name}
      style={imageStyle}
    ></img>
  );
}

export default ItemStoreImage;
