import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  ButtonBase,
  FormControl,
  Input,
  MenuItem,
  Select,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import StoreConfigTextInput from "./StoreConfigTextInput";
import StoreScrapeTask from "./StoreScrapeTask.tsx";
import StoreScrapeHit from "./StoreScrapeHit";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoreConfig,
  setGetItemConfigTasks,
  setSearchConfigTasks,
  updateConfiguration,
  updateSearchConfiguration,
  updateGetItemConfiguration,
  addConfigTask,
  saveStoreConfig,
  deleteStoreConfig,
  testStoreConfig,
} from "../../redux/reducers/searchSlice";

import "./styles.css";
import { useParams } from "react-router";
import { Col, Container, Row } from "reactstrap";
import { CenteredImage } from "../../Components/Shared/CenteredImage";
import {
  Delete,
  Save,
  SavedSearchSharp,
  Visibility,
} from "@mui/icons-material";
import { setPageActions } from "../../redux/reducers/authenticationSlice";
import { useTranslation } from "react-i18next";

export default function StoreScraperConfiguration() {
  const { t } = useTranslation();
  const storeConfig = useSelector((state) => state.search.storeConfig);
  const callType = useSelector((state) => state.search.callType);
  const categories = useSelector((state) => state.search.categoryList);
  const hit = useSelector((state) => state.search.testHit);
  const hits = useSelector((state) => state.search.testHits);

  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    if (params.id) dispatch(getStoreConfig(params.id));
    dispatch(setPageActions(actions));
  }, [params.id, dispatch]);

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = storeConfig.searchConfiguration.tasks.slice();
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    let newList = [];
    for (let index = 0; index < updatedList.length; index++) {
      let item = { ...updatedList[index] };

      item.order = index + 1;
      newList.push(item);
    }

    dispatch(setSearchConfigTasks(newList));
    // Update State
    // setItemList(updatedList);
  };

  const handleDrop1 = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = storeConfig.getItemConfiguration.tasks.slice();
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    let newList = [];
    for (let index = 0; index < updatedList.length; index++) {
      let item = { ...updatedList[index] };

      item.order = index + 1;
      newList.push(item);
    }

    dispatch(setGetItemConfigTasks(newList));
    // Update State
    // setItemList(updatedList);
  };

  const saveConfig = async (_) => {
    await dispatch(saveStoreConfig());
  };

  const handleTestConfig = () => {
    dispatch(testStoreConfig());
  };
  const handleDeleteConfig = () => {
    dispatch(deleteStoreConfig());
  };

  const actions = [
    { icon: <Save />, title: t("save"), action: saveConfig },
    { icon: <Delete />, title: t("delete"), action: handleDeleteConfig },
    { icon: <Visibility />, title: t("config_test"), action: handleTestConfig },
  ];
  return (
    <Container>
      <div>
        <p>CONFIGURATION</p>
        <Row>
          <StoreConfigTextInput
            storeConfig={storeConfig}
            title={"Storename"}
            objectKey={"storeName"}
            type={0}
          />
        </Row>
        <div style={{ display: "flex" }}>
          <StoreConfigTextInput
            storeConfig={storeConfig}
            title={"Image"}
            objectKey={"image"}
            type={0}
          />

          {storeConfig.image && (
            <div style={{ marginLeft: "25px" }}>
              {" "}
              <CenteredImage
                size="75px"
                src={storeConfig.image}
                alt={storeConfig.storeName}
              />
            </div>
          )}
        </div>

        <Row>
          <StoreConfigTextInput
            storeConfig={storeConfig}
            title={"Testquery"}
            objectKey={"testQuery"}
            type={0}
          />
        </Row>
        <Row>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              width: "95%",
            }}
          >
            <p style={{ minWidth: "150px" }}>{"Category"}:</p>
            <Select
              variant="standard"
              value={storeConfig.category || ""}
              onChange={(e) =>
                dispatch(
                  updateConfiguration({
                    key: "category",
                    value: e.target.value,
                  })
                )
              }
            >
              {categories.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Row>
      </div>
      <div>
        <Row>
          <Col xs={12} lg={6}>
            <p>SEARCH CONFIGURATION</p>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  width: "95%",
                }}
              >
                <p style={{ minWidth: "150px" }}>{"Call Type"}:</p>
                <Select
                  variant="standard"
                  value={storeConfig.searchConfiguration.scraperCallType || ""}
                  onChange={(e) =>
                    dispatch(
                      updateSearchConfiguration({
                        key: "scraperCallType",
                        value: e.target.value,
                      })
                    )
                  }
                >
                  {callType.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Row>

            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"Url"}
                objectKey={"callUrl"}
                type={1}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"Splitstring"}
                objectKey={"splitString"}
                type={1}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"ApplicationId"}
                objectKey={"applicationId"}
                type={1}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"Token"}
                objectKey={"token"}
                type={1}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"Index"}
                objectKey={"index"}
                type={1}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"Body"}
                objectKey={"body"}
                type={1}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"Headers"}
                objectKey={"headers"}
                type={1}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"ItemMustContain"}
                objectKey={"itemMustContain"}
                type={1}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"itemCannotContain"}
                objectKey={"itemCannotContain"}
                type={1}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"Referer"}
                objectKey={"referer"}
                type={1}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"ArrayStart"}
                objectKey={"arrayStart"}
                type={1}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"itemsIsJson"}
                objectKey={"itemsIsJson"}
                type={1}
              />
            </Row>
            <Row>
              <p>TASKS</p>
              {storeConfig &&
                storeConfig.searchConfiguration &&
                storeConfig.searchConfiguration.tasks &&
                storeConfig.searchConfiguration.tasks.length < 1 && (
                  <Row>
                    {" "}
                    <Button
                      onClick={() => dispatch(addConfigTask({ type: 0 }))}
                    >
                      Add Task
                    </Button>
                  </Row>
                )}
              <DragDropContext onDragEnd={handleDrop} style={{}}>
                <div style={{ display: "flex", marginTop: "15px" }}>
                  <Droppable droppableId="list-container">
                    {(provided) => (
                      <div
                        className="list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {storeConfig &&
                          storeConfig.searchConfiguration &&
                          storeConfig.searchConfiguration.tasks &&
                          storeConfig.searchConfiguration.tasks.map(
                            (item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="item-container"
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    <StoreScrapeTask task={item} type={0} />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </Row>
          </Col>

          <Col xs={12} lg={6}>
            <p>GET ITEM CONFIGURATION</p>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"Headers"}
                objectKey={"headers"}
                type={2}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"Url"}
                objectKey={"getItemUrl"}
                type={2}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"UnavailableKeyword"}
                objectKey={"unavailableKeyword"}
                type={2}
              />
            </Row>
            <Row>
              <StoreConfigTextInput
                storeConfig={storeConfig}
                title={"Token"}
                objectKey={"token"}
                type={2}
              />
            </Row>
            <Row>
              <p>TASKS</p>
              {storeConfig &&
                storeConfig.getItemConfiguration &&
                storeConfig.getItemConfiguration.tasks &&
                storeConfig.getItemConfiguration.tasks.length < 1 && (
                  <Row>
                    {" "}
                    <Button
                      onClick={() => dispatch(addConfigTask({ type: 1 }))}
                    >
                      Add Task
                    </Button>
                  </Row>
                )}

              <DragDropContext onDragEnd={handleDrop1}>
                <div
                  style={{
                    display: "flex",
                    marginTop: "15px",
                    marginLeft: "10px",
                  }}
                >
                  <Droppable droppableId="list-container2">
                    {(provided) => (
                      <div
                        className="list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {storeConfig &&
                          storeConfig.getItemConfiguration &&
                          storeConfig.getItemConfiguration.tasks &&
                          storeConfig.getItemConfiguration.tasks.map(
                            (item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="item-container"
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    <StoreScrapeTask task={item} type={1} />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </Row>
          </Col>
        </Row>
      </div>
      {hits && (
        <div>
          {hits.map((hit) => (
            <div key={hit.itemId}>
              <StoreScrapeHit item={hit} />
              {hit.price}
            </div>
          ))}
        </div>
      )}
      {hit && (
        <div>
          <p>HIT</p>
          <StoreScrapeHit item={hit} />
          {hit.price}
        </div>
      )}
      {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "fixed", bottom: 16, left: 16 }}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.title}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => action.action()}
            />
          ))}
        </SpeedDial>
      </Box> */}
    </Container>
  );
}
