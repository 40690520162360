import "./App.css";

import React from "react";
import { Route, Routes } from "react-router-dom";
import ItemDetail from "./Routes/Item/ItemDetail/ItemDetail";
import Logout from "./Components/Authentication/Logout.tsx";

// import Register from "./Routes/Account/Register";
import ActivateAccount from "./Routes/Account/ActivateAccount";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SocketHandler from "./Components/Shared/SocketHandler";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import UserProfile from "./Routes/Account/UserProfile";
import ItemPriceHistory from "./Routes/Item/ItemHistory/ItemPriceHistory";
import ItemGroupDetail from "./Routes/Item/ItemGroup/ItemGroupDetail";
import StoreFilter from "./Routes/Stores/StoreFilter";
import ItemGroups from "./Routes/Item/ItemGroup/ItemGroups";
import Login from "./Routes/Account/Login.tsx";
import TokenService from "./Services/TokenService";
import ItemPromotions from "./Routes/Item/ItemPromotion/ItemPromotions";
import ItemSourceUnavailable from "./Routes/Item/ItemSourceUnavailable/ItemSourceUnavailable";
import { NextUIProvider } from "@nextui-org/react";
import ItemNew from "./Routes/Item/ItemNew/ItemNew";
import SharedList from "./Routes/List/SharedList";
import StoreScraperConfiguration from "./Routes/Configuration/StoreScraperConfiguration";
import ShoppingList from "./Routes/shoppingList/ShoppingList";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ItemOverviewLanding from "./Routes/Item/ItemOverview/ItemOverviewLanding.tsx";
import Solar from "./Routes/Extras/Solar.js";

export let history = createBrowserHistory({ window });

export default function App() {
  const localUser = TokenService.getUser();

  const loggedIn = localUser;

  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  if (
    !loggedIn &&
    !window.location.href.endsWith("/login") &&
    !window.location.href.endsWith("/register") &&
    !window.location.href.indexOf("shared") < 0 &&
    window.location.href.indexOf("/activate/") < 0
  ) {
    return <Login />;
  }

  return (
    <NextUIProvider>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />{" "}
        <Logout>
          <SocketHandler />

          <HistoryRouter history={history}>
            <div id="App">
              <Routes>
                <Route exact path="/solar" element={<Solar />} />
                <Route exact path="/admin"></Route>

                <Route exact path="/profile" element={<UserProfile />} />
                <Route exact path="/promotions" element={<ItemPromotions />} />
                <Route
                  exact
                  path="/storeconfig"
                  element={<StoreScraperConfiguration />}
                />
                <Route
                  exact
                  path="/storeconfig/:id"
                  element={<StoreScraperConfiguration />}
                />

                <Route
                  exact
                  path="/shoppinglist/:id"
                  element={<ShoppingList />}
                />
                <Route
                  exact
                  path="/unavailable"
                  element={<ItemSourceUnavailable />}
                />
                <Route exact path="/itemgroups" element={<ItemGroups />} />
                <Route
                  exact
                  path="/itemgroups/:id"
                  element={<ItemGroupDetail />}
                />

                <Route
                  exact
                  path="/activate/:token"
                  element={<ActivateAccount />}
                />
                <Route path="/item/create" element={<ItemNew />} />
                <Route
                  path="/item/pricehistory/:id"
                  element={<ItemPriceHistory />}
                />
                <Route path="/item/:id" element={<ItemDetail />} />
                <Route path="/shared/:id" element={<SharedList />} />
                <Route path="/items" element={<ItemOverviewLanding />} />
                <Route path="/stores" element={<StoreFilter />} />
                <Route path="*" element={<Login />} />
              </Routes>
            </div>
          </HistoryRouter>
        </Logout>
      </ThemeProvider>

      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </NextUIProvider>
  );
}
