import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ReactTags } from "react-tag-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import {
  getItem,
  deleteItem,
  saveItem,
  itemDetail,
  addItem,
  updateItemSourcesForItem,
  switchTweedehands,
  getUserTags,
  addTag,
  removeTag,
} from "../../../redux/reducers/itemSlice";
import {
  initializeScanner,
  stopScanner,
} from "../../../redux/reducers/searchSlice";

import { setPageActions } from "../../../redux/reducers/authenticationSlice";
import ItemDetailImage from "../../../Components/Item/ItemDetail/ItemDetailImage";
import ItemSources from "../../../Components/Item/ItemDetail/ItemSources";
import { updateQueryInternal } from "../../../redux/reducers/searchSlice";
import ItemSearchControl from "../../../Components/Item/ItemSearch/ItemSearchControl";
import ItemSearchResults from "../../../Components/Item/ItemSearch/ItemSearchResults";
import ItemSuggestions from "../../../Components/Item/ItemSearch/ItemSuggestions";
import "./ItemDetail.css";
import ItemDetailDescription from "../../../Components/Item/ItemDetail/ItemDetailDescription";
import ItemDetailTweedehands from "../../../Components/Item/ItemDetail/ItemDetailTweedehands";
import ItemDetailTweedehandsItems from "../../../Components/Item/ItemDetail/ItemDetailTweedehandsItems";
import {
  Add,
  AddBox,
  ArrowBack,
  Delete,
  Edit,
  QrCode,
  Recycling,
  Refresh,
  Save,
  Scanner,
  Sync,
} from "@mui/icons-material";
import { Button, Checkbox } from "@nextui-org/react";
import { history } from "../../../App";
import { Row } from "reactstrap";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ItemDetail(props) {
  const { t } = useTranslation();
  const state = useSelector((state) => state.item);
  const userTags = useSelector((state) => state.item.userTags);
  const barcode = useSelector((state) => state.search).barcode;
  const item = state.item;
  const isTweedehands = state.itemTweedehands;
  const create = props.create;
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalScan, setModalScan] = useState(false);

  const onAdd = useCallback(
    (newTag) => {
      dispatch(addTag(newTag.label));
    },
    [item.tags]
  );

  const tags = item.tags;

  const onDelete = useCallback(
    (tagIndex) => {
      const tag = item.tags[tagIndex].value;

      dispatch(removeTag(tag));
    },
    [item.tags]
  );

  const toggle = () => {
    dispatch(updateQueryInternal(item.description));
    setModal(!modal);
  };

  const toggleEdit = () => setModalEdit(!modalEdit);

  const params = useParams();

  const handleSave = () => {
    if (create) {
      dispatch(addItem());
    } else {
      dispatch(saveItem());
    }
  };

  const handleUpdateSources = () => {
    dispatch(updateItemSourcesForItem(params.id));
  };
  const handleDeleteItem = () => {
    dispatch(deleteItem());
  };

  const pageActions = [
    { title: t("item_save"), action: handleSave, icon: <Save /> },
    { title: t("itemsource_add"), action: toggle, icon: <Add /> },
    // { title: "Scan Source", action: toggleScan, icon: <QrCode /> },
    { title: t("item_edit"), action: toggleEdit, icon: <Edit /> },
    {
      title: t("itemsources_refresh"),
      action: handleUpdateSources,
      icon: <Sync />,
    },
    {
      title: t("item_delete"),
      action: handleDeleteItem,
      icon: <Delete />,
    },
  ];

  useEffect(() => {
    dispatch(itemDetail());
    dispatch(getItem(params.id));
    dispatch(setPageActions(pageActions));
    dispatch(getUserTags());
    //dispatch(updateQueryInternal(""));
  }, [params.id, dispatch, create]);

  return (
    item && (
      <div>
        <div
          style={{
            top: "75px",
            position: "sticky",
            backgroundColor: "white",
            zIndex: 999,
            marginTop: "25px",
            marginLeft: "0px",
            height: "50px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                light
                size="auto"
                onClick={() => {
                  history.push("/items");
                }}
              >
                {" "}
                <ArrowBack />
              </Button>
            </div>{" "}
          </div>

          <Row wrap="no-wrap" justify="space-between"></Row>
        </div>
        <h3>{item.description}</h3>

        <Row>
          <Checkbox
            isSelected={isTweedehands}
            onChange={() => dispatch(switchTweedehands())}
            color="primary"
          >
            {t("secondhand_search")}
          </Checkbox>
        </Row>
        {isTweedehands && <ItemDetailTweedehands />}
        {item && item.tags && userTags && (
          <ReactTags
            labelText={t("tags")}
            placeholderText={t("tag_add")}
            allowNew
            selected={item.tags}
            suggestions={userTags}
            onAdd={onAdd}
            onDelete={onDelete}
            noOptionsText={t("tag_none")}
          />
        )}
        <ItemSources />

        <ItemSuggestions />
        <ItemDetailTweedehandsItems />
        <br />
        <br />
        <Dialog
          open={modal}
          onClose={toggle}
          maxWidth="xl"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>{t("itemsource_add")}</DialogTitle>
          <DialogContent dividers={true}>
            <ItemSearchControl />
            <ItemSearchResults />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        {/* <Dialog
          open={modalScan}
          onClose={toggleScan}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="xl"
        >
          <DialogTitle>
            <p>Scan source</p> <button onClick={toggleScan}>X</button>
          </DialogTitle>
          <DialogContent dividers={true}>
            <p>{barcode}</p>

            <div id="eanscan"></div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={() => dispatch(initializeScanner())}>
                Scan
              </Button>
            </div>

            <ItemSearchResults />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog> */}

        <Dialog
          open={modalEdit}
          onClose={toggleEdit}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>{t("item_edit")}</DialogTitle>
          <DialogContent dividers={true}>
            <ItemDetailDescription />
            <ItemDetailImage />
          </DialogContent>
          <DialogActions>
            {" "}
            <Button
              size="sm"
              light
              onClick={() => {
                handleSave();
                setModalEdit(false);
              }}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {" "}
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "fixed", bottom: 16, left: 16 }}
            icon={<SpeedDialIcon />}
          >
            {" "}
            {pageActions.map((action) => (
              <SpeedDialAction
                key={action.title}
                icon={action.icon}
                tooltipTitle={action.title}
                onClick={() => action.action()}
              />
            ))}
          </SpeedDial>
        </Box> */}
      </div>
    )
  );
}
