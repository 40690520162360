import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthService from "../../Services/AuthService";

function ActivateAccount() {
  const params = useParams();
  useEffect(() => {
    async function activate() {
      return await AuthService.activate(params.token);
    }
    activate().then(() => (window.location.href = "/login"));
  }, [params.token]);
  return <div>VerifyAccount</div>;
}

export default ActivateAccount;
