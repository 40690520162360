import React from "react";
import "./ItemOverviewCard.css";
import { CenteredImage } from "../../Shared/CenteredImage";

import { removeSelectedItem } from "../../../redux/reducers/itemSlice";
import { useDispatch, useSelector } from "react-redux";
import { HighlightOff } from "@mui/icons-material";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
export default function ItemOverviewCardMini(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const shortDescription =
    props.item.description.length > 40
      ? props.item.description.slice(0, 39)
      : props.item.description;

  let cardStyle = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    margin: "15px",
    borderRadius: "10px",
    maxWidth: "150px",
  };

  const handleDeselect = () => {
    dispatch(removeSelectedItem(props.item.id));
  };

  return (
    <Col xs={12}>
      <div style={cardStyle} className="shadow">
        <div style={{ margin: "5px" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                minHeight: "40px",
              }}
            >
              <p style={{ textTransform: "uppercase", fontSize: "10px" }}>
                <b>{shortDescription}</b>
              </p>
            </div>

            <div>
              <Row wrap="no-wrap" justify="space-between">
                <div style={{ display: "flex" }}>
                  <div
                    className="itemoverviewcard-image-container"
                    style={{
                      maxWidth: "125px",
                      minWidth: "115px",
                      // marginRight: "25px",
                      // marginLeft: "15px",
                    }}
                  >
                    <CenteredImage
                      size="55px"
                      src={props.item.image}
                      alt={props.item.store}
                    />
                  </div>
                </div>
              </Row>
            </div>
          </div>
          <div style={{ position: "absolute", right: "0px", bottom: "0px" }}>
            <IconButton
              onClick={() => handleDeselect()}
              size="small"
              color="inherit"
            >
              <HighlightOff></HighlightOff>
            </IconButton>
          </div>
        </div>
      </div>
    </Col>
  );
}
