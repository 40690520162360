import React from "react";
import { Row } from "reactstrap";
import ItemTweedehandsSourceCard from "./ItemTweedehandsSourceCard";
import { useSelector } from "react-redux";

export default function ItemDetailTweedehandsItems() {
  const itemSources = useSelector(
    (state) => state.item.item.tweedehandsItemSources
  );
  return (
    <React.Fragment>
      <div className="responsiveSize">
        <Row>
          {itemSources && itemSources.length > 0 && (
            <React.Fragment>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>TWEEDEHANDS</p>
              </div>
              {itemSources.map((source, i) => (
                <ItemTweedehandsSourceCard key={source.id} source={source} />
              ))}
            </React.Fragment>
          )}
        </Row>
      </div>
    </React.Fragment>
  );
}
