import React from "react";
import { CenteredImage } from "../../Shared/CenteredImage";
import ItemPromotionCardLine from "./ItemPromotionCardLine";
import { Col } from "reactstrap";

export default function ItemPromotionCard(props) {
  const item = props.item;
  const shortDescription =
    item.description.length > 40
      ? item.description.slice(0, 39)
      : item.description;
  return (
    <Col xs={12} s={12} md={12} lg={6} xl={6}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          margin: "15px",
          borderStyle: "solid",
          borderColor: "#f6f6f4",
          borderWidth: "1px",
          borderRadius: "10px",
        }}
        className="shadow"
      >
        <div style={{ margin: "5px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <p style={{ textTransform: "uppercase" }}>
              <b>{shortDescription}</b>
            </p>
          </div>
        </div>
        <hr
          style={{
            borderStyle: "solid",
            marginTop: "5px",
            marginBottom: "10px",
          }}
        ></hr>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              className="itemoverviewcard-image-container"
              style={{
                maxWidth: "125px",
                minWidth: "115px",

                marginLeft: "15px",
              }}
            >
              <a href={`item/${item.id}`} rel="noreferrer">
                <CenteredImage size="95px" src={item.image} alt={item.store} />
              </a>
            </div>
            <div className=""></div>
          </div>
          <hr
            style={{
              borderStyle: "solid",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          ></hr>
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              flexGrow: "1",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {item.itemSources.map((source) => (
                <ItemPromotionCardLine key={source.id} source={source} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
