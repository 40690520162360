import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteItems,
  mergeSelectedItems,
  setShowItemOverviewAddToGroup,
  setShowItemOverviewGroupCreate,
} from "../../../redux/reducers/itemSlice";

import "./ItemOverview.css";

import { clearSelectedItems } from "../../../redux/reducers/itemSlice";
import {
  AddShoppingCart,
  Clear,
  Delete,
  Merge,
  ShoppingCartCheckoutOutlined,
} from "@mui/icons-material";

import { Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export default function ItemOverviewSelectActions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedItems = useSelector((state) => state.item.selectedItems);
  const numberOfSelectedItems = selectedItems.length;

  return (
    <>
      {numberOfSelectedItems > 0 && (
        <Box>
          <b style={{ marginLeft: "15px" }}>{`${numberOfSelectedItems} ${
            numberOfSelectedItems !== 1 ? t("items") : t("item")
          } ${t("selected")} `}</b>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="auto"
              style={{ marginRight: "15px", zIndex: "10000" }}
              light
              onClick={() => dispatch(clearSelectedItems())}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Clear />
                <p
                  style={{
                    fontSize: "0.5rem",
                    padding: "2px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("clear_selection")}
                </p>
              </div>
            </Button>
            <Button
              onClick={() => {
                if (window.confirm(t("confirm_merge_selected")))
                  dispatch(mergeSelectedItems());
              }}
              light
              size="auto"
              // color="error"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                <Merge />
                <p
                  style={{
                    fontSize: "0.5rem",
                    padding: "2px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("merge")}
                </p>
              </div>
            </Button>
            <Button
              onClick={() => {
                dispatch(setShowItemOverviewGroupCreate(true));
              }}
              light
              size="auto"
              // color="error"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                <ShoppingCartCheckoutOutlined />
                <p
                  style={{
                    fontSize: "0.5rem",
                    padding: "2px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("creategroup")}
                </p>
              </div>
            </Button>
            <Button
              onClick={() => {
                dispatch(setShowItemOverviewAddToGroup(true));
              }}
              light
              size="auto"
              // color="error"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                <AddShoppingCart />
                <p
                  style={{
                    fontSize: "0.5rem",
                    padding: "2px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("Toevoegen aan lijst")}
                </p>
              </div>
            </Button>
            <Button
              onClick={() => {
                if (window.confirm(t("confirm_delete_selected")))
                  dispatch(deleteItems());
              }}
              light
              size="auto"
              // color="error"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                <Delete />
                <p
                  style={{
                    fontSize: "0.5rem",
                    padding: "2px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("delete")}
                </p>
              </div>
            </Button>
          </div>
        </Box>
      )}
    </>
  );
}
