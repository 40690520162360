import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createItemGroup,
  getItemGroups,
} from "../../../redux/reducers/itemSlice";
import ItemGroupCard from "../../../Components/ItemGroups/ItemGroupCard";
import { Row, Button } from "reactstrap";
import { Add } from "@mui/icons-material";
import { setPageActions } from "../../../redux/reducers/authenticationSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ItemGroups() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itemGroups = useSelector((state) => state.item.itemGroups);
  const [name, setName] = useState("");
  const [modalGroup, setModalGroup] = useState(false);
  const toggleGroup = () => setModalGroup(!modalGroup);
  const handleCreateGroup = () => {
    toggleGroup();
    dispatch(createItemGroup(name));
  };

  const pageActions = [
    { title: t("creategroup"), action: toggleGroup, icon: <Add /> },
  ];

  useEffect(() => {
    dispatch(getItemGroups());
    dispatch(setPageActions(pageActions));
  }, [itemGroups.length, dispatch]);

  return (
    itemGroups && (
      <React.Fragment>
        <Row>
          {itemGroups.map((g) => (
            <ItemGroupCard key={g.id} itemGroup={g} />
          ))}
        </Row>
        <Dialog
          open={modalGroup}
          onClose={toggleGroup}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>{t("creategroup")}</DialogTitle>
          <DialogContent dividers={true}>
            <Input
              color="primary"
              size="lg"
              placeholder={t("description")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreateGroup}>{t("create")}</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  );
}
