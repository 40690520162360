import instance from "./Api";

class SearchService {
  async search(query, stores = [], location = "841") {
    var data = {
      searchItems: [
        {
          query: query,
          stores: stores,
          location: location,
          size: 25,
        },
      ],
    };
    return instance.post(`/Search/search`, data).then((response) => {
      return response;
    });
  }

  async getStores() {
    return instance.get(`/config/stores`).then((response) => {
      return response;
    });
  }

  async getSearchStatus(id) {
    return instance.get(`/Search/getstatus/${id}`).then((response) => {
      return response;
    });
  }

  async getSearchResult(id) {
    return instance.get(`/Search/getresult/${id}`).then((response) => {
      return response;
    });
  }

  async testConfig(config) {
    return instance.post(`/config/test`, config).then((response) => {
      return response;
    });
  }

  async getConfig(id) {
    return instance.get(`/config/${id}`).then((response) => {
      return response;
    });
  }

  async saveConfig(config) {
    return instance.post(`/config`, config).then((response) => {
      return response;
    });
  }

  async deleteConfig(id) {
    return instance.delete(`/config/${id}`).then((response) => {
      return response;
    });
  }

  async scanCode(code, location, store = 0) {
    return instance
      .get(`/Scan/${code}/${store}/${location}`)
      .then((response) => {
        return response;
      });
  }

  async scanCodeMulti(code, location, store = 0) {
    return instance
      .get(`/Scan/dev/${code}/${store}/${location}`)
      .then((response) => {
        return response;
      });
  }
}
export default new SearchService();
