import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { newItem, addItem } from "../../../redux/reducers/itemSlice";
import ItemDetailImage from "../../../Components/Item/ItemDetail/ItemDetailImage";
import ItemSources from "../../../Components/Item/ItemDetail/ItemSources";
import ItemSearchControl from "../../../Components/Item/ItemSearch/ItemSearchControl";
import ItemSearchResults from "../../../Components/Item/ItemSearch/ItemSearchResults";

import ItemDetailDescription from "../../../Components/Item/ItemDetail/ItemDetailDescription";
import { Add } from "@mui/icons-material";
import { Button, Row, Text } from "@nextui-org/react";
import { setPageActions } from "../../../redux/reducers/authenticationSlice";
import {
  initializeScanner,
  updateQueryInternal,
} from "../../../redux/reducers/searchSlice";
import { useTranslation } from "react-i18next";
const pageActions = [];

export default function ItemNew(props) {
  // const item = useSelector((state) => state.item.item);
  // const searching = useSelector((state) => state.search.searching);
  // const create = props.create;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const params = useParams();

  // const handleSave = () => {
  //   dispatch(addItem());
  // };

  useEffect(() => {
    dispatch(newItem());
    dispatch(setPageActions(pageActions));
    dispatch(updateQueryInternal(""));
  }, []);

  return (
    <div>
      <ItemSearchControl />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{}}>
          {" "}
          <ItemSearchResults />
        </div>
      </div>
      {/* <ItemDetailImage /> */}
    </div>
  );
}
