import { Close } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDescription } from "../../../redux/reducers/itemSlice";
import { Input } from "@nextui-org/react";

export default function ItemDetailDescription() {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.item.item);
  return (
    <Input
      onChange={(e) => {
        dispatch(updateDescription(e.target.value));
      }}
      value={item.description}
      label="Description"
      clearable
      placeholder="Decription"
      width="100%"
    />
  );
}
