import instance from "./Api";

class ItemGroupService {
  deleteItemGroup(groupId) {
    return instance.delete(`/ItemGroup/${groupId}`);
  }

  getItemGroup(groupId) {
    // if (test)
    //   return axios
    //     .get(`${localServer}/ItemGroup/${groupId}`)
    //     .then((res) => res.data);
    return instance.get(`/ItemGroup/${groupId}`);
  }

  getSharedItemGroup(groupId) {
    // if (test)
    //   return axios
    //     .get(`${localServer}/ItemGroup/${groupId}`)
    //     .then((res) => res.data);
    return instance.get(`/ItemGroup/shared/${groupId}`);
  }

  updateItemGroup(groupId, data) {
    // if (test)
    //   return axios
    //     .post(`${localServer}/ItemGroup/${groupId}`, data)
    //     .then((res) => res.data);
    return instance.put(`/ItemGroup/${groupId}`, data);
  }

  addItemGroup(data) {
    // if (test)
    //   return axios
    //     .post(`${localServer}/ItemGroup`, data)
    //     .then((res) => res.data);
    return instance.post(`/ItemGroup`, data);
  }

  updateItemGroupItem(groupId, data) {
    // if (test)
    //   return axios
    //     .put(`${localServer}/ItemGroup/${groupId}/item`, data)
    //     .then((res) => res.data);
    return instance.put(`/ItemGroup/${groupId}/item`, data);
  }

  getItemGroupsForUser(userId) {
    // if (test)
    //   return axios
    //     .get(`${localServer}/ItemGroup/user/${userId}`)
    //     .then((res) => res.data);
    return instance.get(`/ItemGroup/user/${userId}`);
  }

  addItemToItemGroup(groupId, itemId) {
    return instance.post(`/ItemGroup/${groupId}/item/${itemId}`);
  }

  addExistingItemToItemGroup(groupId, itemdId) {
    return instance.post(`/ItemGroup/${groupId}/groupitem/${itemdId}`);
  }

  moveItemGroupItemToParent(groupId, itemdId) {
    return instance.post(`/ItemGroup/${groupId}/groupitemup/${itemdId}`);
  }

  deleteItemFromItemGroup(groupId, itemId) {
    return instance.delete(`/ItemGroup/${groupId}/item/${itemId}`);
  }

  addItemGroupToItemGroup(parentGroupId, childGroupId) {
    return instance.post(`/ItemGroup/${parentGroupId}/group/${childGroupId}`);
  }

  addItemGroupToParentItemGroup(parentGroupId, childGroupId) {
    return instance.post(`/ItemGroup/${parentGroupId}/child/${childGroupId}`);
  }

  moveItemGroupToParentItemGroup(parentGroupId, childGroupId) {
    return instance.post(`/ItemGroup/${parentGroupId}/childup/${childGroupId}`);
  }

  deleteItemGroupFromItemGroup(parentGroupId, childGroupId) {
    return instance.delete(`/ItemGroup/${parentGroupId}/group/${childGroupId}`);
  }
  duplicateItemGroup(data) {
    return instance.post(`/ItemGroup/${data.groupId}/clone`, data);
  }
}
export default new ItemGroupService();
