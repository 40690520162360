import React, { useEffect, useState } from "react";
import "./ItemGroups.css";
import { CenteredImage } from "../Shared/CenteredImage";
import ItemStoreImage from "../Shared/ItemStoreImage";
import { history } from "../../App";
import { useDispatch } from "react-redux";
import {
  deleteItemGroupItem,
  updateItemGroupItem,
} from "../../redux/reducers/itemSlice";
import { Col, Row } from "reactstrap";
import { Button } from "@mui/material";
import { Add, PlusOne, Remove } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function ItemGroupItemCard(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDelete = () => {
    if (window.confirm(t("confirm_delete_item"))) {
      dispatch(
        deleteItemGroupItem({ id: props.item.id, groupId: props.groupId })
      );
    }
  };

  const handleUpdate = (amount) => {
    dispatch(updateItemGroupItem({ itemId: props.item.id, amount }));
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(props.item.amount);
  }, [props.item]);

  const descrease = () => {
    if (count > 1) {
      handleUpdate(count - 1);
      setCount(count - 1);
    }
  };

  const increase = () => {
    handleUpdate(count + 1);
    setCount(count + 1);
  };

  const handleOnDrag = (e, id) => {
    e.dataTransfer.setData(
      "data",
      JSON.stringify({
        childId: id,
        type: "item",
      })
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const shortDescription =
    props.item.item.description.length > 40
      ? props.item.item.description.slice(0, 39)
      : props.item.item.description;

  return (
    // <Col sm={12} md={6} lg={6} className="colSpacing">
    <Col
      xs={12}
      sm={6}
      md={6}
      lg={6}
      xl={4}
      onDragStart={(e) => handleOnDrag(e, props.item.id)}
      onDragOver={handleDragOver}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          margin: "15px",
          borderStyle: "solid",
          borderColor: "#f6f6f4",
          borderWidth: "1px",
          borderRadius: "10px",
          padding: "15px",
        }}
        className="shadow"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            minHeight: "60px",
            cursor: "pointer",
          }}
          onClick={() => history.push(`/item/${props.item.item.id}`)}
        >
          <p style={{ textTransform: "uppercase" }}>
            <b>{shortDescription}</b>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "10px",
            marginLeft: "10px",
          }}
        >
          <p>
            <b>{props.item.total}</b>
          </p>
        </div>

        <hr
          style={{
            borderStyle: "solid",
            marginTop: "5px",
            marginBottom: "10px",
          }}
        ></hr>

        <Row>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className="itemoverviewcard-image-container"
              style={{
                maxWidth: "125px",
                minWidth: "115px",
                marginRight: "25px",
              }}
            >
              <CenteredImage
                size="95px"
                src={props.item.item.image}
                alt={props.item.item.store}
              />
              <div className="itemoverviewcard-image-itemstoreimage-container">
                <ItemStoreImage storeId={props.item.item.store} />
              </div>
              <div className="itemoverviewcard-sourcecount">
                {props.item.item.sourceCount}
              </div>
            </div>
            <div>
              {props.item.promotions.map((promotion) => (
                <React.Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}
                  >
                    <ItemStoreImage storeId={promotion.store} size="25px" />

                    <p>{`${promotion.promotionText}`}</p>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </Row>

        {/* {props.item.promotion &&
          props.item.promoMinimumAmount < props.item.amount && (
            <div>{`bij aankoop van ${props.item.promoMinimumAmount} : ${props.item.promotion}`}</div>
          )}

        {props.item.promotion &&
          props.item.promoMinimumAmount >= props.item.amount && (
            <div>{`${props.item.promotion}`}</div>
          )}
        <hr
          style={{
            borderStyle: "solid",
            marginTop: "5px",
            marginBottom: "10px",
          }}
        ></hr> */}

        <Row>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Button onClick={() => descrease()} disabled={count === 1}>
                <Remove />
              </Button>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                <h5 size={20}>{count}</h5>
              </div>

              <Button onClick={() => increase()}>
                {" "}
                <Add />
              </Button>
            </div>
            <Button onClick={() => handleDelete()} color="error" size="auto">
              {t("delete")}
            </Button>
          </div>
          {props.item.item.activePromotion && (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <div>{`Actieve promotie: ${props.item.item.activePromotion}`}</div>
            </div>
          )}
        </Row>
      </div>
    </Col>
  );
}
