import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Row } from "reactstrap";
import {
  toggleStoreFilter,
  toggleStoreSelectAll,
} from "../../redux/reducers/searchSlice";
import { history } from "../../App";
import { Edit } from "@mui/icons-material";
import { Button, Checkbox } from "@mui/material";
import { setPageActions } from "../../redux/reducers/authenticationSlice";
const pageActions = [];
export default function StoreFilter(props) {
  const storenames = useSelector((state) => state.search.searchStores);
  let sortedstorenames = [...storenames].sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  );

  const selected = useSelector((state) => state.search.selectedStores);
  const allSelected = useSelector((state) => state.search.allSelected);

  let grouped = [];

  const categories = storenames
    .map((name) => name.category)
    .filter((value, index, array) => array.indexOf(value) === index);

  categories.forEach((category) => {
    grouped.push({
      category: category,
      stores: storenames.filter((store) => store.category === category),
    });
  });

  const groups = storenames.reduce((groups, item) => {
    const group = groups[item.category] || [];
    group.push(item);
    groups[item.category] = group;
    return groups;
  }, {});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageActions(pageActions));
  }, [dispatch]);
  return (
    <React.Fragment>
      <div
        style={{
          fontSize: "0.75em",
          marginTop: "25px",
          textAlign: "center",
          marginBottom: "15px",
        }}
      >
        FILTERS
      </div>
      <div
        style={{
          margin: "auto",
          // width: "80%",
        }}
      >
        {" "}
        <Row>
          <Col sm={12} md={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  fontSize: "0.75em",
                  marginLeft: "5px",
                }}
              >
                ALL
              </div>
              <div>
                <Input
                  type="checkbox"
                  onChange={() => dispatch(toggleStoreSelectAll())}
                  checked={allSelected}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div
        style={
          {
            // marginLeft: "10%",
            // marginRight: "10%",
            // height: "300px",
            // overflowX: "hidden",
            // overflowY: "scroll"
          }
        }
      >
        <Row>
          {groups && (
            <Col sm={12} md={12}>
              {grouped.map((category) => {
                return (
                  <div key={category.category}>
                    <h3>{category.category}</h3>
                    {category.stores.map((store) => {
                      const color = store.validated ? "green" : "red";
                      return (
                        <div
                          key={store.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginBottom: "10px",
                            }}
                          >
                            <div style={{ marginRight: "35px" }}>
                              <Checkbox
                                checked={selected.includes(store.id)}
                                onChange={() =>
                                  dispatch(toggleStoreFilter(store.id))
                                }
                              ></Checkbox>
                            </div>
                            <div style={{ marginRight: "25px" }}>
                              {" "}
                              <img
                                className={`storeImage`}
                                // src={`/images/stores/${store.id}.png`}
                                src={store.image}
                                alt={store.name}
                              ></img>
                            </div>
                            <div>
                              {
                                sortedstorenames.filter(
                                  (x) => x.id === store.id
                                )[0].name
                              }
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    color: color,
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {`${store.validated ? "valid" : "not valid"}${
                                    store.oldConfig ? "(hardcoded)" : ""
                                  }`}
                                </p>
                              </div>
                              <Button
                                size="auto"
                                style={{ marginLeft: "25px" }}
                                onClick={() =>
                                  history.push(`storeconfig/${store.id}`)
                                }
                              >
                                <div
                                  style={
                                    {
                                      // display: "flex",
                                      // justifyContent: "center",
                                      // flexDirection: "column",
                                      // alignItems: "center",
                                    }
                                  }
                                >
                                  <Edit />
                                  <p
                                    style={{
                                      fontSize: "0.5rem",
                                      padding: "2px",
                                    }}
                                  >
                                    EDIT
                                  </p>
                                </div>
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
  );
}
