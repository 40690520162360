import React from "react";
import { Col } from "reactstrap";
import { CenteredImage } from "../../Shared/CenteredImage";
import ItemStoreImage from "../../Shared/ItemStoreImage";
import "./ItemSourceCard.css";
import { Home } from "@mui/icons-material";
import { Button } from "@mui/material";

function ItemTweedehandsSourceCard(props) {
  // const itemCreate = useSelector(state => state.item.itemCreate);
  const source = props.source;
  const handleNavigateStore = () => {
    window.open(source.url, "_blank");
  };
  const shortDescription =
    source.description.length > 40
      ? source.description.slice(0, 39)
      : source.description;

  return (
    <Col xs={12} s={6} md={6} lg={6} xl={4}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          margin: "15px",
          borderStyle: "solid",
          borderColor: "#f6f6f4",
          borderWidth: "1px",
          borderRadius: "10px",
        }}
        className="shadow"
      >
        <div style={{ margin: "5px" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                padding: "5px",
                minHeight: "55px",
              }}
            >
              <h6 style={{ textTransform: "uppercase" }}>{shortDescription}</h6>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              <p>{source.priceString}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              <p style={{ fontSize: "13px", padding: "2px" }}>
                {source.lastPriceDate}
              </p>
            </div>
            <hr
              style={{
                borderStyle: "solid",
                marginTop: "5px",
                marginBottom: "10px",
              }}
            ></hr>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  className="itemoverviewcard-image-container"
                  style={{
                    maxWidth: "125px",
                    minWidth: "115px",
                    // marginRight: "25px",
                    marginLeft: "15px",
                  }}
                >
                  <CenteredImage
                    size="95px"
                    src={source.image}
                    alt={source.store}
                  />
                  <div className="itemoverviewcard-image-itemstoreimage-container">
                    <ItemStoreImage storeId={source.store} />
                  </div>
                </div>
                <div className=""></div>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "5px",
              right: "5px",
              zIndex: "0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {" "}
              <Button onClick={() => handleNavigateStore(source.id)}>
                {" "}
                <p style={{ fontSize: "1rem", padding: "2px" }}>VISIT</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
export default ItemTweedehandsSourceCard;
