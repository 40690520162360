import instance from "./Api";

class CategoryService {
  getCategoriesForUser(userId) {
    return instance.get(`/Category/${userId}`);
  }

  getItemsForCategory(userId, categoryId) {
    return instance.get(
      `/Category/user/${userId}/category/${categoryId}/items`
    );
  }

  addCategory(name, userId) {
    return instance.post(`/Category/user/${userId}/add/${name}`);
  }

  removeCategory(userId, categoryId) {
    return instance.get(
      `/Category/User/${userId}/category/${categoryId}/remove`
    );
  }

  addItemToCategory(categoryId, itemId) {
    return instance.post(`/Category/${categoryId}/item/${itemId}`);
  }

  removeItemFromCategory(categoryId, itemId) {
    return instance.post(`/Category/${categoryId}/item/${itemId}/remove`);
  }
}
export default new CategoryService();
