import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { Button, Input } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import {
  addItemTotemGroup,
  addSelectedItemsTotemGroup,
  clearSelectedItems,
  createItemGroupFromItems,
  getItemGroups,
  setShowItemOverviewAddToGroup,
  setShowItemOverviewGroupCreate,
} from "../../../redux/reducers/itemSlice";

export default function ItemOverviewDialogs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [selection, setSelection] = useState("0");
  const showGroupCreate = useSelector(
    (state) => state.item.showItemOverviewGroupCreate
  );

  const itemGroups = useSelector((state) => state.item.itemGroups);

  const showGroupAddItems = useSelector(
    (state) => state.item.showItemOverviewAddToGroup
  );

  useEffect(() => {
    dispatch(getItemGroups());
  }, []);
  const toggleGroupCreate = () => {
    dispatch(setShowItemOverviewGroupCreate(!showGroupCreate));
  };
  const toggleGroupAddItem = () => {
    dispatch(setShowItemOverviewAddToGroup(!showGroupAddItems));
  };
  console.log(itemGroups);
  return (
    <>
      <Dialog
        open={showGroupCreate}
        onClose={toggleGroupCreate}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>{t("creategroup")}</DialogTitle>
        <DialogContent dividers={true}>
          <Input
            aria-label="group"
            color="primary"
            size="lg"
            placeholder={t("description")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            aria-label="addgroup"
            auto
            light
            onClick={() => {
              dispatch(createItemGroupFromItems(description));
              toggleGroupCreate();
              dispatch(clearSelectedItems());
            }}
          >
            {t("create")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showGroupAddItems}
        onClose={toggleGroupAddItem}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>{t("Artikels toevoegen")}</DialogTitle>
        <DialogContent dividers={true}>
          <Select
            value={selection}
            onChange={(e) => setSelection(e.target.value)}
          >
            <MenuItem value={"0"}>Kies de lijst</MenuItem>
            {itemGroups.map((group) => (
              <MenuItem value={group.id}>{group.description}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          {selection !== "0" && (
            <Button
              aria-label="addgroup"
              auto
              light
              onClick={() => {
                if (selection !== "0") {
                  dispatch(addSelectedItemsTotemGroup(selection));
                  toggleGroupAddItem();
                  dispatch(clearSelectedItems());
                }
              }}
            >
              {t("toevoegen")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
