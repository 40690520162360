import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getItems } from "../../../redux/reducers/itemSlice";
import ItemSourceUnavailableList from "../../../Components/Item/ItemSourceUnavailable/ItemSourceUnavailableList";

export default function ItemSourceUnavailable() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getItems());
  }, [dispatch]);
  return (
    <React.Fragment>
      <div className="pageContent">
        <ItemSourceUnavailableList />
      </div>
    </React.Fragment>
  );
}