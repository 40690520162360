import React from "react";
import { useState, useEffect } from "react";

export default function useDeviceDetection() {
  const [isMobile, setIsMobile] = useState({
    isMobile: false,
    is4kScreen: false,
    isHDScreen: false,
  });

  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      // console.log(userAgent);
      const isMobile =
        /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent) ||
        window.innerWidth < 450;
      const isHDScreen = window.innerWidth > 450 && window.innerWidth <= 1920;
      const is4kScreen = window.innerWidth > 1920;

      setIsMobile({
        isMobile,
        is4kScreen,
        isHDScreen,
      });
      //   const isTablet =
      //     /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

      //   if (isMobile) {
      //     setDevice("Mobile");
      //   } else if (isTablet) {
      //     setDevice("Tablet");
      //   } else {
      //     setDevice("Desktop");
      //   }
    };

    handleDeviceDetection();
    window.addEventListener("resize", handleDeviceDetection);

    return () => {
      window.removeEventListener("resize", handleDeviceDetection);
    };
  }, []);

  return isMobile;
}
