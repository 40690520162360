import React, { useEffect, useState } from "react";
import "./Logout.css";

import { useDispatch } from "react-redux";
import { getStores } from "../../redux/reducers/searchSlice";
import TokenService from "../../Services/TokenService";
import { history } from "../../App";
import {
  AppBar,
  Container,
  Toolbar,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  Avatar,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { OpenInNew, Person, PowerSettingsNew } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Logout(props) {
  const { t } = useTranslation();

  const settings = [
    { title: t("profile"), link: "/profile", icon: <Person /> },
  ];

  const pageActions = useSelector((s) => s.authentication).pageActions;

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElPageActions, setAnchorElPageActions] =
    React.useState<null | HTMLElement>(null);
  const [anchorElPageActionsFull, setAnchorElPageActionsFull] =
    React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenPageActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPageActions(event.currentTarget);
  };
  const handleOpenPageActionsMenuFull = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElPageActionsFull(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClosePageActionsMenu = () => {
    setAnchorElPageActions(null);
  };
  const handleClosePageActionsMenuFull = () => {
    setAnchorElPageActionsFull(null);
  };
  const [user, setUser] = useState(null);
  const [showNavBar, setShowNavBar] = useState(false);

  const dispatch = useDispatch();
  const localUser = TokenService.getUser();

  const isAdmin =
    localUser && localUser.userId === "ca450926-970a-4e76-9025-7fea8efa23e4";
  let pages = [
    { title: t("items"), link: "/items" },
    { title: t("searchitem"), link: "/item/create" },
    { title: t("groups"), link: "/itemgroups" },
    { title: t("promotions"), link: "/promotions" },
    { title: t("stores"), link: "/stores" },
  ];
  if (isAdmin) {
    pages.push({ title: t("store_new"), link: "/storeconfig" });
  }
  const isExcludedPath: boolean =
    window.location.href.indexOf("/login") >= 0 ||
    window.location.href.endsWith("/register") ||
    window.location.href.indexOf("/activate/") >= 0;

  const checkLoggedIn: boolean = localUser && !isExcludedPath;

  const logoff = () => {
    TokenService.removeUser();
    setShowNavBar(false);
    window.location.href = "/login";
  };
  useEffect(() => {
    const redirect = window.location.href;
    setUser(localUser);
    setShowNavBar(checkLoggedIn);
    // setShowNavBar(localUser !== null);
    if (!checkLoggedIn) {
      if (
        redirect &&
        !redirect.endsWith("/login") &&
        !redirect.indexOf("redirect=") < 0 &&
        window.location.href.indexOf("redirect=") < 0
      ) {
        //window.location.href = `/login?redirect=${redirect}`;
      } else {
        if (!isExcludedPath) {
          //window.location.href = `/login`;
        }
      }
    }

    dispatch(getStores());
  }, []);

  useEffect(() => {}, [window.location]);

  return (
    <React.Fragment>
      {showNavBar && !isExcludedPath && (
        <AppBar position="sticky">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                {" "}
                {((pageActions && pageActions.length > 0) ||
                  (pages && pages.length > 0)) && (
                  <div>
                    <IconButton
                      size="small"
                      aria-label="account of current user"
                      aria-controls="menu-pageactions"
                      aria-haspopup="true"
                      onClick={handleOpenPageActionsMenu}
                      color="inherit"
                    >
                      <MenuIcon></MenuIcon>
                      MENU
                    </IconButton>
                    <Menu
                      id="menu-pageactions"
                      anchorEl={anchorElPageActions}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElPageActions)}
                      onClose={handleClosePageActionsMenu}
                    >
                      {pages.map((page, index) => (
                        <MenuItem
                          key={`${page.title}_${index}`}
                          onClick={() => {
                            history.push(page.link);
                            handleClosePageActionsMenu();
                          }}
                        >
                          <div style={{ marginRight: "15px" }}>
                            {" "}
                            <OpenInNew />
                          </div>
                          <Typography textAlign="center">
                            {" "}
                            <p style={{ textTransform: "capitalize" }}>
                              {page.title}
                            </p>
                          </Typography>
                        </MenuItem>
                      ))}

                      {pageActions && pageActions.length > 0 && <Divider />}

                      {pageActions.map((pageAction) => (
                        <MenuItem
                          key={pageAction.title}
                          onClick={() => {
                            handleClosePageActionsMenu();
                            pageAction.action();
                          }}
                        >
                          <div style={{ marginRight: "15px" }}>
                            {" "}
                            {pageAction.icon}
                          </div>

                          <Typography textAlign="center">
                            <p style={{ textTransform: "capitalize" }}>
                              {pageAction.title}
                            </p>
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                )}
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  minWidth: "450px",
                }}
              >
                {" "}
                {pageActions && pageActions.length > 0 && (
                  <div>
                    <IconButton
                      size="small"
                      aria-label="account of current user"
                      aria-controls="menu-pageactionsfull"
                      aria-haspopup="true"
                      onClick={handleOpenPageActionsMenuFull}
                      color="inherit"
                    >
                      <MenuIcon></MenuIcon>
                      MENU
                    </IconButton>
                    <Menu
                      id="menu-pageactionsfull"
                      anchorEl={anchorElPageActionsFull}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElPageActionsFull)}
                      onClose={handleClosePageActionsMenuFull}
                    >
                      {pageActions.map((pageAction) => (
                        <MenuItem
                          key={pageAction.title}
                          onClick={() => {
                            handleClosePageActionsMenuFull();
                            pageAction.action();
                          }}
                        >
                          <div style={{ marginRight: "15px" }}>
                            {" "}
                            {pageAction.icon}
                          </div>

                          <Typography textAlign="center">
                            <p style={{ textTransform: "capitalize" }}>
                              {pageAction.title}
                            </p>
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                )}
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {pages.map((page) => {
                  const active = window.location.href.indexOf(page.link) >= 0;
                  return (
                    <div
                      key={page.title}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {" "}
                      <Button
                        key={page.title}
                        onClick={() => {
                          history.push(page.link);
                          handleCloseNavMenu();
                        }}
                        sx={{
                          my: 2,
                          color: "white",
                          display: "block",
                        }}
                      >
                        {page.title}
                      </Button>
                      {active && <div style={{ borderStyle: "solid" }}></div>}
                    </div>
                  );
                })}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title={t("settings_show")}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={user.userName}
                      src="/static/images/avatar/2.jpg"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.title}
                      onClick={() => {
                        history.push(setting.link);
                        handleCloseUserMenu();
                      }}
                    >
                      <div style={{ marginRight: "15px" }}>{setting.icon}</div>

                      <Typography textAlign="center">
                        <p style={{ textTransform: "capitalize" }}>
                          {" "}
                          {setting.title}
                        </p>
                      </Typography>
                    </MenuItem>
                  ))}
                  <MenuItem
                    key={"logoff"}
                    onClick={() => {
                      logoff();
                      handleCloseUserMenu();
                    }}
                  >
                    <div style={{ marginRight: "15px" }}>
                      <PowerSettingsNew />
                    </div>

                    <Typography textAlign="center">
                      {" "}
                      <p style={{ textTransform: "capitalize" }}>
                        {t("logoff")}
                      </p>
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      )}

      <Container>
        {props.children}
        {/* <div className="logoutChildren">{props.children}</div>
      <div style={{ minHeight: "40px" }}></div> */}
      </Container>
    </React.Fragment>
  );
}
export default Logout;
