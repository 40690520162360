import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import ShoppingListService from "../../Services/ShoppingListService";
import TokenService from "../../Services/TokenService";
import { history } from "../../App";

const initialState = {
  shoppingList: null,
  shoppingLists: [],
  shoppingListsForGroup: [],
};

export const getShoppingListsForUser = createAsyncThunk(
  "shoppinglist/getshoppinglistsforuser",
  async (_, { dispatch, getState }) => {
    const user = TokenService.getUser();
    if (user)
      return await ShoppingListService.getShoppingListsForUser(user.userId);
    return [];
  }
);

export const getShoppingListsForGroup = createAsyncThunk(
  "shoppinglist/getShoppingListsForGroup",
  async (id, { dispatch, getState }) => {
    return await ShoppingListService.getShoppingListsForGroup(id);
  }
);

export const createShoppingListForItemGroup = createAsyncThunk(
  "shoppinglist/createShoppingListForItemGroup",
  async (data, { dispatch, getState }) => {
    const user = TokenService.getUser();
    if (user)
      return await ShoppingListService.createShoppingListFromItemGroup(
        user.userId,
        data.groupId
      );
    return null;
  }
);

export const getShoppingList = createAsyncThunk(
  "shoppinglist/getshoppinglist",
  async (id, { dispatch, getState }) => {
    const user = TokenService.getUser();
    if (user) return await ShoppingListService.getShoppingList(id);
    return null;
  }
);
export const switchShoppingListitem = createAsyncThunk(
  "shoppinglist/switchShoppingListitem",
  async (data, { dispatch, getState }) => {
    return await ShoppingListService.switchShoppingListitem(
      data.id,
      data.itemId
    );
  }
);

const { actions, reducer } = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout: (state, action) => {
      TokenService.removeUser();
      state.user = null;
      // location.href to refresh the state
      window.location.href = "/login";
    },
  },

  extraReducers: {
    [getShoppingListsForUser.fulfilled]: (state, { meta, payload }) => {
      state.shoppingLists = payload;
    },
    [getShoppingListsForGroup.fulfilled]: (state, { meta, payload }) => {
      state.shoppingListsForGroup = payload;
    },
    [getShoppingList.fulfilled]: (state, { meta, payload }) => {
      state.shoppingList = payload;
    },
    [createShoppingListForItemGroup.fulfilled]: (state, { meta, payload }) => {
      state.shoppingList = payload;
      history.push(`/shoppinglist/${payload.id}`);
    },
    [switchShoppingListitem.fulfilled]: (state, { meta, payload }) => {
      state.shoppingList = payload;
    },
  },
});

export const { logout } = actions;
export default reducer;
