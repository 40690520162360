import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getItemsPaged,
  setCurrentPage,
} from "../../../redux/reducers/itemSlice";
import ItemOverviewFilter from "../../../Components/Item/ItemOverview/ItemOverviewFilter.tsx";
import "./ItemOverview.css";
import ItemsList from "../../../Components/Item/ItemOverview/ItemsList";

import { setPageActions } from "../../../redux/reducers/authenticationSlice";

import ItemOverviewSelectActions from "./ItemOverviewSelectActions.tsx";

import useDeviceDetection from "../../../Hooks/useDeviceDetection";
import ItemOverviewDialogs from "./ItemOverviewDialogs.tsx";
import ItemOverviewPagination from "./ItemOverviewPagination.tsx";
import ItemOverviewSelectedSidebar from "./ItemOverviewSelectedSidebar.tsx";
const pageActions = [];

function ItemOverview() {
  const dispatch = useDispatch();
  const selectedItems = useSelector((state) => state.item.selectedItems);

  const filter = useSelector((state) => state.item.filter);

  const device = useDeviceDetection();
  useEffect(() => {
    dispatch(setCurrentPage(1));
    dispatch(getItemsPaged({ query: filter, page: 1, size: 12, tags: [] }));
    dispatch(setPageActions(pageActions));
  }, [dispatch]);

  const height = selectedItems.length > 0 ? "150px" : "75px";

  return (
    <div>
      <div
        style={{
          top: "55px",
          position: "sticky",
          backgroundColor: "white",
          zIndex: 999,
          margin: "0px",
          height: height,
          width: "100%",
        }}
      >
        <ItemOverviewFilter />
        <ItemOverviewSelectActions />
      </div>
      {device.is4kScreen && <ItemOverviewSelectedSidebar />}

      <ItemsList />
      <ItemOverviewPagination />
      <ItemOverviewDialogs />
    </div>
  );
}

export default ItemOverview;
