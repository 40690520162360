import React from "react";
import ItemSourceCard from "./ItemSourceCard";
import { useSelector } from "react-redux";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";

function ItemSources() {
  const itemSources = useSelector((state) => state.item.item.itemSources);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {itemSources && itemSources.length > 0 && (
        <React.Fragment>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ textTransform: "uppercase" }}>{t("itemsources")}</p>
          </div>
          <Row>
            {itemSources.map((source, i) => (
              <ItemSourceCard key={source.id} source={source} />
            ))}
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ItemSources;
