import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Solar() {
  const nisCodes = [
    {
      Code: 11001,
      Gemeente: "AARTSELAAR",
      Postcode: 2630,
    },
    {
      Code: 11002,
      Gemeente: "ANTWERPEN",
      Postcode: 2000,
    },
    {
      Code: 11004,
      Gemeente: "BOECHOUT",
      Postcode: 2530,
    },
    {
      Code: 11005,
      Gemeente: "BOOM",
      Postcode: 2850,
    },
    {
      Code: 11007,
      Gemeente: "BORSBEEK",
      Postcode: 2150,
    },
    {
      Code: 11008,
      Gemeente: "BRASSCHAAT",
      Postcode: 2930,
    },
    {
      Code: 11009,
      Gemeente: "BRECHT",
      Postcode: 2960,
    },
    {
      Code: 11013,
      Gemeente: "EDEGEM",
      Postcode: 2650,
    },
    {
      Code: 11016,
      Gemeente: "ESSEN",
      Postcode: 2910,
    },
    {
      Code: 11018,
      Gemeente: "HEMIKSEM",
      Postcode: 2620,
    },
    {
      Code: 11021,
      Gemeente: "HOVE",
      Postcode: 2540,
    },
    {
      Code: 11022,
      Gemeente: "KALMTHOUT",
      Postcode: 2920,
    },
    {
      Code: 11023,
      Gemeente: "KAPELLEN",
      Postcode: 2950,
    },
    {
      Code: 11024,
      Gemeente: "KONTICH",
      Postcode: 2550,
    },
    {
      Code: 11025,
      Gemeente: "LINT",
      Postcode: 2547,
    },
    {
      Code: 11029,
      Gemeente: "MORTSEL",
      Postcode: 2640,
    },
    {
      Code: 11030,
      Gemeente: "NIEL",
      Postcode: 2845,
    },
    {
      Code: 11035,
      Gemeente: "RANST",
      Postcode: 2520,
    },
    {
      Code: 11037,
      Gemeente: "RUMST",
      Postcode: 2840,
    },
    {
      Code: 11038,
      Gemeente: "SCHELLE",
      Postcode: 2627,
    },
    {
      Code: 11039,
      Gemeente: "SCHILDE",
      Postcode: 2970,
    },
    {
      Code: 11040,
      Gemeente: "SCHOTEN",
      Postcode: 2900,
    },
    {
      Code: 11044,
      Gemeente: "STABROEK",
      Postcode: 2940,
    },
    {
      Code: 11050,
      Gemeente: "WIJNEGEM",
      Postcode: 2110,
    },
    {
      Code: 11052,
      Gemeente: "WOMMELGEM",
      Postcode: 2160,
    },
    {
      Code: 11053,
      Gemeente: "WUUSTWEZEL",
      Postcode: 2990,
    },
    {
      Code: 11054,
      Gemeente: "ZANDHOVEN",
      Postcode: 2240,
    },
    {
      Code: 11055,
      Gemeente: "ZOERSEL",
      Postcode: 2980,
    },
    {
      Code: 11056,
      Gemeente: "ZWIJNDRECHT",
      Postcode: 2070,
    },
    {
      Code: 11057,
      Gemeente: "MALLE",
      Postcode: 2390,
    },
    {
      Code: 12002,
      Gemeente: "BERLAAR",
      Postcode: 2590,
    },
    {
      Code: 12005,
      Gemeente: "BONHEIDEN",
      Postcode: 2820,
    },
    {
      Code: 12007,
      Gemeente: "BORNEM",
      Postcode: 2880,
    },
    {
      Code: 12009,
      Gemeente: "DUFFEL",
      Postcode: 2570,
    },
    {
      Code: 12014,
      Gemeente: "HEIST-OP-DEN-BERG",
      Postcode: 2220,
    },
    {
      Code: 12021,
      Gemeente: "LIER",
      Postcode: 2500,
    },
    {
      Code: 12025,
      Gemeente: "MECHELEN",
      Postcode: 2800,
    },
    {
      Code: 12026,
      Gemeente: "NIJLEN",
      Postcode: 2560,
    },
    {
      Code: 12029,
      Gemeente: "PUTTE",
      Postcode: 2580,
    },
    {
      Code: 12030,
      Gemeente: "PUURS",
      Postcode: 2870,
    },
    {
      Code: 12034,
      Gemeente: "SINT-AMANDS",
      Postcode: 2890,
    },
    {
      Code: 12035,
      Gemeente: "SINT-KATELIJNE-WAVER",
      Postcode: 2860,
    },
    {
      Code: 12040,
      Gemeente: "WILLEBROEK",
      Postcode: 2830,
    },
    {
      Code: 13001,
      Gemeente: "ARENDONK",
      Postcode: 2370,
    },
    {
      Code: 13002,
      Gemeente: "BAARLE-HERTOG",
      Postcode: 2387,
    },
    {
      Code: 13003,
      Gemeente: "BALEN",
      Postcode: 2490,
    },
    {
      Code: 13004,
      Gemeente: "BEERSE",
      Postcode: 2340,
    },
    {
      Code: 13006,
      Gemeente: "DESSEL",
      Postcode: 2480,
    },
    {
      Code: 13008,
      Gemeente: "GEEL",
      Postcode: 2440,
    },
    {
      Code: 13010,
      Gemeente: "GROBBENDONK",
      Postcode: 2280,
    },
    {
      Code: 13011,
      Gemeente: "HERENTALS",
      Postcode: 2200,
    },
    {
      Code: 13012,
      Gemeente: "HERENTHOUT",
      Postcode: 2270,
    },
    {
      Code: 13013,
      Gemeente: "HERSELT",
      Postcode: 2230,
    },
    {
      Code: 13014,
      Gemeente: "HOOGSTRATEN",
      Postcode: 2320,
    },
    {
      Code: 13016,
      Gemeente: "HULSHOUT",
      Postcode: 2235,
    },
    {
      Code: 13017,
      Gemeente: "KASTERLEE",
      Postcode: 2460,
    },
    {
      Code: 13019,
      Gemeente: "LILLE",
      Postcode: 2275,
    },
    {
      Code: 13021,
      Gemeente: "MEERHOUT",
      Postcode: 2450,
    },
    {
      Code: 13023,
      Gemeente: "MERKSPLAS",
      Postcode: 2330,
    },
    {
      Code: 13025,
      Gemeente: "MOL",
      Postcode: 2400,
    },
    {
      Code: 13029,
      Gemeente: "OLEN",
      Postcode: 2250,
    },
    {
      Code: 13031,
      Gemeente: "OUD-TURNHOUT",
      Postcode: 2360,
    },
    {
      Code: 13035,
      Gemeente: "RAVELS",
      Postcode: 2380,
    },
    {
      Code: 13036,
      Gemeente: "RETIE",
      Postcode: 2470,
    },
    {
      Code: 13037,
      Gemeente: "RIJKEVORSEL",
      Postcode: 2310,
    },
    {
      Code: 13040,
      Gemeente: "TURNHOUT",
      Postcode: 2300,
    },
    {
      Code: 13044,
      Gemeente: "VORSELAAR",
      Postcode: 2290,
    },
    {
      Code: 13046,
      Gemeente: "VOSSELAAR",
      Postcode: 2350,
    },
    {
      Code: 13049,
      Gemeente: "WESTERLO",
      Postcode: 2260,
    },
    {
      Code: 13053,
      Gemeente: "LAAKDAL",
      Postcode: 2430,
    },
    {
      Code: 21001,
      Gemeente: "ANDERLECHT",
      Postcode: 1070,
    },
    {
      Code: 21002,
      Gemeente: "OUDERGEM",
      Postcode: 1160,
    },
    {
      Code: 21003,
      Gemeente: "SINT-AGATHA-BERCHEM",
      Postcode: 1082,
    },
    {
      Code: 21004,
      Gemeente: "BRUSSEL",
      Postcode: 1000,
    },
    {
      Code: 21005,
      Gemeente: "ETTERBEEK",
      Postcode: 1040,
    },
    {
      Code: 21006,
      Gemeente: "EVERE",
      Postcode: 1140,
    },
    {
      Code: 21007,
      Gemeente: "VORST",
      Postcode: 1190,
    },
    {
      Code: 21008,
      Gemeente: "GANSHOREN",
      Postcode: 1083,
    },
    {
      Code: 21009,
      Gemeente: "ELSENE",
      Postcode: 1050,
    },
    {
      Code: 21010,
      Gemeente: "JETTE",
      Postcode: 1090,
    },
    {
      Code: 21011,
      Gemeente: "KOEKELBERG",
      Postcode: 1081,
    },
    {
      Code: 21012,
      Gemeente: "SINT-JANS-MOLENBEEK",
      Postcode: 1080,
    },
    {
      Code: 21013,
      Gemeente: "SINT-GILLIS",
      Postcode: 1060,
    },
    {
      Code: 21014,
      Gemeente: "SINT-JOOST-TEN-NODE",
      Postcode: 1210,
    },
    {
      Code: 21015,
      Gemeente: "SCHAARBEEK",
      Postcode: 1030,
    },
    {
      Code: 21016,
      Gemeente: "UKKEL",
      Postcode: 1180,
    },
    {
      Code: 21017,
      Gemeente: "WATERMAAL-BOSVOORDE",
      Postcode: 1170,
    },
    {
      Code: 21018,
      Gemeente: "SINT-LAMBRECHTS-WOLUWE",
      Postcode: 1200,
    },
    {
      Code: 21019,
      Gemeente: "SINT-PIETERS-WOLUWE",
      Postcode: 1150,
    },
    {
      Code: 23002,
      Gemeente: "ASSE",
      Postcode: 1730,
    },
    {
      Code: 23003,
      Gemeente: "BEERSEL",
      Postcode: 1650,
    },
    {
      Code: 23009,
      Gemeente: "BEVER",
      Postcode: 1547,
    },
    {
      Code: 23016,
      Gemeente: "DILBEEK",
      Postcode: 1700,
    },
    {
      Code: 23023,
      Gemeente: "GALMAARDEN",
      Postcode: 1570,
    },
    {
      Code: 23024,
      Gemeente: "GOOIK",
      Postcode: 1755,
    },
    {
      Code: 23025,
      Gemeente: "GRIMBERGEN",
      Postcode: 1850,
    },
    {
      Code: 23027,
      Gemeente: "HALLE",
      Postcode: 1500,
    },
    {
      Code: 23032,
      Gemeente: "HERNE",
      Postcode: 1540,
    },
    {
      Code: 23033,
      Gemeente: "HOEILAART",
      Postcode: 1560,
    },
    {
      Code: 23038,
      Gemeente: "KAMPENHOUT",
      Postcode: 1910,
    },
    {
      Code: 23039,
      Gemeente: "KAPELLE-OP-DEN-BOS",
      Postcode: 1880,
    },
    {
      Code: 23044,
      Gemeente: "LIEDEKERKE",
      Postcode: 1770,
    },
    {
      Code: 23045,
      Gemeente: "LONDERZEEL",
      Postcode: 1840,
    },
    {
      Code: 23047,
      Gemeente: "MACHELEN",
      Postcode: 1830,
    },
    {
      Code: 23050,
      Gemeente: "MEISE",
      Postcode: 1860,
    },
    {
      Code: 23052,
      Gemeente: "MERCHTEM",
      Postcode: 1785,
    },
    {
      Code: 23060,
      Gemeente: "OPWIJK",
      Postcode: 1745,
    },
    {
      Code: 23062,
      Gemeente: "OVERIJSE",
      Postcode: 3090,
    },
    {
      Code: 23064,
      Gemeente: "PEPINGEN",
      Postcode: 1670,
    },
    {
      Code: 23077,
      Gemeente: "SINT-PIETERS-LEEUW",
      Postcode: 1600,
    },
    {
      Code: 23081,
      Gemeente: "STEENOKKERZEEL",
      Postcode: 1820,
    },
    {
      Code: 23086,
      Gemeente: "TERNAT",
      Postcode: 1740,
    },
    {
      Code: 23088,
      Gemeente: "VILVOORDE",
      Postcode: 1800,
    },
    {
      Code: 23094,
      Gemeente: "ZAVENTEM",
      Postcode: 1930,
    },
    {
      Code: 23096,
      Gemeente: "ZEMST",
      Postcode: 1980,
    },
    {
      Code: 23097,
      Gemeente: "ROOSDAAL",
      Postcode: 1760,
    },
    {
      Code: 23098,
      Gemeente: "DROGENBOS",
      Postcode: 1620,
    },
    {
      Code: 23099,
      Gemeente: "KRAAINEM",
      Postcode: 1950,
    },
    {
      Code: 23100,
      Gemeente: "LINKEBEEK",
      Postcode: 1630,
    },
    {
      Code: 23101,
      Gemeente: "SINT-GENESIUS-RODE",
      Postcode: 1640,
    },
    {
      Code: 23102,
      Gemeente: "WEMMEL",
      Postcode: 1780,
    },
    {
      Code: 23103,
      Gemeente: "WEZEMBEEK-OPPEM",
      Postcode: 1970,
    },
    {
      Code: 23104,
      Gemeente: "LENNIK",
      Postcode: 1750,
    },
    {
      Code: 23105,
      Gemeente: "AFFLIGEM",
      Postcode: 1790,
    },
    {
      Code: 24001,
      Gemeente: "AARSCHOT",
      Postcode: 3200,
    },
    {
      Code: 24007,
      Gemeente: "BEGIJNENDIJK",
      Postcode: 3130,
    },
    {
      Code: 24008,
      Gemeente: "BEKKEVOORT",
      Postcode: 3460,
    },
    {
      Code: 24009,
      Gemeente: "BERTEM",
      Postcode: 3060,
    },
    {
      Code: 24011,
      Gemeente: "BIERBEEK",
      Postcode: 3360,
    },
    {
      Code: 24014,
      Gemeente: "BOORTMEERBEEK",
      Postcode: 3190,
    },
    {
      Code: 24016,
      Gemeente: "BOUTERSEM",
      Postcode: 3370,
    },
    {
      Code: 24020,
      Gemeente: "DIEST",
      Postcode: 3290,
    },
    {
      Code: 24028,
      Gemeente: "GEETBETS",
      Postcode: 3450,
    },
    {
      Code: 24033,
      Gemeente: "HAACHT",
      Postcode: 3150,
    },
    {
      Code: 24038,
      Gemeente: "HERENT",
      Postcode: 3020,
    },
    {
      Code: 24041,
      Gemeente: "HOEGAARDEN",
      Postcode: 3320,
    },
    {
      Code: 24043,
      Gemeente: "HOLSBEEK",
      Postcode: 3220,
    },
    {
      Code: 24045,
      Gemeente: "HULDENBERG",
      Postcode: 3040,
    },
    {
      Code: 24048,
      Gemeente: "KEERBERGEN",
      Postcode: 3140,
    },
    {
      Code: 24054,
      Gemeente: "KORTENAKEN",
      Postcode: 3470,
    },
    {
      Code: 24055,
      Gemeente: "KORTENBERG",
      Postcode: 3070,
    },
    {
      Code: 24059,
      Gemeente: "LANDEN",
      Postcode: 3400,
    },
    {
      Code: 24062,
      Gemeente: "LEUVEN",
      Postcode: 3000,
    },
    {
      Code: 24066,
      Gemeente: "LUBBEEK",
      Postcode: 3210,
    },
    {
      Code: 24086,
      Gemeente: "OUD-HEVERLEE",
      Postcode: 3050,
    },
    {
      Code: 24094,
      Gemeente: "ROTSELAAR",
      Postcode: 3110,
    },
    {
      Code: 24104,
      Gemeente: "TERVUREN",
      Postcode: 3080,
    },
    {
      Code: 24107,
      Gemeente: "TIENEN",
      Postcode: 3300,
    },
    {
      Code: 24109,
      Gemeente: "TREMELO",
      Postcode: 3120,
    },
    {
      Code: 24130,
      Gemeente: "ZOUTLEEUW",
      Postcode: 3440,
    },
    {
      Code: 24133,
      Gemeente: "LINTER",
      Postcode: 3350,
    },
    {
      Code: 24134,
      Gemeente: "SCHERPENHEUVEL-ZICHEM",
      Postcode: 3270,
    },
    {
      Code: 24135,
      Gemeente: "TIELT-WINGE",
      Postcode: 3390,
    },
    {
      Code: 24137,
      Gemeente: "GLABBEEK",
      Postcode: 3380,
    },
    {
      Code: 31003,
      Gemeente: "BEERNEM",
      Postcode: 8730,
    },
    {
      Code: 31004,
      Gemeente: "BLANKENBERGE",
      Postcode: 8370,
    },
    {
      Code: 31005,
      Gemeente: "BRUGGE",
      Postcode: 8000,
    },
    {
      Code: 31006,
      Gemeente: "DAMME",
      Postcode: 8340,
    },
    {
      Code: 31012,
      Gemeente: "JABBEKE",
      Postcode: 8490,
    },
    {
      Code: 31022,
      Gemeente: "OOSTKAMP",
      Postcode: 8020,
    },
    {
      Code: 31033,
      Gemeente: "TORHOUT",
      Postcode: 8820,
    },
    {
      Code: 31040,
      Gemeente: "ZEDELGEM",
      Postcode: 8210,
    },
    {
      Code: 31042,
      Gemeente: "ZUIENKERKE",
      Postcode: 8377,
    },
    {
      Code: 31043,
      Gemeente: "KNOKKE-HEIST",
      Postcode: 8300,
    },
    {
      Code: 32003,
      Gemeente: "DIKSMUIDE",
      Postcode: 8600,
    },
    {
      Code: 32006,
      Gemeente: "HOUTHULST",
      Postcode: 8650,
    },
    {
      Code: 32010,
      Gemeente: "KOEKELARE",
      Postcode: 8680,
    },
    {
      Code: 32011,
      Gemeente: "KORTEMARK",
      Postcode: 8610,
    },
    {
      Code: 32030,
      Gemeente: "LO-RENINGE",
      Postcode: 8647,
    },
    {
      Code: 33011,
      Gemeente: "IEPER",
      Postcode: 8900,
    },
    {
      Code: 33016,
      Gemeente: "MESEN",
      Postcode: 8957,
    },
    {
      Code: 33021,
      Gemeente: "POPERINGE",
      Postcode: 8970,
    },
    {
      Code: 33029,
      Gemeente: "WERVIK",
      Postcode: 8940,
    },
    {
      Code: 33037,
      Gemeente: "ZONNEBEKE",
      Postcode: 8980,
    },
    {
      Code: 33039,
      Gemeente: "HEUVELLAND",
      Postcode: 8950,
    },
    {
      Code: 33040,
      Gemeente: "LANGEMARK-POELKAPELLE",
      Postcode: 8920,
    },
    {
      Code: 33041,
      Gemeente: "VLETEREN",
      Postcode: 8640,
    },
    {
      Code: 34002,
      Gemeente: "ANZEGEM",
      Postcode: 8570,
    },
    {
      Code: 34003,
      Gemeente: "AVELGEM",
      Postcode: 8580,
    },
    {
      Code: 34009,
      Gemeente: "DEERLIJK",
      Postcode: 8540,
    },
    {
      Code: 34013,
      Gemeente: "HARELBEKE",
      Postcode: 8530,
    },
    {
      Code: 34022,
      Gemeente: "KORTRIJK",
      Postcode: 8500,
    },
    {
      Code: 34023,
      Gemeente: "KUURNE",
      Postcode: 8520,
    },
    {
      Code: 34025,
      Gemeente: "LENDELEDE",
      Postcode: 8860,
    },
    {
      Code: 34027,
      Gemeente: "MENEN",
      Postcode: 8930,
    },
    {
      Code: 34040,
      Gemeente: "WAREGEM",
      Postcode: 8790,
    },
    {
      Code: 34041,
      Gemeente: "WEVELGEM",
      Postcode: 8560,
    },
    {
      Code: 34042,
      Gemeente: "ZWEVEGEM",
      Postcode: 8550,
    },
    {
      Code: 34043,
      Gemeente: "SPIERE-HELKIJN",
      Postcode: 8587,
    },
    {
      Code: 35002,
      Gemeente: "BREDENE",
      Postcode: 8450,
    },
    {
      Code: 35005,
      Gemeente: "GISTEL",
      Postcode: 8470,
    },
    {
      Code: 35006,
      Gemeente: "ICHTEGEM",
      Postcode: 8480,
    },
    {
      Code: 35011,
      Gemeente: "MIDDELKERKE",
      Postcode: 8430,
    },
    {
      Code: 35013,
      Gemeente: "OOSTENDE",
      Postcode: 8400,
    },
    {
      Code: 35014,
      Gemeente: "OUDENBURG",
      Postcode: 8460,
    },
    {
      Code: 35029,
      Gemeente: "DE HAAN",
      Postcode: 8420,
    },
    {
      Code: 36006,
      Gemeente: "HOOGLEDE",
      Postcode: 8830,
    },
    {
      Code: 36007,
      Gemeente: "INGELMUNSTER",
      Postcode: 8770,
    },
    {
      Code: 36008,
      Gemeente: "IZEGEM",
      Postcode: 8870,
    },
    {
      Code: 36010,
      Gemeente: "LEDEGEM",
      Postcode: 8880,
    },
    {
      Code: 36011,
      Gemeente: "LICHTERVELDE",
      Postcode: 8810,
    },
    {
      Code: 36012,
      Gemeente: "MOORSLEDE",
      Postcode: 8890,
    },
    {
      Code: 36015,
      Gemeente: "ROESELARE",
      Postcode: 8800,
    },
    {
      Code: 36019,
      Gemeente: "STADEN",
      Postcode: 8840,
    },
    {
      Code: 37002,
      Gemeente: "DENTERGEM",
      Postcode: 8720,
    },
    {
      Code: 37007,
      Gemeente: "MEULEBEKE",
      Postcode: 8760,
    },
    {
      Code: 37010,
      Gemeente: "OOSTROZEBEKE",
      Postcode: 8780,
    },
    {
      Code: 37011,
      Gemeente: "PITTEM",
      Postcode: 8740,
    },
    {
      Code: 37012,
      Gemeente: "RUISELEDE",
      Postcode: 8755,
    },
    {
      Code: 37015,
      Gemeente: "TIELT",
      Postcode: 8700,
    },
    {
      Code: 37017,
      Gemeente: "WIELSBEKE",
      Postcode: 8710,
    },
    {
      Code: 37018,
      Gemeente: "WINGENE",
      Postcode: 8750,
    },
    {
      Code: 37020,
      Gemeente: "ARDOOIE",
      Postcode: 8850,
    },
    {
      Code: 38002,
      Gemeente: "ALVERINGEM",
      Postcode: 8690,
    },
    {
      Code: 38008,
      Gemeente: "DE PANNE",
      Postcode: 8660,
    },
    {
      Code: 38014,
      Gemeente: "KOKSIJDE",
      Postcode: 8670,
    },
    {
      Code: 38016,
      Gemeente: "NIEUWPOORT",
      Postcode: 8620,
    },
    {
      Code: 38025,
      Gemeente: "VEURNE",
      Postcode: 8630,
    },
    {
      Code: 41002,
      Gemeente: "AALST",
      Postcode: 9300,
    },
    {
      Code: 41011,
      Gemeente: "DENDERLEEUW",
      Postcode: 9470,
    },
    {
      Code: 41018,
      Gemeente: "GERAARDSBERGEN",
      Postcode: 9500,
    },
    {
      Code: 41024,
      Gemeente: "HAALTERT",
      Postcode: 9450,
    },
    {
      Code: 41027,
      Gemeente: "HERZELE",
      Postcode: 9550,
    },
    {
      Code: 41034,
      Gemeente: "LEDE",
      Postcode: 9340,
    },
    {
      Code: 41048,
      Gemeente: "NINOVE",
      Postcode: 9400,
    },
    {
      Code: 41063,
      Gemeente: "SINT-LIEVENS-HOUTEM",
      Postcode: 9520,
    },
    {
      Code: 41081,
      Gemeente: "ZOTTEGEM",
      Postcode: 9620,
    },
    {
      Code: 41082,
      Gemeente: "ERPE-MERE",
      Postcode: 9420,
    },
    {
      Code: 42003,
      Gemeente: "BERLARE",
      Postcode: 9290,
    },
    {
      Code: 42004,
      Gemeente: "BUGGENHOUT",
      Postcode: 9255,
    },
    {
      Code: 42006,
      Gemeente: "DENDERMONDE",
      Postcode: 9200,
    },
    {
      Code: 42008,
      Gemeente: "HAMME",
      Postcode: 9220,
    },
    {
      Code: 42010,
      Gemeente: "LAARNE",
      Postcode: 9270,
    },
    {
      Code: 42011,
      Gemeente: "LEBBEKE",
      Postcode: 9280,
    },
    {
      Code: 42023,
      Gemeente: "WAASMUNSTER",
      Postcode: 9250,
    },
    {
      Code: 42025,
      Gemeente: "WETTEREN",
      Postcode: 9230,
    },
    {
      Code: 42026,
      Gemeente: "WICHELEN",
      Postcode: 9260,
    },
    {
      Code: 42028,
      Gemeente: "ZELE",
      Postcode: 9240,
    },
    {
      Code: 43002,
      Gemeente: "ASSENEDE",
      Postcode: 9960,
    },
    {
      Code: 43005,
      Gemeente: "EEKLO",
      Postcode: 9900,
    },
    {
      Code: 43007,
      Gemeente: "KAPRIJKE",
      Postcode: 9970,
    },
    {
      Code: 43010,
      Gemeente: "MALDEGEM",
      Postcode: 9990,
    },
    {
      Code: 43014,
      Gemeente: "SINT-LAUREINS",
      Postcode: 9980,
    },
    {
      Code: 43018,
      Gemeente: "ZELZATE",
      Postcode: 9060,
    },
    {
      Code: 44001,
      Gemeente: "AALTER",
      Postcode: 9880,
    },
    {
      Code: 44011,
      Gemeente: "DEINZE",
      Postcode: 9800,
    },
    {
      Code: 44012,
      Gemeente: "DE PINTE",
      Postcode: 9840,
    },
    {
      Code: 44013,
      Gemeente: "DESTELBERGEN",
      Postcode: 9070,
    },
    {
      Code: 44019,
      Gemeente: "EVERGEM",
      Postcode: 9940,
    },
    {
      Code: 44020,
      Gemeente: "GAVERE",
      Postcode: 9890,
    },
    {
      Code: 44021,
      Gemeente: "GENT",
      Postcode: 9000,
    },
    {
      Code: 44029,
      Gemeente: "KNESSELARE",
      Postcode: 9910,
    },
    {
      Code: 44034,
      Gemeente: "LOCHRISTI",
      Postcode: 9080,
    },
    {
      Code: 44036,
      Gemeente: "LOVENDEGEM",
      Postcode: 9920,
    },
    {
      Code: 44040,
      Gemeente: "MELLE",
      Postcode: 9090,
    },
    {
      Code: 44043,
      Gemeente: "MERELBEKE",
      Postcode: 9820,
    },
    {
      Code: 44045,
      Gemeente: "MOERBEKE",
      Postcode: 9180,
    },
    {
      Code: 44048,
      Gemeente: "NAZARETH",
      Postcode: 9810,
    },
    {
      Code: 44049,
      Gemeente: "NEVELE",
      Postcode: 9850,
    },
    {
      Code: 44052,
      Gemeente: "OOSTERZELE",
      Postcode: 9860,
    },
    {
      Code: 44064,
      Gemeente: "SINT-MARTENS-LATEM",
      Postcode: 9830,
    },
    {
      Code: 44072,
      Gemeente: "WAARSCHOOT",
      Postcode: 9950,
    },
    {
      Code: 44073,
      Gemeente: "WACHTEBEKE",
      Postcode: 9185,
    },
    {
      Code: 44080,
      Gemeente: "ZOMERGEM",
      Postcode: 9930,
    },
    {
      Code: 44081,
      Gemeente: "ZULTE",
      Postcode: 9870,
    },
    {
      Code: 45017,
      Gemeente: "KRUISHOUTEM",
      Postcode: 9770,
    },
    {
      Code: 45035,
      Gemeente: "OUDENAARDE",
      Postcode: 9700,
    },
    {
      Code: 45041,
      Gemeente: "RONSE",
      Postcode: 9600,
    },
    {
      Code: 45057,
      Gemeente: "ZINGEM",
      Postcode: 9750,
    },
    {
      Code: 45059,
      Gemeente: "BRAKEL",
      Postcode: 9660,
    },
    {
      Code: 45060,
      Gemeente: "KLUISBERGEN",
      Postcode: 9690,
    },
    {
      Code: 45061,
      Gemeente: "WORTEGEM-PETEGEM",
      Postcode: 9790,
    },
    {
      Code: 45062,
      Gemeente: "HOREBEKE",
      Postcode: 9667,
    },
    {
      Code: 45063,
      Gemeente: "LIERDE",
      Postcode: 9570,
    },
    {
      Code: 45064,
      Gemeente: "MAARKEDAL",
      Postcode: 9680,
    },
    {
      Code: 45065,
      Gemeente: "ZWALM",
      Postcode: 9630,
    },
    {
      Code: 46003,
      Gemeente: "BEVEREN",
      Postcode: 9120,
    },
    {
      Code: 46013,
      Gemeente: "KRUIBEKE",
      Postcode: 9150,
    },
    {
      Code: 46014,
      Gemeente: "LOKEREN",
      Postcode: 9160,
    },
    {
      Code: 46020,
      Gemeente: "SINT-GILLIS-WAAS",
      Postcode: 9170,
    },
    {
      Code: 46021,
      Gemeente: "SINT-NIKLAAS",
      Postcode: 9100,
    },
    {
      Code: 46024,
      Gemeente: "STEKENE",
      Postcode: 9190,
    },
    {
      Code: 46025,
      Gemeente: "TEMSE",
      Postcode: 9140,
    },
    {
      Code: 71002,
      Gemeente: "AS",
      Postcode: 3570,
    },
    {
      Code: 71004,
      Gemeente: "BERINGEN",
      Postcode: 3580,
    },
    {
      Code: 71011,
      Gemeente: "DIEPENBEEK",
      Postcode: 3590,
    },
    {
      Code: 71016,
      Gemeente: "GENK",
      Postcode: 3600,
    },
    {
      Code: 71017,
      Gemeente: "GINGELOM",
      Postcode: 3890,
    },
    {
      Code: 71020,
      Gemeente: "HALEN",
      Postcode: 3545,
    },
    {
      Code: 71022,
      Gemeente: "HASSELT",
      Postcode: 3500,
    },
    {
      Code: 71024,
      Gemeente: "HERK-DE-STAD",
      Postcode: 3540,
    },
    {
      Code: 71034,
      Gemeente: "LEOPOLDSBURG",
      Postcode: 3970,
    },
    {
      Code: 71037,
      Gemeente: "LUMMEN",
      Postcode: 3560,
    },
    {
      Code: 71045,
      Gemeente: "NIEUWERKERKEN",
      Postcode: 3850,
    },
    {
      Code: 71047,
      Gemeente: "OPGLABBEEK",
      Postcode: 3660,
    },
    {
      Code: 71053,
      Gemeente: "SINT-TRUIDEN",
      Postcode: 3800,
    },
    {
      Code: 71057,
      Gemeente: "TESSENDERLO",
      Postcode: 3980,
    },
    {
      Code: 71066,
      Gemeente: "ZONHOVEN",
      Postcode: 3520,
    },
    {
      Code: 71067,
      Gemeente: "ZUTENDAAL",
      Postcode: 3690,
    },
    {
      Code: 71069,
      Gemeente: "HAM",
      Postcode: 3945,
    },
    {
      Code: 71070,
      Gemeente: "HEUSDEN-ZOLDER",
      Postcode: 3550,
    },
    {
      Code: 72003,
      Gemeente: "BOCHOLT",
      Postcode: 3950,
    },
    {
      Code: 72004,
      Gemeente: "BREE",
      Postcode: 3960,
    },
    {
      Code: 72018,
      Gemeente: "KINROOI",
      Postcode: 3640,
    },
    {
      Code: 72020,
      Gemeente: "LOMMEL",
      Postcode: 3920,
    },
    {
      Code: 72021,
      Gemeente: "MAASEIK",
      Postcode: 3680,
    },
    {
      Code: 72025,
      Gemeente: "NEERPELT",
      Postcode: 3910,
    },
    {
      Code: 72029,
      Gemeente: "OVERPELT",
      Postcode: 3900,
    },
    {
      Code: 72030,
      Gemeente: "PEER",
      Postcode: 3990,
    },
    {
      Code: 72037,
      Gemeente: "HAMONT-ACHEL",
      Postcode: 3930,
    },
    {
      Code: 72038,
      Gemeente: "HECHTEL-EKSEL",
      Postcode: 3940,
    },
    {
      Code: 72039,
      Gemeente: "HOUTHALEN-HELCHTEREN",
      Postcode: 3530,
    },
    {
      Code: 72040,
      Gemeente: "MEEUWEN-GRUITRODE",
      Postcode: 3670,
    },
    {
      Code: 72041,
      Gemeente: "DILSEN-STOKKEM",
      Postcode: 3650,
    },
    {
      Code: 73001,
      Gemeente: "ALKEN",
      Postcode: 3570,
    },
    {
      Code: 73006,
      Gemeente: "BILZEN",
      Postcode: 3740,
    },
    {
      Code: 73009,
      Gemeente: "BORGLOON",
      Postcode: 3840,
    },
    {
      Code: 73022,
      Gemeente: "HEERS",
      Postcode: 3870,
    },
    {
      Code: 73028,
      Gemeente: "HERSTAPPE",
      Postcode: 3717,
    },
    {
      Code: 73032,
      Gemeente: "HOESELT",
      Postcode: 3730,
    },
    {
      Code: 73040,
      Gemeente: "KORTESSEM",
      Postcode: 3720,
    },
    {
      Code: 73042,
      Gemeente: "LANAKEN",
      Postcode: 3620,
    },
    {
      Code: 73066,
      Gemeente: "RIEMST",
      Postcode: 3770,
    },
    {
      Code: 73083,
      Gemeente: "TONGEREN",
      Postcode: 3700,
    },
    {
      Code: 73098,
      Gemeente: "WELLEN",
      Postcode: 3830,
    },
    {
      Code: 73107,
      Gemeente: "MAASMECHELEN",
      Postcode: 3630,
    },
    {
      Code: 73109,
      Gemeente: "VOEREN",
      Postcode: 3790,
    },
  ];

  const [solar, setSolar] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        "https://www.webkom.be/gateway/solar/000"
      );
      console.log(response.data);
      setSolar(response.data);
    };

    getData();
  }, []);

  return (
    <div>
      {solar.map((item, id) => (
        <div>
          <h2 key={id}>{`${item.date} (${
            id === 0 ? "vandaag" : "morgen"
          })`}</h2>
          <h3>{`De beste tijd voor grootverbruikers is tussen ${item.bestStart} en ${item.bestStop}`}</h3>
          <h3>{`De beste tijden om grootverbuikers op te zetten:`}</h3>
          <ul>
            {item.optimalHours.map((hour, id2) => (
              <li>
                <h3 key={id2}>{hour}</h3>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
