import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ItemPromotionList from "../../../Components/Item/ItemPromotions/ItemPromotionList";
import { getUserPromotionItems } from "../../../redux/reducers/itemSlice";
import { setPageActions } from "../../../redux/reducers/authenticationSlice";
const pageActions = [];

export default function ItemPromotions() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserPromotionItems());
    dispatch(setPageActions(pageActions));
  }, [dispatch]);
  return (
    <div style={{ marginTop: "50px" }}>
      <ItemPromotionList />
    </div>
  );
}
