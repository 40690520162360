import instance from "./Api";
import TokenService from "./TokenService";

class UserService {
  getUsers() {
    return instance.get("/User");
  }
  getColruytStores(query) {
    return instance.get("/Search/stores/" + query);
  }
  getUser() {
    var user = TokenService.getUser();
    if (user) {
      return instance.get(`/User/${user.userId}`);
    }
    return null;
  }
  updateUser(user) {
    return instance.post(`/User/${user.userId}`, user);
  }
}
export default new UserService();
