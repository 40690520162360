import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItemTotemGroup } from "../../redux/reducers/itemSlice";
import { CenteredImage } from "../Shared/CenteredImage";
import { Col, Row } from "reactstrap";
import { Button } from "@mui/material";
import ItemStoreImage from "../Shared/ItemStoreImage";
import { useTranslation } from "react-i18next";

export default function ItemGroupItemPicker(props) {
  const items = useSelector((state) => state.item.filteredItems);
  const dispatch = useDispatch();
  const handleAdd = (id) => {
    dispatch(addItemTotemGroup({ groupId: props.groupId, itemId: id }));
  };
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {items && items.length > 0 && (
        <Row>
          {items.map((item) => {
            const shortDescription =
              item.description.length > 40
                ? item.description.slice(0, 39)
                : item.description;
            return (
              <Col xs={12} sm={12} md={12} lg={12}>
                {" "}
                <div
                  key={item.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    margin: "15px",
                    borderStyle: "solid",
                    borderColor: "#f6f6f4",
                    borderWidth: "1px",
                    borderRadius: "10px",
                    padding: "15px",
                  }}
                  className="shadow"
                >
                  <div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          minHeight: "60px",
                        }}
                      >
                        <p style={{ textTransform: "uppercase" }}>
                          <b>{shortDescription}</b>
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginRight: "10px",
                        }}
                      >
                        <p>
                          <b>{item.priceString}</b>
                        </p>
                      </div>
                      <hr
                        style={{
                          borderStyle: "solid",
                          marginTop: "5px",
                          marginBottom: "10px",
                        }}
                      ></hr>
                    </div>
                    <Row wrap="no-wrap" justify="space-between">
                      <div style={{ display: "flex" }}>
                        <div
                          className="itemoverviewcard-image-container"
                          style={{
                            maxWidth: "125px",
                            minWidth: "115px",
                            // marginRight: "25px",
                            marginLeft: "15px",
                          }}
                        >
                          <CenteredImage
                            size="95px"
                            src={item.image}
                            alt={item.store}
                          />
                          <div className="itemoverviewcard-image-itemstoreimage-container">
                            <ItemStoreImage storeId={item.store} />
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      justify="flex-end"
                    >
                      <Button onClick={() => handleAdd(item.id)}>
                        {t("add")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </React.Fragment>
  );
}
