import React from "react";
import { useSelector } from "react-redux";
import { Row } from "reactstrap";
import ItemOverviewCardMini from "../../../Components/Item/ItemOverview/ItemOverviewCardMini";
import { useTranslation } from "react-i18next";

export default function ItemOverviewSelectedSidebar() {
  const selectedItems = useSelector((state) => state.item.selectedItems);
  const { t } = useTranslation();
  return (
    <>
      {selectedItems && selectedItems.length > 0 && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            justifyContent: "center",
            right: "5px",
          }}
        >
          <div
            style={{
              width: "175px",
              height: "900px",
            }}
          >
            <div
              style={{
                display: "flex",

                justifyContent: "center",
                textTransform: "capitalize",
              }}
            >
              {t("selected")}
            </div>
            <div
              style={{
                width: "175px",
                height: "900px",
                overflowX: "hidden",
                overflowY: "scroll",
              }}
            >
              {" "}
              <Row>
                {selectedItems.map((item, i) => (
                  <ItemOverviewCardMini key={item.id} item={item} />
                ))}{" "}
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
