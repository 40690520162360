import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import {
  Html5QrcodeScanner,
  Html5QrcodeScanType,
  Html5Qrcode,
} from "html5-qrcode";
import SearchService from "../../Services/SearchService";
import TokenService from "../../Services/TokenService";
import { toast } from "react-toastify";
import { history } from "../../App";

const initialState = {
  items: [],
  currentSearchId: "",
  suggestions: [],
  query: "",
  barcode: "Barcode niet gevonden",
  scanning: false,
  searchStores: [],
  groupedStores: [],
  categories: [],
  selectedGroupedStores: [],
  sortedStores: [],
  selectedStores: [],
  selectedStore: 1,
  allSelected: false,
  selectedItems: [],
  menuOpen: false,
  noResults: false,
  searchVisible: false,
  searching: false,
  storeConfig: {
    searchConfiguration: {
      tasks: [],
    },
    getItemConfiguration: {
      tasks: [],
    },
  },
  testHit: {},
  testHits: [],
  taskTypes: [
    { value: 0, label: "ReplaceSingle" },
    { value: 1, label: "ReplaceMultiple" },
    { value: 2, label: "BeforeAfter" },
    { value: 3, label: "Split" },
    { value: 4, label: "ValueBetween" },
    { value: 5, label: "ValueBetweenReversed" },
    { value: 6, label: "StoreValue" },
    { value: 7, label: "GetItemRaw" },
    { value: 8, label: "GetParameterValue" },
    { value: 9, label: "JoinCharacter" },
    { value: 10, label: "SaveAsVariable" },
    { value: 11, label: "Pattern" },
  ],
  steps: [
    { value: 0, label: "Description" },
    { value: 1, label: "Price" },
    { value: 2, label: "Image" },
    { value: 3, label: "Url" },
    { value: 4, label: "ItemId" },
    { value: 5, label: "ItemList" },
    { value: 6, label: "PrepareQuery" },
    { value: 7, label: "Promotion" },
    { value: 8, label: "PromotionStart" },
    { value: 9, label: "PromotionEnd" },
  ],
  categoryList: [
    { value: 0, label: "General" },
    { value: 1, label: "Electronica" },
    { value: 2, label: "Apotheek" },
    { value: 3, label: "Voeding" },
    { value: 4, label: "Tweedehands" },
    { value: 5, label: "Muziek" },
    { value: 6, label: "Doe Het Zelf" },
    { value: 7, label: "Verzorging" },
    { value: 8, label: "Kleding" },
    { value: 9, label: "Tuin" },
    { value: 10, label: "Games" },
    { value: 11, label: "Parfum" },
    { value: 12, label: "Drank" },
    { value: 13, label: "Snus" },
    { value: 14, label: "Meubels" },
    { value: 15, label: "Domotica" },
    { value: 16, label: "Apparaten" },
    { value: 17, label: "Outdoor" },
    { value: 18, label: "Computer" },
    { value: 19, label: "Veiling" },
  ],
  callType: [
    { value: 0, label: "POST" },
    { value: 1, label: "GET" },
    { value: 2, label: "PostUrlEncoded" },
    { value: 3, label: "AlGOLIA" },
    { value: 4, label: "SenseFuel" },
  ],
  initialTask: {
    order: 1,
    type: 4,
    step: 0,
    parameter1: "",
    parameter2: "",
  },
  scanner: null,
};

let interval;
let scanTimeOut;
let scanTimeOut2;

export const getResult = createAsyncThunk(
  "search/getresult",

  async (id, { dispatch, getState }) => {
    if (id === "00000000-0000-0000-0000-000000000000") {
      return [];
    }
    let timer = 0;
    interval = setInterval(async () => {
      timer = timer + 1;
      const status = await SearchService.getSearchStatus(id);
      if (timer >= 45) {
        const result = await SearchService.getSearchResult(id);
        dispatch(setResult(result));
        dispatch(endSearch(result.length));
      }
      if (status === 2) {
        const result = await SearchService.getSearchResult(id);
        dispatch(setResult(result));
        dispatch(endSearch(result.length));
      } else if (status === 5) {
        const result = await SearchService.getSearchResult(id);
        dispatch(setResultPartial(result));
        // setTimeout(()=>dispatch(getResult(id)),500)
        // dispatch(getResult(id));
      } else if (status === 3) {
        dispatch(setResult([]));
        dispatch(endSearch(0));
        return [];
      }
    }, 1000);
  }
);

export const saveStoreConfig = createAsyncThunk(
  "search/savestoreconfig",

  async (_, { dispatch, getState }) => {
    const state = getState().search;
    try {
      let saveConfig = JSON.parse(JSON.stringify(state.storeConfig));
      let tasks = [];
      if (
        saveConfig.searchConfiguration &&
        saveConfig.searchConfiguration.tasks
      ) {
        for (
          let index = 0;
          index < saveConfig.searchConfiguration.tasks.length;
          index++
        ) {
          let element = { ...saveConfig.searchConfiguration.tasks[index] };
          if (true) {
            element.id = "00000000-0000-0000-0000-000000000000";
          }
          tasks.push(element);
        }
        try {
          saveConfig.searchConfiguration.tasks = tasks;
        } catch (err) {
          console.log(err);
        }

        tasks = [];
      }
      if (
        saveConfig.getItemConfiguration &&
        saveConfig.getItemConfiguration.tasks
      ) {
        for (
          let index = 0;
          index < saveConfig.getItemConfiguration.tasks.length;
          index++
        ) {
          let element = {
            ...saveConfig.getItemConfiguration.tasks[index],
          };
          if (true) {
            element.id = "00000000-0000-0000-0000-000000000000";
          }
          tasks.push(element);
        }
        saveConfig.getItemConfiguration.tasks = tasks;
      }

      const response = await SearchService.saveConfig(saveConfig);
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const testStoreConfig = createAsyncThunk(
  "search/teststoreconfig",

  async (_, { dispatch, getState }) => {
    const state = getState().search;
    try {
      let saveConfig = JSON.parse(JSON.stringify(state.storeConfig));
      let tasks = [];
      if (
        saveConfig.searchConfiguration &&
        saveConfig.searchConfiguration.tasks
      ) {
        for (
          let index = 0;
          index < saveConfig.searchConfiguration.tasks.length;
          index++
        ) {
          let element = { ...saveConfig.searchConfiguration.tasks[index] };
          if (true) {
            element.id = "00000000-0000-0000-0000-000000000000";
          }
          tasks.push(element);
        }
        try {
          saveConfig.searchConfiguration.tasks = tasks;
        } catch (err) {
          console.log(err);
        }

        tasks = [];
      }
      if (
        saveConfig.getItemConfiguration &&
        saveConfig.getItemConfiguration.tasks
      ) {
        for (
          let index = 0;
          index < saveConfig.getItemConfiguration.tasks.length;
          index++
        ) {
          let element = {
            ...saveConfig.getItemConfiguration.tasks[index],
          };
          if (true) {
            element.id = "00000000-0000-0000-0000-000000000000";
          }
          tasks.push(element);
        }
        saveConfig.getItemConfiguration.tasks = tasks;
      }

      const response = await SearchService.testConfig(saveConfig);
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const search = createAsyncThunk(
  "search/items",
  async (_, { dispatch, getState }) => {
    const state = getState().search;
    if (!state.query || state.query.length === 1) return [];
    dispatch(startSearch());
    const user = TokenService.getUser();

    var searcharray = [];
    if (typeof state.selectedStore === "string") {
      state.selectedGroupedStores
        .filter((g) => g.category === state.selectedStore)
        .map((f) => f.stores.map((s) => searcharray.push(s.id)));
    } else {
      searcharray = [state.selectedStore];
    }

    const id = await SearchService.search(
      state.query,
      searcharray,
      user.configuration.colruytLocation
    );
    dispatch(getResult(id));

    //return items;
  }
);

export const scanCode = createAsyncThunk(
  "search/scanCode",
  async (code, { dispatch, getState }) => {
    const user = TokenService.getUser();
    dispatch(startSearch());
    return await SearchService.scanCode(
      code,
      user.configuration.colruytLocation
    );
  }
);

export const scanCodeMulti = createAsyncThunk(
  "search/scanCodeMulti",
  async (code, { dispatch, getState }) => {
    const user = TokenService.getUser();
    dispatch(startSearch());
    const id = await SearchService.scanCodeMulti(
      code,
      user.configuration.colruytLocation
    );
    dispatch(getResult(id));
  }
);

export const initializeScanner = createAsyncThunk(
  "search/initializeScanner",
  async (_, { dispatch, getState }) => {
    let qrboxFunction = function (viewfinderWidth, viewfinderHeight) {
      let minEdgePercentage = 0.7; // 70%
      let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
      let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
      return {
        width: qrboxSize,
        height: qrboxSize,
      };
    };

    const newScanner = new Html5QrcodeScanner("eanscan", {
      qrbox: qrboxFunction,
      fps: 60,
      focusMode: "continuous",
      advanced: [{ zoom: 5 }],
      defaultZoomValueIfSupported: 5,
      showTorchButtonIfSupported: true,
      rememberLastUsedCamera: true,
      useBarCodeDetectorIfSupported: true,
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      experimentalFeatures: {
        useBarCodeDetectorIfSupported: true,
      },
    });

    const success = (result) => {
      dispatch(stopScanner());
      dispatch(startSearch());
      setResult(result);

      dispatch(scanCodeMulti(result));
    };
    const error = (error) => {};
    newScanner.render(success, error);
    scanTimeOut = setTimeout(() => {
      dispatch(stopScanner());
      dispatch(endSearch(0));
    }, 30000);
    return newScanner;
  }
);

export const initializeScannerPro = createAsyncThunk(
  "search/initializeScannerPro",
  async (_, { dispatch, getState }) => {
    let qrboxFunction = function (viewfinderWidth, viewfinderHeight) {
      return {
        width: 370,
        height: 150,
      };
    };

    const newScanner = new Html5QrcodeScanner("eanscan2", {
      qrbox: qrboxFunction,
      fps: 60,
      focusMode: "continuous",
      rememberLastUsedCamera: true,
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      aspectRatio: 2.25,
      defaultZoomValueIfSupported: 5,
      // advanced: [{ zoom: 0.015 }],
      // showZoomSliderIfSupported: true,
      useBarCodeDetectorIfSupported: true,
    });
    const success = (result) => {
      dispatch(stopScanner());
      setResult(result);

      dispatch(scanCode(result));
    };
    const error = (error) => {
      //console.warn(error);
    };
    newScanner.render(success, error);

    scanTimeOut2 = setTimeout(() => dispatch(stopScanner()), 5000);
    return newScanner;
  }
);

export const getStores = createAsyncThunk(
  "searchlocal/items",
  async (_, { getState }) => {
    const state = getState().search;
    if (state.searchStores.length === 0) {
      const stores = await SearchService.getStores();
      return stores;
    }
    return state.searchStores;
  }
);

export const getStoreConfig = createAsyncThunk(
  "config/getconfig",
  async (id, { dispatch, getState }) => {
    return await SearchService.getConfig(id);
  }
);
export const deleteStoreConfig = createAsyncThunk(
  "config/deleteConfig",
  async (_, { dispatch, getState }) => {
    const id = getState().search.storeConfig.store;
    if (window.confirm("do you wnat to delete?")) {
      return await SearchService.deleteConfig(id);
      window.location.href = "/storeconfig";
    }
  }
);

const { actions, reducer } = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    toggleSelectItem: (state, action) => {
      let checkvalue =
        state.selectedItems.filter((i) => i.itemUrl === action.payload.itemUrl)
          .length > 0;
      if (checkvalue) {
        let items = state.selectedItems.filter(
          (i) => i.itemUrl !== action.payload.itemUrl
        );
        state.selectedItems = items;
      } else {
        let items = [...state.selectedItems, action.payload];
        state.selectedItems = items;
      }
    },

    deSelectAllItems: (state, action) => {
      state.selectedItems = [];
    },
    setScanner: (state, action) => {
      state.scanning = true;
      state.scanner = action.payload;
    },
    stopScanner: (state, action) => {
      state.scanning = false;

      if (state.scanner) {
        state.scanner.clear();
        state.scanner = null;
      }
    },
    stopScannerPro: (state, action) => {
      if (state.scanner) {
        state.scanner.stop().then((ignore) => {
          state.scanner.clear();
        });
      }
      state.scanning = false;
      state.scanner = null;
    },
    setSelectedStore: (state, action) => {
      state.selectedStore = action.payload;
      localStorage.setItem(
        "selectedStore",
        JSON.stringify(state.selectedStore)
      );
    },
    startSearch: (state, action) => {
      interval = clearInterval(interval);
      state.items = [];
      state.searching = true;
      state.searchVisible = true;
      state.noResults = false;
    },
    endSearch: (state, action) => {
      state.searchVisible = false;
      state.noResults = action.payload === 0;
      state.searching = false;
    },
    setResult: (state, action) => {
      if (!action.payload) return;
      let payload = action.payload;
      if (action.payload.indexOf("\\u") >= 0) {
        console.log("encoded");
        payload = decodeURIComponent(payload);
      }

      if (!Array.isArray(payload)) {
        payload = JSON.parse(payload);
      }
      interval = clearInterval(interval);
      state.items = payload;
    },
    setCurrentSearchId: (state, action) => {
      state.currentSearchId = action.payload;
    },
    setResultPartial: (state, action) => {
      if (!action.payload) return;
      let payload = action.payload;
      if (action.payload.indexOf("\\u") >= 0) {
        console.log("encoded");
        payload = decodeURIComponent(payload);
      }

      if (!Array.isArray(payload)) {
        payload = JSON.parse(payload);
      }
      state.items = payload;
    },
    switchMenu: (state, action) => {
      state.menuOpen = !state.menuOpen;
    },
    setSearchConfigTasks: (state, action) => {
      state.storeConfig.searchConfiguration.tasks = [...action.payload];
    },
    setGetItemConfigTasks: (state, action) => {
      state.storeConfig.getItemConfiguration.tasks = [...action.payload];
    },
    deleteConfigTasks: (state, action) => {
      if (action.payload.type === 0) {
        const tasks = current(state)
          .storeConfig.searchConfiguration.tasks.slice()
          .filter((x) => x.id !== action.payload.id);
        state.storeConfig.searchConfiguration.tasks = tasks;
      } else {
        const tasks = current(state)
          .storeConfig.getItemConfiguration.tasks.slice()
          .filter((x) => x.id !== action.payload.id);
        state.storeConfig.getItemConfiguration.tasks = tasks;
      }
    },

    addConfigTask: (state, action) => {
      let task = { ...state.initialTask };
      let tasks = [];
      if (action.payload.type === 0) {
        tasks = [...current(state.storeConfig.searchConfiguration.tasks)];
      } else {
        tasks = [...current(state.storeConfig.getItemConfiguration.tasks)];
      }
      task.id = Date.now().toString();
      if (action.payload.position && action.payload.position >= 0) {
        const newIndex = parseInt(action.payload.position, 10);
        let insertedTasks = [];
        if (newIndex <= tasks.length) {
          insertedTasks = [
            ...tasks.slice(0, newIndex),
            task,
            ...tasks.slice(newIndex),
          ];
        } else {
          insertedTasks = [...tasks, task];
        }

        let orderedTasks = [];
        for (let index = 0; index < insertedTasks.length; index++) {
          let element = {
            ...insertedTasks[index],
          };
          element.order = index + 1;
          orderedTasks.push(element);
        }
        if (action.payload.type === 0) {
          state.storeConfig.searchConfiguration.tasks = orderedTasks;
        } else {
          state.storeConfig.getItemConfiguration.tasks = orderedTasks;
        }
      } else {
        task.order = tasks.length + 1;
        if (action.payload.type === 0) {
          state.storeConfig.searchConfiguration.tasks = [
            ...state.storeConfig.searchConfiguration.tasks,
            task,
          ];
        } else {
          state.storeConfig.getItemConfiguration.tasks = [
            ...state.storeConfig.getItemConfiguration.tasks,
            task,
          ];
        }
      }
    },

    updateConfigTasks: (state, action) => {
      let updatedTasks = [];
      if (action.payload.type === 0) {
        let tasks = [...current(state).storeConfig.searchConfiguration.tasks];
        let taskId = tasks.findIndex((e) => e.id === action.payload.id);
        for (let index = 0; index < tasks.length; index++) {
          let element = { ...tasks[index] };
          if (index === taskId) {
            element[action.payload.key] = action.payload.value;
          }
          updatedTasks.push(element);
        }
        state.storeConfig.searchConfiguration.tasks = updatedTasks;
      } else {
        let tasks = [...current(state).storeConfig.getItemConfiguration.tasks];
        let taskId = tasks.findIndex((e) => e.id === action.payload.id);
        for (let index = 0; index < tasks.length; index++) {
          let element = { ...tasks[index] };
          if (index === taskId) {
            element[action.payload.key] = action.payload.value;
          }
          updatedTasks.push(element);
        }
        state.storeConfig.getItemConfiguration.tasks = updatedTasks;
      }
    },
    updateConfiguration: (state, action) => {
      let config = { ...current(state.storeConfig) };
      config[action.payload.key] = action.payload.value;
      state.storeConfig = config;
    },
    updateSearchConfiguration: (state, action) => {
      let config = { ...current(state.storeConfig.searchConfiguration) };
      config[action.payload.key] = action.payload.value;
      state.storeConfig.searchConfiguration = config;
    },
    updateGetItemConfiguration: (state, action) => {
      let config = { ...current(state.storeConfig.getItemConfiguration) };
      config[action.payload.key] = action.payload.value;
      state.storeConfig.getItemConfiguration = config;
    },
    updateQueryInternal: (state, action) => {
      state.query = action.payload;
      if (!state.query) {
        state.searchVisible = false;
        state.noResults = false;
        state.items = [];
      }
    },
    toggleStoreFilter: (state, action) => {
      if (state.selectedStores.includes(action.payload)) {
        let selected = state.selectedStores.filter((s) => s !== action.payload);
        state.selectedStores = selected;
      } else {
        let selected = [...state.selectedStores, action.payload];
        state.selectedStores = selected;
      }

      let availableStores = [];
      let grouped = [];
      state.selectedStores.forEach((s) =>
        availableStores.push(state.searchStores.find((st) => st.id === s))
      );

      const categories = availableStores
        .map((name) => name.category)
        .filter((value, index, array) => array.indexOf(value) === index);

      categories.forEach((category) => {
        grouped.push({
          category: category,
          stores: availableStores
            .filter((store) => store.category === category)
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
        });
      });
      state.selectedGroupedStores = grouped;

      localStorage.setItem(
        "selectedStores",
        JSON.stringify(state.selectedStores)
      );
    },
    toggleStoreSelectAll: (state, action) => {
      if (state.allSelected) {
        state.selectedStores = [];
        state.selectedGroupedStores = [];
      } else {
        state.selectedStores = state.searchStores.map((s) => s.id);
        state.selectedGroupedStores = state.groupedStores;
      }
      state.allSelected = !state.allSelected;
      localStorage.setItem(
        "selectedStores",
        JSON.stringify(state.selectedStores)
      );
    },
    setStores: (state, action) => {
      let availableStores = [];
      let grouped = [];
      state.selectedStores.forEach((s) =>
        availableStores.push(state.searchStores.find((st) => st.id === s))
      );

      const categories = availableStores
        .map((name) => name.category)
        .filter((value, index, array) => array.indexOf(value) === index);

      categories.forEach((category) => {
        grouped.push({
          category: category,
          stores: availableStores
            .filter((store) => store.category === category)
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
        });
      });
      state.selectedGroupedStores = grouped;
    },
  },
  extraReducers: {
    [getStoreConfig.fulfilled]: (state, { meta, payload }) => {
      state.storeConfig = payload;
    },
    [initializeScanner.fulfilled]: (state, { meta, payload }) => {
      state.scanning = true;
      state.scanner = payload;
      state.items = [];
    },
    [saveStoreConfig.fulfilled]: (state, { meta, payload }) => {
      state.storeConfig = payload;
      toast.success("Config Saved");
      history.push(`/storeconfig/${payload.store}`);
    },
    [testStoreConfig.fulfilled]: (state, { meta, payload }) => {
      state.testHit = payload.hit;
      state.testHits = payload.hits;
    },
    [scanCode.fulfilled]: (state, { meta, payload }) => {
      state.items = payload.hits;
      state.suggestions = payload.suggestions;
      state.searchVisible = false;
      state.noResults =
        payload.hits.length === 0 && payload.suggestions.length === 0;
      state.searching = false;
      state.scanner = null;
    },
    [getStores.fulfilled]: (state, { meta, payload }) => {
      if (state.searchStores.length === 0) {
        state.searchStores = payload;

        state.sortedStores = [...payload].sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );

        let grouped = [];

        const categories = payload
          .map((name) => name.category)
          .filter((value, index, array) => array.indexOf(value) === index);

        state.categories = categories;

        categories.forEach((category) => {
          grouped.push({
            category: category,
            stores: payload
              .filter((store) => store.category === category)
              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
          });
        });
        state.groupedStores = grouped;
        state.selectedGroupedStores = grouped;
        let selectedStore = JSON.parse(localStorage.getItem("selectedStore"));

        state.selectedStores = state.searchStores.map((s) => s.id);
        if (selectedStore) {
          state.selectedStore = selectedStore;
        }
      }
    },
  },
});

export const {
  updateQueryInternal,
  toggleStoreFilter,
  toggleStoreSelectAll,
  setStores,
  toggleSelectItem,
  deSelectAllItems,
  switchMenu,
  startSearch,
  endSearch,
  setSelectedStore,
  setResult,
  setResultPartial,
  setSearchConfigTasks,
  setGetItemConfigTasks,
  updateConfigTasks,
  deleteConfigTasks,
  updateConfiguration,
  updateSearchConfiguration,
  updateGetItemConfiguration,
  addConfigTask,
  stopScanner,
  stopScannerPro,
  setScanner,
} = actions;
export default reducer;
